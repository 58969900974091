.custom-modal-dialog {
    @include media("<=ltablet") { 
        margin: 0 auto;
    }
    @include media("<=tablet") {
        height: auto;
        overflow-y: auto;
    }
}

.modal-wrapper{
    padding:34px 47px;
    background: $white;
    border-radius: 5px;
    position: relative;

    @include media("<=ltablet") {
        padding:23px 30px;
        height:100vh;
    }

    &--height{
        @include media("<=ltablet") {
            height:auto;
        }
        @include media("<=tablet") {
            height:100vh !important;
            overflow-y: auto;
        }
    }
}

.modal-close {
    position: absolute;
    top:35px;
    right:37px;
    color:$black;
    opacity: 1;

    @include media("<=ltablet") {
        top:23px;
    }
    
}

.modal-title {
    font-size:20px;
    line-height: 24px;
    font-weight:$bold;
}

.modal-content-place {
    display:block;
    margin: 36px 0 0;

    @include media("<=ltablet") {
        display: flex;
        min-height:600px;
        flex-direction: column;
        justify-content: center;
        margin:0;
        margin-left: -30px;
        margin-right: -30px;
    }

        .image-crop-holder {
            max-width:775px;
            height: 614px;
            position:relative;

            @include media("<=ltablet") {
                height:365px;
            }

                img {
                    object-fit: cover;
                    max-width: inherit;
                    width: 100%;
                    height: 100%;
                }
        }

        &--form-place {

            @include media("<=ltablet") {
                margin-left:auto;
                margin-right:auto;
                min-height: auto;
                margin-top:36px;
            }
        }
}

.button-group-place {
    display:flex;
    justify-content: flex-end;
    margin-top: 26px;
    flex-wrap:wrap;

        .button{
            margin-right: 24px;

            &:last-child{
                margin-right: 0px;
            }
        }

    @include media("<=ltablet") {
        justify-content: center;
    }

        &--reducespace {
            padding: 0 27px;

            @include media("<=ltablet") {
                padding: 0 0;
            }
        }

    &--button-break {
        @include media("<=ltablet") {
            flex-direction:column;
            align-items: center;
        }

        .button{
            @include media("<=ltablet") {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}



.close-popup {
	display:none;
	@include media("<=tablet") {
		display:block;
        top: 0;
        right: 0;
	}
}

.form-heading {
    font-size:12px;
    font-weight: $bold;
    line-height: 15px;
    margin-bottom: 22px;

    &--violetcolor {
        color:$primary-color;
    }
}
.language-selector-menu {
    margin: 45px 15px 35px 0;
    padding-left: 20px;
    .lang-toggle {
        span {
            color: $black !important;
        }
    }
}