.navbar {
	padding: 0;
	background: transparent;
	.nav-right {
		width: 100%;
		&-prelogin {
			padding: 35px 0;
			@include media("<=ltablet") {
				padding: 25px 0;
				@include flexbox;
				align-items: center;
				justify-content: flex-end;
			}
			&-links {
				@include flexbox;
				align-items: center;
				justify-content: flex-end;
				ul {
					li {
						a {
							color: $black;
							letter-spacing: 0.4px;
							font-size: 16px;
							font-weight: 700;
							margin: 0 20px;
							padding: 0;
							@include transition(all, .4s, ease-in-out);
							&:hover {
								color: $primary-color;
							}
						}
					}
				}
				.button {
					margin-left: 20px;
				}
				.multilingual {
					margin-left: 26px;
					position: relative;
					clear: both;
				    display: table;
				    width: 60px;
				    height: 30px;
				}
				@include media("<=ltablet") {
					display: none;
				}
			}
			.nav-toggle {
				display: none;
			    position: relative;
			    left: auto;
			    cursor: pointer;
			    @include media("<=ltablet") {
			    	display: block;
			    }
			    .bar {
			    	width: 22px;
				    height: 2px;
				    background-color: $headline-color;
				    margin: 6px 0;
				    transition: 0.4s;
				    display: block;
				    pointer-events: none;
				    &.bar3, &.bar1 {
				    	width: 75%;
				    	margin-left: auto;
				    }
			    }
			    &.active {
			    	.bar {
			    		background-color: $white;
			    		&.bar1 {
					    	width: 100%;
					    }
			    	}
			    }
			}
		}
		&-postlogin {
			display: none;
		}
	}
	&-brand {
		line-height: 1;
		max-width: 255px;
		width: 100%;
		padding: 5px 0 0;
		svg {
			height: 26px;
			width: 170px;
			@include media(">=mxphone") {
				width: 195px;
			}
			@include media(">ltablet") {
				width: 225px;
			}
		}
	}
	&-postlogin {
		border: 1px solid $lightgray-border;
		background: $faded-white;
		.nav-right {
			width: 100%;
			&-postlogin {
				@include flexbox;
				align-items: center;
				justify-content: space-between;
				&-left {
					padding: 25px;
					border-right: 1px solid $lightgray-border;
					border-left: 1px solid $lightgray-border;
					display: none;
					@include media(">ltablet") {
						display: block;
					}
					p {
						color: $bodytext-color;
						font-size: 17px;
						margin-bottom: 0;
						span {
							color: $headline-color;
							font-size: 14px;
							font-weight: 700;
							margin-right: 10px;
						}
					}
				}
				&-right {
					.postlogin-desktop {
						display: none;
						padding: 25px 0;
						@include media(">tablet") {
							padding: 18px 0;
						}
						@include media(">ltablet") {
							@include flexbox;
							align-items: center;
						}
						.button {
							margin-right: 23px;
						}
						ul {
							li {
								a {
									color: $bodytext-color;
									letter-spacing: 0.4px;
									font-size: 16px;
									font-weight: 400;
									margin: 0 23px;
									padding: 0 !important;
									@include transition(all, .4s, ease-in-out);
									&:hover {
										color: $primary-color;
									}
								}
							}
						}
						.dropdown-holder {
							margin-left: 23px;
							.accountIcon {
								font-size: 16px;
								font-weight: 400;
								color: $bodytext-color;
								i {
									color: $black;
								}
							}
							.profileDropdown {
								background: $white;
							    position: absolute;
							    top: 160%;
							    right: 0;
							    padding: 26px 15px 0;
							    min-width: 256px;
							    text-align: left;
							    z-index: 2;
							    @include borderradius(5px);
							    @include boxshadow(0px, 3px, 25px, 0px, $light-gray-shadow);
							    transition: all 0s ease;
							    .profileDropdownDesc {
							    	margin-bottom: 30px;
							    	h6 {
							    		font-size: 14px;
							    		font-weight: $bold;
							    		line-height: 17px;
							    		margin-bottom: 6px;
							    		color: $headline-color;
							    	}
							    	p {
							    		margin-bottom: 0;
							    		font-size: $root-font-size - 6;
							    		line-height: normal;
							    		color: $bodytext-color;
							    	}
							    }
							    .profileDropdownList {
							    	clear: both;
							    	display: table;
							    	width: 100%;
							    	li {
							    		line-height: 1;
								    	a {
								    		color: $bodytext-color;
										    font-size: 14px;
										    line-height: normal;
										    display: inline-flex;
										    align-items: center;
										    margin-left: auto;
										    width: 100%;
										    margin-bottom: 16px;
										    @include transition(all, .4s, ease-in-out);
										    svg {
										    	width: 20px;
								    			height: 20px;
								    			fill: $white;
								    			margin-right: 12px;
										    }
								    	}
								    	&:hover {
								    		a {
								    			padding-left: 5px !important;
								    		}
								    	}
								    	/*&:last-child {
								    		a {
									    		margin-bottom: 0;
									    	}
									    }*/
								    }
							    }
							    .dropdown-top {
							    	padding: 0 20px;
							    }
							    .multilingual-group {
							    	margin-bottom: 22px;
							    	.btn-group {
							    		.dropdown-toggle {
							    			padding-left: 0;
							    			padding-top: 0;
							    			span {
							    				font-size: $root-font-size - 4;
							    				color: $bodytext-color;
							    				font-weight: $regular;
							    				margin-right: 10px;
							    			}
							    			&:after {
							    				display: none;
							    			}
							    		}
							    	}
							    }
							    .logout {
							    	padding: 25px 20px;
							    	border-top: 1px solid $lightgray-border;
							    	.profileDropdownList {
							    		li {
							    			a {
							    				margin-bottom: 0;
							    			}
							    		}
							    	}
							    }
							}
						}
					}
					.postlogin-mobile {
						@include flexbox;
						align-items: center;
						padding: 25px 0;
						@include media(">tablet") {
							padding: 18px 0;
						}
						@include media(">ltablet") {
							display: none;
						}
						a {
							margin-left: 17px;
							.material-icons {
								font-size: 32px;
							}
							&:hover {
								color: $primary-color;
							}
						}
					}
				}
			}
			.nav-right-prelogin {
				display: none;
			}
		}
		.container {
			max-width: 1810px !important;
		}
		&.nav-active {
			box-shadow: none !important;
		}
	}
	&.fixed-top {
		@include transition(all, .4s, ease-in-out);
	}
	&.nav-active {
		background: $faded-white;
		@include boxshadow(0px, 0px, 5px, 0px, rgba(0,0,0,0.25));
	}
}
/*.arrow-up {
	&:before {
		position: absolute;
	    top: -5px;
	    right: 10px;
	    content: "";
	    width: 0;
	    height: 0;
	    border-left: 5px solid transparent;
	    border-right: 5px solid transparent;
	}
}
.arrow-up-white {
	&:before {
		border-bottom: 5px solid $primary-color;
	}
}*/

.multilingual-group {
	.btn {
		width: 65px;
		background: none;
		-webkit-appearance: none;
		&:focus {
			outline: none;
			box-shadow: none;
		}
		img {
			@include borderradius(50%);
			width: 24px;
			height: 24px;
			display: inline-block;
			overflow: hidden;
		}
	}
	.dropdown-menu {
		right: 0;
		left: auto;
		min-width: auto;
		@include boxshadow(0px, 0px, 5px, 0px, rgba(0,0,0,.15));
    	border: none;
		li {
			a {
				margin: 8px !important;
				@include borderradius(50%);
				width: 24px;
				height: 24px;
				display: inline-block;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-position: center;
					object-fit: cover;
				}
			}
		}
	}
	.drop-wrap-menu {
		li {
			a {
				@include borderradius(0px);
				@include flexbox;
				justify-content: space-between;
				clear: both;
				overflow: hidden;
    			width: auto;
    			height: auto;
    			margin: 12px 8px !important;
				span {
					padding-right: 12px;
					display: inline-block;
				}
				img {
					@include borderradius(50%);
				    width: 24px;
				    height: 24px;
				    display: inline-block;
				    overflow: hidden;
				}
			}
		}
	}
	.btn-group {
		.dropdown-toggle {
			&:after {
				background-image: url(../images/svg/down.svg);
			    background-repeat: no-repeat;
			    background-size: contain;
			    border: none;
			    vertical-align: middle;
			    width: 15px;
			    height: 8px;
			    margin-left: 13px;
			    @include transition(all, .3s, ease-in);
			}
		}
		&.show {
			.dropdown-toggle {
				&:after {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}