// Material Icon Library Import
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// Fontawosome Icon Library Import
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.0/css/all.min.css');

$roboto: 'Roboto', sans-serif;
$lato: 'Lato', sans-serif;
$playfair: 'Playfair Display', serif;

$imagePath : "images";
$fontPath : "fonts";
$fontawesome : "FontAwesome";

// Font weight
$light: 300;
$regular: 400;
$meduim: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;


//@include media("<=phone") {}
//@include media(">phone") {}
//@include media(">mphone") {}
//@include media(">lphone") {}
//@include media(">tablet") {}
//@include media(">ltablet") {}
//@include media(">=815px", "<desktop") {}
//@include media(">=desktop") {}
//@include media("retina2x") {}
$breakpoints: (
  'phone': 319px,
  'sphone': 320px,
  'mphone': 375px,
  'mxphone': 414px,
  'smphone': 539px,
  'lphone': 639px, 
  'tablet': 767px, 
  'ltablet': 991px, 
  'desktop': 1024px,
  'mdesktop': 1199px,
  'largerdesktop': 1400px,
  'ldesktop': 1600px,
  'defaultdesktop': 1700px,
  'exldesktop': 1800px,
  'mac': 1919px,
) !default;


$media-expressions: (
  'screen': 'screen', 
  'print': 'print', 
  'handheld': 'handheld',
  'retina2x': (
    '(-webkit-min-device-pixel-ratio: 2)', 
    '(min-resolution: 192dpi)'
  ), 
  'retina3x': (
    '(-webkit-min-device-pixel-ratio: 3)', 
    '(min-resolution: 350dpi)'
  )
) !default;


$unit-intervals: (
  'px': 1, 
  'em': 0.01, 
  'rem': 0.1
) !default;

@mixin media($conditions...) {
  @for $i from 1 through length($conditions) {
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}

//Font size responsive
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
      @if $fs-breakpoint==null {
          font-size: $fs-font-size;
          line-height: $fs-font-size + 5px;
      } @else {
          @media screen and (min-width: $fs-breakpoint) {
              font-size: $fs-font-size;
              line-height: $fs-font-size + 10px;
          }
      }
  }
}

// paragraph
$p-font-sizes: (
  null: 14px,
  320px: 14px,
  768px: 16px,
  1600px: 18px,
  1920px: 18px,
);
$large-font-sizes: (
  null: 26px,
  320px: 26px,
  768px: 30px,
  1600px: 40px,
  1920px: 50px,
);
$xlarge-font-sizes: (
  null: 72px,
  320px: 72px,
  768px: 72px,
  1600px: 72px,
  1920px: 72px,
);

// header font size.
$h1-font-sizes: (
  null: 40px,
  320px: 40px,
  768px: 50px,
  1600px: 65px,
  1920px: 65px,
);
$h2-font-sizes: (
  null: 30px,
  320px: 30px,
  768px: 40px,
  1600px: 45px,
  1920px: 45px,
);
$h3-font-sizes: (
  null: 25px,
  320px: 25px,
  768px: 35px,
  1600px: 40px,
  1920px: 40px,
);
$h4-font-sizes: (
  null: 22px,
  320px: 22px,
  768px: 30px,
  1600px: 35px,
  1920px: 35px,
);
$h5-font-sizes: (
  null: 20px,
  320px: 20px,
  768px: 25px,
  1600px: 30px,
  1920px: 30px,
);
$h6-font-sizes: (
  null: 18px,
  320px: 18px,
  768px: 20px,
  1600px: 25px,
  1920px: 25px,
);
$bttn-font-sizes: (
  null: 16px,
  320px: 16px,
  768px: 18px,
  1600px: 20px,
  1920px: 20px,
);
$link-font-sizes: (
  null: 14px,
  320px: 14px,
  1920px: 14px,
);




//gradient color
