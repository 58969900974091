/*home features blocks*/
.services {
	padding: 300px 0 80px;
	@include media(">tablet") {
		padding: 80px 0 80px;
	}
	@include media(">mdesktop") {
		padding: 110px 0;
	}
	@include media(">largerdesktop") {
		padding: 110px 0;
	}
	@include media(">ldesktop") {
		padding: 110px 0;
	}
	&-inner {
		max-width: 1420px;
		.common-heading {
			max-width: 532px;
			margin-bottom: 110px;
			@include media("<=tablet") {
				margin-bottom: 38px;
			}
		}
	}
}
.services-group {
	@include flexbox;
	align-items: center;
	margin-bottom: 110px;
	&:last-child {
		margin-bottom: 0;
	}
	@include media("<=tablet") {
		flex-direction: column;
		margin-bottom: 55px;
	}
	&-left {
		width: 55%;
		padding-right: 145px;
		@include media("<=mdesktop") {
			padding-right: 95px;
		}
		@include media("<=tablet") {
			width: 100%;
			padding-right: 0;
			order: 2;
			-webkit-order: 2;
		}
		&-inner {
			max-width: 532px;
			@include media("<=tablet") {
				max-width: 100%;
			}
			p {
				max-width: 496px;
			}
			span {
				color: $primary-color;
				font-weight: $semi-bold;
				font-size: 20px;
				line-height: 24px;
				margin-bottom: 15px;
				@include media("<=tablet") {
					font-size: 18px;
					line-height: 22px;
				}
			}
			.common-heading {
				margin-bottom: 25px;
				@include media("<=tablet") {
					font-size: $root-font-size + 7;
					line-height: 30px;
					margin-bottom: 15px;
				}
			}
		}
	}
	&-right {
		width: 45%;
		.img-wrapper {
			width: 100%;
			height: 388px;
			img {
				width: 100%;
				height: 100%;
				object-position: center;
				object-fit: cover;
			}
			@include media("<=tablet") {
				margin-bottom: 32px;
				height: auto;
			}
		}
		@include media("<=tablet") {
			width: 100%;
		}
	}
	&:nth-child(odd) {
		.services-group-left {
			order: 2;
			-webkit-order: 2;
			padding-left: 145px;
			padding-right: 0;
			@include media("<=mdesktop") {
				padding-left: 95px;
			}
			@include media("<=tablet") {
				padding-left: 0;
			}
		}
	}
}

/*get-started*/

.get-started {
	padding: 150px 0;
	background: url(../images/home_bottom_bg.jpg) center center no-repeat;
	background-size: cover;
	position: relative;
	@include media("<=tablet") {
		padding: 190px 0;
	}
	&-inner {
		position: relative;
		z-index: 2;
		max-width: 725px;
		.button {
			margin-top: 32px;
			@include media("<=tablet") {
				margin-top: 35px;
			}
		}
		p {
			@include media("<=tablet") {
				font-size: 16px;
				line-height: 25px;
			}
		}
	}
}

/*features*/

.feature-box-wrapper {
	margin-bottom: 0;
	padding-bottom: 60px;
	@include media(">tablet") {
		margin-bottom: -110px;
		padding-bottom: 0;
	}
}
.feature-box-slider {
	@include media(">tablet") {
		/*@include flexbox;
		flex-wrap: wrap;
		justify-content: space-between;*/
	}
	.slick-track {
		display: flex !important;
		.slick-slide {
			height: inherit !important;
		}
	}
	.slick-dots {
		bottom: -45px;
		/*@include flexbox;
	    align-items: baseline;
	    justify-content: center;*/
		li {
			button {
				&:before {
					font-size: 19px;
    				line-height: 21px;
    				color: $white;
    				opacity: 1;
				}
			}
			&.slick-active {
				width: 28px;
				height: 28px;
				button {
					width: 28px;
					height: 28px;
					&:before {
						font-size: 28px;
						line-height: 30px;
						width: 28px;
						height: 28px;
						color: $primary-color;
						opacity: 1;
					}
				}
			}
		}
	}
}
.feature-box {
	padding: 34px 52px;
	background: $white;
	@include borderradius(5px);
	box-shadow: 0px 3px 25px #922C8814;
	margin: 0 10px;
	@include media(">tablet") {
		/*max-width: 49%;*/
		/*margin-bottom: 15px;*/
		margin: 0 7px;
	}
	@include media(">mdesktop") {
		/*max-width: 24%;*/
	}
	&-icon {
		width: 70px;
		height: 70px;
		/*@include borderradius(50%);
		background: $yellow-accent-color;*/
		margin-bottom: 18px;
		.material-icons {
			font-size: 43px;
			color: $white;
		}
	}
	h6 {
		font-size: $root-font-size + 2;
		line-height: 24px;
		margin-bottom: 12px;
	}
	p {
		a {
			color: $primary-color;
			&:hover {
				color: $violet-hover-color;
			}
		}
	}
}

.features {
	padding: 88px 0;
	background: $faded-white;
	@include media(">tablet") {
		padding: 210px 0 110px;
	}
	&-info {
		max-width: 718px;
		padding: 0 25px;
		@include media(">tablet") {
			padding: 0;
		}
		h2 {
			span {
				color: $primary-color;
				text-transform: uppercase;
			}
		}
		.button {
			margin-top: 30px;
			@include media(">tablet") {
				margin-top: 50px;
			}
		}
	}
}
/*template slider*/
.feature-template {
	padding-top: 95px;
	@include media(">tablet") {
		padding-top: 110px;
	}
	&-slider {
		&-wrapper {
			@include flexbox;
			flex-direction: column;
			margin: 0 17px;
			.tmp-name {
				color: $headline-color;
				font-size: 20px;
				line-height: 24px;
				font-weight: $regular;
				margin-bottom: 20px;
				position: relative;
				@include media(">tablet") {
					font-size: 25px;
					line-height: 30px;
					margin-bottom: 28px;
				}
				&:before {
					position: absolute;
					content: '';
					bottom: -1px;
					width: 100%;
					height: 1px;
					background: $headline-color;
				}
			}
			.tmp-image {
				@include borderradius(5px);
				overflow: hidden;
				height: 230px;
				@include media(">smphone") {
					height: 400px;
				}
				@include media(">tablet") {
					height: 420px;
				}
				@include media(">mdesktop") {
					height: 678px;
				}
				img {
					width: 100%;
					height: 100%;
					object-position: center;
					object-fit: cover;
				}
			}
		}
	}
	.slider-nav {
		@include flexbox;
		align-items: center;
		justify-content: flex-end;
		padding-top: 25px;
		padding-right: 30px;
		@include media(">tablet") {
			padding-right: 8%;
		}
		.material-icons {
			font-size: 35px;
			font-weight: 300;
		}
		.slide-count {
			margin-right: 18px;
			position: relative;
			top: -3px;
			font-size: $root-font-size + 3;
			color: $headline-color;
		}
		.slick-arrow {
			cursor: pointer;
			display: inline-block;
			&.prev {
				.material-icons {
					color: $bodytext-color;
				}
			}
		}
	}
}

/*help*/

.help-wrapper {
	padding: 84px 0;
	background: $headline-color;
	@include media(">largerdesktop") {
		padding: 150px 0;
	}
	&-inner {
		padding: 0;
		flex-wrap: wrap;
		flex-direction: column;
		@include media(">tablet") {
			flex-wrap: nowrap;
			flex-direction: row;
			padding: 0 20px;
		}
		@include media(">largerdesktop") {
			padding: 0 70px;
		}
		&-left {
			width: 100%;
			text-align: center;
			padding: 0 50px;
			@include media(">tablet") {
				width: 50%;
				text-align: left;
				padding: 0;
				padding-right: 50px;
			}
			&-content {
				max-width: 100%;
				padding-top: 0;
				@include media(">tablet") {
					max-width: 670px;
				}
				@include media(">largerdesktop") {
					padding-top: 42px;
				}
				.button {
					margin-top: 10px;
				}
				p {
					@include media("<=tablet") {
						font-size: $root-font-size - 2;
						line-height: 25px;
					}
				}
			}
		}
		&-right {
			width: 100%;
			padding-top: 60px;
			@include media(">tablet") {
				width: 50%;
				padding-top: 0;
			}
		}
	}
	.container {
		@include media("<=tablet") {
			padding: 0;
		}
	}
}

.help-accordion {
	max-width: 100%;
	width: 100%;
	float: right;
	@include media(">tablet") {
		max-width: 662px;
	}
	.card {
		@include borderradius(0px);
		border: none;
		background: transparent;
		.card-header {
			border: 1px solid $gray-border;
			border-left: none;
			border-right: none;
			border-bottom: none;
			background: transparent;
			padding: 0;
			.card-title {
				>button {
					font-size: 18px;
					line-height: 22px;
					font-weight: $bold;
					color: $white;
					padding: 30px 25px;
					width: 100%;
				    text-align: left;
				    white-space: inherit;
				    @include media(">tablet") {
				    	font-size: 22px;
						line-height: 27px;
						padding: 38px 0;
				    }
					&:before {
						float: right !important;
						font-family: 'Material Icons';
						content: "remove";
						-webkit-font-feature-settings: 'liga';
						margin-left: 30px;
						font-weight: 300;
						font-size: 35px;
					}
				}
				>button.collapsed {
					&:before {
						float: right !important;
						font-family: 'Material Icons';
						content: "add";
						-webkit-font-feature-settings: 'liga';
						margin-left: 30px;
					}
				}
				>button {
					&:hover {
						text-decoration: none;
					}
					&:active {
						text-decoration: none;
					}
					&:focus {
						text-decoration: none;
					}
				}
			}
		}
		.card-body {
			background: transparent;
			padding: 0 75px 25px 25px;
			@include media(">tablet") {
				padding: 0 45px 15px 0;
			}
			p {
				font-size: $root-font-size - 2;
				line-height: 25px;
				color: $white;
				@include media("<=tablet") {
					font-size: $root-font-size - 4;
				}
			}
		}
		&:last-child {
			border-bottom: 1px solid $gray-border;
		}
	}
}

/*customer service*/

.customer-service {
	background: $faded-white;
	min-height: 274px;
	padding: 65px 0;
	@include media(">tablet") {
		padding: 98px 0;
	}
	&-inner {
		max-width: 1300px;
		position: relative;
    	margin-top: 0;
    	padding: 0 15px;
    	@include media(">mdesktop") {
    		margin-top: -310px;
    		/*padding: 0;*/
    	}
		h2 {
			margin-bottom: 25px;
			@include media(">tablet") {
				margin-bottom: 46px;
			}
		}
		.feature-box-wrap {
			/*@include flexbox;
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;
			align-items: center;*/
			display: grid;
		    grid-gap: 19px;
		    grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
			@include media(">mdesktop") {
				/*flex-wrap: nowrap;
				justify-content: space-between;*/
				grid-gap: 46px;
				grid-template-columns: repeat(auto-fill,minmax(330px,1fr));
			}
			.feature-box {
				/*max-width: 393px;*/
				/*margin: 0 23px;*/
				margin: 0;
				padding: 56px 48px;
				text-align: left;
				@include media(">tablet") {
					/*margin: 10px;*/
				}
				@include media(">mdesktop") {
					/*margin: 0;
					max-width: 31.7%;*/
				}
				h6 {
					margin-bottom: 24px;
				}
			}
		}
	}
}

/*login*/

.login-wrapper-outer {
	padding: 70px 0 100px 0;
	@include media(">ltablet") {
		padding: 162px 0;
	}
	.login-wrapper {
		max-width: 945px;
		margin: 0 auto;
		@include flexbox;
		padding: 0 15px;
		justify-content: center;
		flex-direction: column;
		@include media(">ltablet") {
			justify-content: space-between;
			flex-direction: row;
		}
		@include media(">mdesktop") {
			padding: 0;
		}
		&-left {
			width: 100%;
			padding-right: 0;
			padding-top: 70px;
			text-align: center;
			@include media(">ltablet") {
				padding-right: 45px;
				padding-top: 100px;
				width: 55%;
				text-align: left;
			}
			@include media(">mdesktop") {
				padding-right: 75px;
			}
			h1 {
				margin-bottom: 15px;
			}
		}
		&-right {
			width: 100%;
			border-left: none;
			@include media(">ltablet") {
				width: 45%;
				border-left: 1px solid $lightgray-border;
			}
			.form-wrapper {
				width: 100%;
				max-width: 367px;
				margin: 0 auto;
				padding: 30px 0;
				@include media(">ltablet") {
					margin-left: auto;
					margin-right: 0;
					padding: 50px 0;
				}
				input {
					border: 1px solid $lightgray-border;
					&.error {
						border: 1px solid $error-color;
					}
				}
				.login-btn {
					margin-bottom: 42px;
					padding: 21px 16px;
				}
				label {
					// display: none;
					display: block;
					@include media(">ltablet") {
						display: block;
					}
				}
				.option-tab {
					@include flexbox;
				}
				.checker-wrapper {
					.checker {
						font-size: $root-font-size;
						color: $bodytext-color;
						a {
							text-decoration: underline;
							&:hover {
								color: $primary-color;
							}
						}
					}
				}
				.cat-selector {
					margin-bottom: 20px;
					p {
						font-size: 16px;
						line-height: 25px;
						color: $primary-color;
						margin-bottom: 14px;
					}
				}
				.form-group {
					.input-group-append {
						.input-group-text {
							background-color: $primary-color;
							color: $white;
							border: 1px solid $primary-color;
						}
					}
				}
			}
		}
	}
}
/*.round {
	position: relative;
	label {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 50%;
		cursor: pointer;
		height: 28px;
		left: 0;
		position: absolute;
		top: 0;
		width: 28px;
		&:after {
			border: 2px solid #fff;
			border-top: none;
			border-right: none;
			content: "";
			height: 6px;
			left: 7px;
			opacity: 0;
			position: absolute;
			top: 8px;
			transform: rotate(-45deg);
			width: 12px;
		}
	}
	input[type="checkbox"] {
		visibility: hidden;
		&:checked {
			+ {
				label {
					background-color: #66bb6a;
					border-color: #66bb6a;
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}*/

/*.checker-group {
	display: block;
	margin-bottom: 15px;
	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		&:checked {
			+ {
				label {
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 5px;
						left: 9px;
						width: 7px;
						height: 13px;
						border: solid $primary-color;
						border-width: 0 2px 2px 0;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	label {
		position: relative;
		cursor: pointer;
		color: $bodytext-color;
		font-size: 18px;
		line-height: 1;
		padding-left: 0;
		@include flexbox;
		align-items: center;
		&:before {
			content: '';
			-webkit-appearance: none;
			background-color: transparent;
			border: 2px solid $primary-color;
			@include borderradius(50%);
			padding: 10px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 15px;
			width: 25px;
			height: 25px;
			flex-shrink: 0;
		}
		a {
			text-decoration: underline;
			margin-left: 5px;
			&:hover {
				color: $primary-hover-color;
				text-decoration: none;
			}
		}
	}
}*/

.checker {
	display: block;
	position: relative;
	padding-left: 43px !important;
	font-weight: $regular;
	cursor: pointer;
	font-size: $root-font-size - 2;
	color: $bodytext-color;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark {
					background-color: transparent;
					border: 2px solid $primary-color;
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.checkmark {
					
				}
			}
		}
	}
	.checkmark {
		&:after {
			left: 8px;
			top: 3px;
			width: 6px;
			height: 12px;
			border: solid $primary-color;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: transparent;
	border: 2px solid $lightgray-border;
	@include borderradius(50%);
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}


/*terms*/

.terms-wrapper {
	padding: 180px 0 105px 0;
    &-inner {
        max-width: 1014px;
        margin: 0 auto;
        padding: 0 20px;
        @include media(">tablet") {
        	padding: 0 15px;
        }
        .page-heading {
        	text-align: center;
        	padding-bottom: 50px;
        	@include media(">ltablet") {
        		text-align: left;
        		padding-bottom: 25px;
        	}
        }
    }
}