:root {

    /* Colors: */
    --unnamed-color-8a8a8f: #8A8A8F;
    --unnamed-color-fd6b05: #FD6B05;
    --unnamed-color-d1d0d4: #D1D0D4;
    --unnamed-color-707070: #707070;
    --unnamed-color-727272: #727272;
    --unnamed-color-fd710c: #FD710C;
    --unnamed-color-2ce836: #2CE836;
    --unnamed-color-ff6700: #FF6700;
    --unnamed-color-848484: #848484;
    --unnamed-color-c8c7cc: #C8C7CC;
    --unnamed-color-ffffff: #FFFFFF;
    --unnamed-color-cecece: #CECECE;
    --unnamed-color-000000: #000000;
}

$primary-color: #9863CF;
$secondary-color: #922C88;
$yellow-accent-color: #EFBA01;
$green-accent-color: #28A745;
$white: #fff;
$faded-white: #FCFCFC;
$black: #000;
$headline-color: #303030;
$bodytext-color: #909090;
$lightgray-bg: #F8F8F8;
$lightergray-bg: #DBDBDB;
$lightgray-border: #F0F0F0;
$lightgray-border-v2: #f6f6f6;
$gray-border: #707070;
$faded-gray-color: #c9c9c9;
$select-dropdown-border: #f4f4f4;
$select-dropdown-option: #131313;
$error-color: #db0303;
$error-toaster-color: #FD6B68;
$success-toaster-color: #5DD584;
$warning-toaster-color: #f6bf00;

$light-gray-shadow: rgba(77, 77, 77, .08);
$headline-color-overlay: rgba(48, 48, 48, .21);
$primary-color-overlay: rgba(152, 99, 207, .65);
$primary-color-faded: rgba(152, 99, 207, .43);

$violet-hover-color: #7740b0;
$yellow-hover-color: #e5b81c;
$primary-hover-color: #7e4ab4;