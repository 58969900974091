.home-banner {
	min-height: auto;
	background: url(../images/mobile_background.jpg) center center no-repeat;
	background-size: cover;
	padding-top: 110px;
	@include media(">tablet") {
		padding-top: 250px;
		background: url(../images/banner.jpg) center -180px no-repeat;
		background-size: cover;
		/*background-position: center -180px;*/
	}
	@include media(">ltablet") {
		min-height: auto;
	}
	@include media(">largerdesktop") {
		background-position: center -180px;
		min-height: 1242px;
	}
	@include media(">ldesktop") {
		background-position: center -180px;
	}
	&-content {
		&-top {
			max-width: 1225px;
			p {
				max-width: 730px;
				margin: 0 auto;
			}
			h6 {
				font-weight: $semi-bold;
			}
			h1 {
				span {
					color: $primary-color;
					text-transform: uppercase;
				}
			}
			.button {
				margin: 35px auto 42px;
				@include media(">tablet") {
					margin: 35px auto 90px;
				}
				@include media(">ltablet") {
					margin: 50px auto 50px;
				}
			}
		}
		&-bottom {
			max-height: 350px;
			@include media(">tablet") {
				max-height: 100%;
			}
			.desktop {
				display: none;
				@include media(">tablet") {
					display: block;
				}
			}
			.mobile {
				display: block;
				@include media(">tablet") {
					display: none;
				}
			}
		}
	}
}
.home-banner--feature {
	min-height: auto;
	background-position: center center;
	@include media(">mdesktop") {
		min-height: 805px;
	}
	.container {
		padding-left: 0;
		padding-right: 0;
		@include media(">tablet") {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.home-banner-content-top {
		@include media("<=tablet") {
			padding-left: 27px;
			padding-right: 27px;
		}
		h6 {
			@include media("<=tablet") {
				font-size: $root-font-size + 2;
				line-height: 24px;
			}
		}
		.button {
			margin-bottom: 136px;
			@include media("<=mdesktop") {
				margin-bottom: 80px;
			}
			@include media("<=tablet") {
				margin-bottom: 42px;
			}
		}
	}
}
.home-banner--contact {
	background-position: center -120px;
	background-color: $faded-white;
	@include media(">ltablet") {
		background-position: center center;
		min-height: 1000px;
	}
	@include media(">mdesktop") {
		min-height: 1242px;
	}
	.home-banner-content {
		p {
			max-width: 545px;
		}
		h6 {
			@include media("<=tablet") {
				font-size: $root-font-size + 2;
				line-height: 24px;
			}
		}
		.input-wrapper {
			margin-top: 45px;
			@include media("<=tablet") {
				margin-top: 60px;
			}
		}
	}
}
.contact-cta-grp {
	@include media("<=tablet") {
		margin-top: 30px;
	}
}

/*macbook image clone*/

.frame-outer {
	margin: 0 auto;
	position: relative;
}
.frame-inner {
	background: red;
    position: absolute;
    top: 83px;
    left: 325px;
    /* right: 0; */
    max-width: 973px;
    height: 603px;
    /* margin: 0 auto; */
    width: 100%;
    overflow: hidden;
    img {
    	width: 100%;
    }
}


.mac_container {
	width: 85%;
	margin: 10px auto;
	display: none;
	@include media(">tablet") {
		display: block;
	}
}
.mac_scaler {
	margin: auto;
	width: 100%;
	padding-top: 54.875%;
	height: 0px;
	position: relative;
}
.mac_holder {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}
.mac_screen {
	margin: auto;
	background: #111;
	width: 81%;
	height: 94%;
	border-top-left-radius: 2% 3%;
	border-top-right-radius: 2% 3%;
	border-bottom-left-radius: 5% 3%;
	border-bottom-right-radius: 5% 3%;
	box-sizing: border-box;
	padding: 3%;
	position: relative;
	overflow: hidden;
	border: 1px solid #ddd;
	&:before {
		content: '';
		position: absolute;
		right: 0.3%;
		top: 0.5%;
		width: 36.5%;
		height: 35%;
		border: 1px solid #666;
		border-bottom: none;
		border-left: none;
		border-top-right-radius: 4.5% 7%;
		display: none;
	}
	&:after {
		content: '';
		position: absolute;
		right: -25%;
		top: -25%;
		width: 40%;
		height: 150%;
		background: rgba(200,200,200,0.2);
		background: linear-gradient(rgba(255,255,255,0.2), rgba(200,200,200,0) 40%);
		transform: rotate(-30deg);
		display: none;
	}
}
.mac_camera {
	background: #333;
	border-radius: 50%;
	width: 1%;
	height: 1.5%;
	position: absolute;
	left: 49.5%;
	top: 2%;
	&:after {
		content: '';
		position: absolute;
		left: 35%;
		top: 40%;
		width: 30%;
		height: 30%;
		border-radius: 50%;
		background: #777;
	}
}
.mac_bottom {
	background: #e9e9e9;
	background: linear-gradient(#ccc 50%, #444);
	width: 100%;
	height: 5%;
	position: relative;
	top: -1.7%;
	border-bottom-left-radius: 6% 50%;
	border-bottom-right-radius: 6% 50%;
	&:after {
		content: '';
		position: absolute;
		right: 1%;
		bottom: 11%;
		width: 98%;
		height: 0%;
		box-shadow: 1px 0px 8px 1px #333;
	}
}
.mac_bottom_top_half {
	width: 100%;
	height: 50%;
	background: #e9e9e9;
	background: linear-gradient(90deg, #aaa, #f3f3f3 0.5%, #aaa 2.5%, #f3f3f3 5.5%, #f3f3f3 94.5%, #aaa 97.5%, #f3f3f3 99.5%, #aaa);
}
.mac_thumb_space {
	background: #bbb;
	background: radial-gradient(90% 150% at 50% 1%, #eee 49%, #888);
	margin: auto;
	width: 15%;
	height: 60%;
	border-bottom-left-radius: 8% 100%;
	border-bottom-right-radius: 8% 100%;
}
.mac_screen_content {
	background: #333;
	width: 100%;
	height: 100%;
	text-align: center;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

/*mobile screen*/
.phone-wrapper {
	max-width: 290px;
	margin: 0 auto;
	display: table;
	@include media(">tablet") {
		display: none;
	}
	.phone {
		height: 600px;
		position: relative;
		border: 8px solid $black;
		border-radius: 4.5vh;
    	overflow: hidden;
		img {
			height: 100%;
			display: block;
			border-radius: 2.5vh;
			width: 100%;
		    object-fit: cover;
		    object-position: center;
		}
		.notch-container {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100px;
			.notch {
				width: 20vh;
				height: 4.25vh;
				margin: 0 auto;
				background-color: #111;
				border-bottom-left-radius: 2.334vh;
				border-bottom-right-radius: 2.334vh;
			}
		}
	}
}