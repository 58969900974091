.postlogin-sidebar {
	float: none;
	position: fixed;
	background: $faded-white;
	top: 0;
	max-height: 100%;
	height: 100%;
	z-index: 2;
	padding-top: 84px;
	left: 0;
	width: 100%;
	right: 0;
	display: none;
	@include media(">ltablet") {
		padding-top: 120px;
		width: 280px;
		float: left;
		left: auto;
		right: auto;
		display: block;
	}
	.sidebar-menu {
		padding: 15px;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		display: none;
		@include media(">ltablet") {
			padding: 0 0 15px 0;
			display: block;
		}
		ul {
			li {
				a {
					display: inline-block;
				    width: 100%;
				    text-decoration: none;
				    position: relative;
				    padding: 12px 30px 12px 20px;
				    @include transition(all, .3s, ease);
				    @include borderradius(27px);
				    @include media(">ltablet") {
						@include borderradius(0px);
						width: auto;
				    }
				    span {
				    	font-size: 20px;
				    	color: $headline-color;
				    	font-weight: $bold;
				    	line-height: 24px;
				    	/*@include media(">ltablet") {
				    		color: $primary-color;
				    	}*/
				    }
				}
				&:hover {
					a {
						
					}
				}
				&.sidebar-dropdown {
					/*a {
						&:after {
					    	font-family: 'Material Icons';
							content: "keyboard_arrow_down";
							-webkit-font-feature-settings: 'liga';
						    font-style: normal;
						    display: inline-block;
						    font-style: normal;
						    font-variant: normal;
						    text-rendering: auto;
						    -webkit-font-smoothing: antialiased;
						    -moz-osx-font-smoothing: grayscale;
						    text-align: center;
						    background: 0 0;
						    position: absolute;
						    right: 15px;
						    top: 50%;
						    @include transform(translateY(-50%));
						    color: $black;
						    font-size: 22px;
						    @include media("<=ltablet") {
						    	content: "settings";
						    	color: $faded-white;
						    }
					    }
					}*/
					.main-menu {
						@include flexbox;
						justify-content: space-between;
						align-items: center;
						.toggler {
							cursor: pointer;
							font-size: $root-font-size + 7;
							margin-right: 14px;
							&-desktop {
								@include media("<=ltablet") {
									display: none;
								}
							}
							&-mobile {
								@include media(">=ltablet") {
									display: none;
								}
							}
						}
					}
					.sidebar-submenu {
						display: none;
						ul {
							li {
								padding-left: 15px;
								a {
									color: $bodytext-color;
									font-size: $root-font-size - 4;
									/*line-height: 1;*/
									letter-spacing: 0.35px;
									padding: 8px 20px;
									background: transparent;
								    @include transition(all, .3s, ease);
								    @include borderradius(27px);
								    @include flexbox;
								    align-items: center;
								    justify-content: space-between;
									&:after {
										@include media(">ltablet") {
											content: "settings";
							    			color: $faded-white;
							    			font-family: 'Material Icons';
											-webkit-font-feature-settings: 'liga';
										    font-style: normal;
										    display: inline-block;
										    font-style: normal;
										    font-variant: normal;
										    text-rendering: auto;
										    -webkit-font-smoothing: antialiased;
										    -moz-osx-font-smoothing: grayscale;
										    text-align: center;
										    background: 0 0;
										    position: absolute;
										    right: 15px;
										    top: 50%;
										    @include transform(translateY(-50%));
										    font-size: 22px;
							    		}
									}
								}
								&:hover {
									a {
										color: $bodytext-color;
										@include media(">ltablet") {
											color: $primary-color;
										}
									}
								}
								&.active {
									a {
										color: $primary-color;
										@include media(">ltablet") {
											background: $primary-color;
											color: $white;
										}
										/*&:after {
											@include media(">ltablet") {
						    					color: $white;
						    				}
										}*/
									}
								}
							}
						}
					}
					&.active {
						a {
							@include media("<=ltablet") {
								background: $primary-color;
								width: 100%;
							}
							span {
								color: $primary-color;
								@include media("<=ltablet") {
									color: $white;
								}
							}
							&:after {
								@include transform(translateY(-50%) rotate(180deg));
							}
						}
						.main-menu {
							.toggler {
								position: absolute;
								/*right: 15px;*/
								right: 0;
								@include media("<=ltablet") {
									right: 15px;
								}
								&-mobile {
									color: $white;
								}
							}
						}
					}
				}

				&.message-count-place {
					display: flex;
					justify-content: space-between;
					align-items: center;

						.message-count {
							background: $primary-color;
							color:$white;
							font-size: 12px;
							border-radius: 50%;
							min-width: 19px;
							min-height: 19px;
							padding: 0px 3px;
							text-align: center;
							margin-right: 16px;
						}
				}
			}
		}
		&.sidebar-active {
			display: block;
			-webkit-animation: fade-in 1.5s ease-out;
        	-moz-animation: fade-in 1.5s ease-out;
		}
	}
	&.sidebar-show {
		display: block;
		/*display: flex;
		justify-content: space-between;
		flex-direction: column;*/
	}
	&.sidebar-show-user {
		display: block;
	}
}