@charset "UTF-8";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.0/css/all.min.css");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: bottom;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  /* Colors: */
  --unnamed-color-8a8a8f: #8A8A8F;
  --unnamed-color-fd6b05: #FD6B05;
  --unnamed-color-d1d0d4: #D1D0D4;
  --unnamed-color-707070: #707070;
  --unnamed-color-727272: #727272;
  --unnamed-color-fd710c: #FD710C;
  --unnamed-color-2ce836: #2CE836;
  --unnamed-color-ff6700: #FF6700;
  --unnamed-color-848484: #848484;
  --unnamed-color-c8c7cc: #C8C7CC;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-cecece: #CECECE;
  --unnamed-color-000000: #000000;
}

html, body {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background: #FCFCFC;
}
body.bodyopen {
  position: fixed;
}

p {
  line-height: 25px;
  margin-bottom: 25px;
  color: #909090;
  font-size: 14px;
  line-height: 19px;
}
@media screen and (min-width: 320px) {
  p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) {
  p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1600px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1920px) {
  p {
    font-size: 18px;
    line-height: 28px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  margin: 0 0 25px;
  color: #303030;
  font-weight: 700;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px;
  }
}

h1 {
  font-size: 40px;
  line-height: 45px;
}
@media screen and (min-width: 320px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1600px) {
  h1 {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1920px) {
  h1 {
    font-size: 65px;
    line-height: 75px;
  }
}

h2, .h2 {
  font-size: 30px;
  line-height: 35px;
}
@media screen and (min-width: 320px) {
  h2, .h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 768px) {
  h2, .h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 1600px) {
  h2, .h2 {
    font-size: 45px;
    line-height: 55px;
  }
}
@media screen and (min-width: 1920px) {
  h2, .h2 {
    font-size: 45px;
    line-height: 55px;
  }
}

h3, .h3 {
  font-size: 25px;
  line-height: 30px;
}
@media screen and (min-width: 320px) {
  h3, .h3 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 768px) {
  h3, .h3 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media screen and (min-width: 1600px) {
  h3, .h3 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 1920px) {
  h3, .h3 {
    font-size: 40px;
    line-height: 50px;
  }
}

h4, .h4 {
  font-size: 22px;
  line-height: 27px;
}
@media screen and (min-width: 320px) {
  h4, .h4 {
    font-size: 22px;
    line-height: 32px;
  }
}
@media screen and (min-width: 768px) {
  h4, .h4 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1600px) {
  h4, .h4 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media screen and (min-width: 1920px) {
  h4, .h4 {
    font-size: 35px;
    line-height: 45px;
  }
}

h5, .h5 {
  font-size: 20px;
  line-height: 25px;
}
@media screen and (min-width: 320px) {
  h5, .h5 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) {
  h5, .h5 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 1600px) {
  h5, .h5 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1920px) {
  h5, .h5 {
    font-size: 30px;
    line-height: 40px;
  }
}

h6, .h6 {
  font-size: 18px;
  line-height: 23px;
}
@media screen and (min-width: 320px) {
  h6, .h6 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 768px) {
  h6, .h6 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1600px) {
  h6, .h6 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 1920px) {
  h6, .h6 {
    font-size: 25px;
    line-height: 35px;
  }
}

.letter-spacing--07 {
  letter-spacing: -0.07px;
}

.letter-spacing--09 {
  letter-spacing: -0.09px;
}

.letter-spacing--024 {
  letter-spacing: -0.24px;
}

.letter-spacing--039 {
  letter-spacing: -0.39px;
}

.opacity--44 {
  opacity: 0.44;
}

ul li {
  -webkit-font-smoothing: antialiased;
}

.container {
  max-width: 1642px !important;
}
.container-big {
  max-width: 1810px !important;
}

a, input, button, h1, h2, h3, h4, h5, h6, svg, input[type=text], input[type=search], input[type=file], input[type=time], input[type=date], input[type=tel], input[type=email], input[type=password], input[type=url], textarea, select {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-font-smoothing: antialiased;
}

input:hover, input:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}

a {
  color: #9863CF;
  font-weight: 400;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

/*.p-32 {
    padding: 32px !important;
    @include media("<=tablet") {
        padding: 18px !important;
    }
}*/
.mb-40 {
  margin-bottom: 30px !important;
}
@media (min-width: 992px) {
  .mb-40 {
    margin-bottom: 40px !important;
  }
}

#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  z-index: 1;
  background: rgba(48, 48, 48, 0.81);
  height: 100vh;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

p a {
  color: #9863CF;
  font-weight: 400;
  text-decoration: underline;
}
p a:hover {
  color: #7e4ab4;
  text-decoration: none;
}
@media (max-width: 991px) {
  p {
    margin: 0 0 15px;
  }
}

span.error, span.valid {
  display: block;
  text-align: left;
  top: -8px;
  position: relative;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  padding-left: 16px;
  margin-bottom: 8px;
}

span.error {
  color: #db0303;
  text-align: left;
}

span.valid {
  color: #303030;
  text-align: left;
}

.overlay {
  position: relative;
}
.overlay:after {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  opacity: 0.2;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
.overlay--fullwhite:after {
  background: rgba(255, 255, 255, 0.9);
}

.overlay--fullBlack:after {
  background-color: #303030;
}

.borderBottom {
  position: relative;
}
.borderBottom:after {
  bottom: 0;
  left: 0;
  content: "";
  width: 140px;
  height: 1px;
  border: 1px dashed #fff;
  position: absolute;
  z-index: 1;
}
.borderBottom--full:after {
  width: 100%;
}

.blank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.clear, .commonSectionInner ul li, .commonSectionInner ol li {
  width: 100%;
  float: left;
}

.dividerSide {
  margin: 0 0 30px;
}
.dividerSide:before, .dividerSide:after {
  position: absolute;
  top: 50%;
  content: "";
  width: 129px;
  height: 1px;
  border: 1px dashed #fff;
  opacity: 0.4;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .dividerSide:before, .dividerSide:after {
    display: none !important;
  }
}
.dividerSide:before {
  left: 100%;
}
.dividerSide:after {
  right: 100%;
}
.dividerSide h1, .dividerSide h2, .dividerSide h3, .dividerSide h4, .dividerSide h5, .dividerSide h6 {
  padding: 0 15px;
  margin: 0 !important;
}
@media (max-width: 991px) {
  .dividerSide {
    margin: 0 0 10px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1560px;
  }
}
@media (min-width: 1800px) {
  .container {
    max-width: 1748px;
  }
}
@media (min-width: 1919px) {
  .container {
    max-width: 1748px;
  }
}
@media (max-width: 1024px) {
  .container--postLogin {
    max-width: 100%;
  }
}

.bannersubheading, .subheading {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 15px;
  font-style: italic;
}
@media (max-width: 991px) {
  .bannersubheading, .subheading {
    font-size: 15px;
    margin-bottom: 0;
  }
}

.alignMiddle, .addedSuccesful .addedSuccesfulIcon, .addedSuccesful {
  display: flex;
  justify-content: center;
  align-items: center;
}

.o-commonSection {
  padding: 51px 0;
}
@media (max-width: 1024px) {
  .o-commonSection {
    padding: 31px 0;
  }
}
@media (max-width: 1024px) {
  .o-commonSection--innerPage {
    padding: 0;
  }
}

.slick-slide:hover, .slick-slide:focus {
  outline: none;
}

.slick-arrow {
  width: 25px !important;
  height: 40px !important;
  z-index: 2;
}
.slick-arrow:before {
  display: none;
}
.slick-arrow .slickIcon {
  width: 12px;
  height: 23px;
  fill: #fff;
}
@media (min-width: 1919px) {
  .slick-arrow {
    width: 57px !important;
    height: 50px !important;
  }
}

.font100 {
  font-weight: 100;
}

.font200 {
  font-weight: 200;
}

.font300 {
  font-weight: 300;
}

.font400 {
  font-weight: 400;
}

.font500 {
  font-weight: 500;
}

.font600 {
  font-weight: 600;
}

.font700 {
  font-weight: 700;
}

.font800 {
  font-weight: 800;
}

.font900 {
  font-weight: 900;
}

.commonSectionInner:not(:last-child) {
  padding: 0 0 101px;
}
@media (max-width: 991px) {
  .commonSectionInner:not(:last-child) {
    padding-bottom: 51px;
  }
}
.commonSectionInner ul, .commonSectionInner ol {
  padding: 0 0 15px;
  overflow: hidden;
}
.commonSectionInner ul li, .commonSectionInner ol li {
  position: relative;
  padding-left: 35px;
}
.commonSectionInner ul li:before, .commonSectionInner ol li:before {
  background: url(../images/arrow-dropright-circle.svg) no-repeat center center/cover;
  position: absolute;
  top: 4px;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
}
.commonSectionInner ul li:not(:last-child), .commonSectionInner ol li:not(:last-child) {
  padding-bottom: 15px;
}

.boxImageWrap {
  height: 350px;
  overflow: hidden;
  border: 1px solid #303030;
}
.boxImageWrap .boxImage {
  position: absolute;
  top: 0;
  left: 0;
  background-attachment: fixed !important;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s 0.3s ease-in-out;
  -moz-transition: all 0.5s 0.3s ease-in-out;
  -ms-transition: all 0.5s 0.3s ease-in-out;
  -o-transition: all 0.5s 0.3s ease-in-out;
  transition: all 0.5s 0.3s ease-in-out;
  -webkit-transform: translateZ(0) scale(1);
  -moz-transform: translateZ(0) scale(1);
  -ms-transform: translateZ(0) scale(1);
  -o-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
}
.boxImageWrap .boxContent {
  color: #fff;
  z-index: 2;
  left: 55px;
  bottom: 15px;
}
@media (max-width: 991px) {
  .boxImageWrap .boxContent {
    left: 15px;
  }
}
.boxImageWrap .boxImageWrapBlank {
  z-index: 3;
}
.boxImageWrap:hover .boxImage {
  -webkit-transform: translateZ(0) scale(1.2);
  -moz-transform: translateZ(0) scale(1.2);
  -ms-transform: translateZ(0) scale(1.2);
  -o-transform: translateZ(0) scale(1.2);
  transform: translateZ(0) scale(1.2);
  -webkit-transition: all 0.5s 0.3s ease-in-out;
  -moz-transition: all 0.5s 0.3s ease-in-out;
  -ms-transition: all 0.5s 0.3s ease-in-out;
  -o-transition: all 0.5s 0.3s ease-in-out;
  transition: all 0.5s 0.3s ease-in-out;
}
@media (min-width: 1600px) {
  .boxImageWrap {
    height: 470px;
  }
}
@media (max-width: 1024px) {
  .boxImageWrap {
    height: 250px;
  }
}

.colorWhite {
  color: #fff;
}

@media (min-width: 1920px) {
  .col-10 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.o-breadcrumb {
  position: relative;
  z-index: 2;
  margin: 0 0 30px;
}
.o-breadcrumb .o-breadcrumbItem {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  font-size: 14px;
  position: relative;
  margin: 0 5px 0 0;
}
.o-breadcrumb .o-breadcrumbItem:before {
  float: right;
  content: "/";
  padding: 0 0 0 8px;
}
.o-breadcrumb .o-breadcrumbItem:hover {
  color: #303030;
}
.o-breadcrumb .o-breadcrumbItem.active {
  color: #303030;
  margin: 0;
}
.o-breadcrumb .o-breadcrumbItem.active:before {
  display: none;
}

.columns--md--3 {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
}

.columns--md--4 {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
}

.columns--md--6 {
  -moz-column-count: 6;
  -webkit-column-count: 6;
  column-count: 6;
}

.addedSuccesful {
  /*background: $headline-color;*/
  width: 22px;
  height: 22px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.addedSuccesful .addedSuccesfulIcon {
  border: 2px solid #fff;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.addedSuccesful .correct {
  width: 11px;
  height: 11px;
  fill: #fff;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .backdropFilter {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .header.is-visible {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .NewsfeedStatus a.wishlisticon {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdropFilter {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .header.is-visible {
    background-color: rgba(146, 44, 136, 0.8);
  }

  .NewsfeedStatus a.wishlisticon {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.abc {
  font-size: 40px;
  line-height: 45px;
}
@media screen and (min-width: 320px) {
  .abc {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 768px) {
  .abc {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1600px) {
  .abc {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1920px) {
  .abc {
    font-size: 65px;
    line-height: 75px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.button {
  padding: 16px;
  /*min-width: 250px;*/
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  letter-spacing: 0.4px;
  color: #fff;
  transition: all 0.5s ease-in-out;
  line-height: 1;
  display: inline-block;
  cursor: pointer;
  /*@include media("<=largerdesktop") {
      height: 45px;
  }
  @include media("<=ltablet") {
      height: 58px;
  }*/
}
.button--violet {
  background: #9863CF;
  border: 1px solid #9863CF;
}
.button--violet:hover:not([disabled]) {
  background: #7740b0;
  border: 1px solid #7740b0;
}
.button--violet:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.button--gray {
  background: #909090;
  border: 1px solid #909090;
}
.button--gray:hover {
  background: #303030;
  border: 1px solid #303030;
}
.button--transparent {
  background: transparent;
  border: 1px solid #9863CF;
  color: #9863CF;
}
.button--transparent:hover {
  background: #9863CF;
  border: 1px solid #9863CF;
  color: #fff;
}
.button--yellow {
  background: #EFBA01;
}
.button--yellow:hover {
  background: #e5b81c;
}
.button--text {
  border: none;
  background: transparent;
  color: #909090;
  font-weight: 400;
}
.button--large {
  min-width: 188px;
}
.button--big {
  min-width: 235px;
}
.button--md {
  min-width: 150px;
}
.button--sm {
  min-width: 115px;
  padding: 12px 16px;
}
.button--form {
  padding: 11px 25px;
}

.input-wrapper-inner {
  max-width: 766px;
  margin: 0 auto;
}
.input-wrapper .form-group {
  margin-bottom: 18px;
}
@media (min-width: 768px) {
  .input-wrapper .form-group {
    margin-bottom: 26px;
  }
}

.form-wrapper .form-group {
  margin-bottom: 20px;
}
.form-wrapper label {
  width: 100%;
  text-align: left;
  color: #9863CF;
  font-size: 16px;
  line-height: 25px;
  padding-left: 16px;
  margin-bottom: 16px;
}
.form-wrapper input {
  padding: 13px 13px 13px 32px;
  font-size: 16px;
}
.form-wrapper input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #909090;
}
.form-wrapper input::-moz-placeholder {
  /* Firefox 19+ */
  color: #909090;
}
.form-wrapper input:-ms-input-placeholder {
  /* IE 10+ */
  color: #909090;
}
.form-wrapper input:-moz-placeholder {
  /* Firefox 18- */
  color: #909090;
}
.form-wrapper textarea {
  height: 190px;
  padding: 28px 28px 28px 32px;
  resize: none;
}
@media (min-width: 768px) {
  .form-wrapper textarea {
    height: 150px;
  }
}
.form-wrapper input, .form-wrapper textarea {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: none;
}
.form-wrapper input:focus, .form-wrapper textarea:focus {
  outline: none;
  box-shadow: none;
}
.form-wrapper .btn-group .button {
  border: none;
  margin-top: 17px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .form-wrapper .btn-group .button {
    margin-top: 15px;
  }
}
.form-wrapper .field-icon {
  float: right;
  margin-right: 15px;
  margin-top: -40px;
  position: relative;
  z-index: 2;
  font-size: 28px;
  color: #DBDBDB;
  cursor: pointer;
}

.link-wrapper .login-failed {
  color: #9863CF;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .link-wrapper .login-failed {
    margin-bottom: 35px;
    color: #303030;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
  }
}

.choose-tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  padding: 4px;
  border: 1px solid #F0F0F0;
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  -ms-border-radius: 46px;
  -o-border-radius: 46px;
  border-radius: 46px;
}
.choose-tabs * {
  z-index: 2;
}
.choose-tabs input[type=radio] {
  display: none;
}
.choose-tabs input[type=radio]:checked + label {
  color: #fff;
}
.choose-tabs .option-tab {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 178px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 99px;
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 0;
  transition: color 0.15s ease-in;
}
.choose-tabs input[id=radio-1]:checked ~ .glider {
  transform: translateX(0);
}
.choose-tabs input[id=radio-2]:checked ~ .glider {
  transform: translateX(100%);
}
.choose-tabs input[id=radio-3]:checked ~ .glider {
  transform: translateX(200%);
}
.choose-tabs .glider {
  position: absolute;
  display: flex;
  height: 44px;
  width: 178px;
  background-color: #9863CF;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}
@media (max-width: 700px) {
  .choose-tabs .tabs {
    transform: scale(0.6);
  }
}

/*input button group*/
.input-with-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.input-with-btn .input-wrap {
  margin-right: 20px;
  width: 100%;
}
.input-with-btn .input-wrap .form-group {
  width: 100%;
}
.input-with-btn .input-wrap-small {
  max-width: 28%;
  width: auto;
}
.input-with-btn a {
  margin-top: 10px;
}
.input-with-btn a:not(:first-of-type) {
  margin-left: 10px;
}
.input-with-btn .split-btns {
  margin-top: 10px;
}
.input-with-btn .split-btns a {
  margin-top: 0;
}
.input-with-btn--start {
  align-items: flex-start;
}
.input-with-btn--start .preurl {
  margin: 15px 25px 0 0;
  font-size: 16px;
  color: #909090;
}

/*radio group*/
.radio-grp-full ul li {
  position: relative;
  display: inline-block;
  text-align: center;
  margin: 0 12px 12px 0;
  vertical-align: top;
}
.radio-grp-full ul li label, .radio-grp-full ul li input {
  display: block;
}
.radio-grp-full ul li input {
  position: absolute;
}
.radio-grp-full ul li label {
  padding: 11px 25px;
  margin-bottom: 0 !important;
  border: 1px solid #F0F0F0;
  cursor: pointer;
  z-index: 9;
  letter-spacing: -0.08px;
  font-size: 14px;
  line-height: 1 !important;
  font-weight: 400;
  color: #909090 !important;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  border-radius: 35px;
}
.radio-grp-full ul li label:hover {
  border-color: #9863CF;
  color: #9863CF !important;
}
.radio-grp-full ul li input[type=radio], .radio-grp-full ul li input[type=checkbox] {
  opacity: 0.01;
  z-index: 10;
}
.radio-grp-full ul li input[type=radio]:checked + label, .radio-grp-full ul li .Checked + label, .radio-grp-full ul li input[type=checkbox]:checked + label, .radio-grp-full ul li .Checked + label {
  border-color: #9863CF;
  color: #9863CF !important;
}

/*custom checker switch*/
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #F0F0F0;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: #303030;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: transparent;
}
input:checked + .slider:before {
  transform: translateX(26px);
  background-color: #28A745;
}
input:focus + .slider {
  outline: none;
}

.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.pager-table {
  border-collapse: separate;
}
.pager-table thead tr th {
  color: #303030;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 700;
  border-top: none;
  padding: 22px;
  border-bottom: none;
  /*border-bottom: 1px solid $lightgray-border;*/
  vertical-align: middle;
}
@media (max-width: 767px) {
  .pager-table thead tr th:nth-child(2), .pager-table thead tr th:nth-child(4) {
    display: none;
  }
}
@media (max-width: 767px) {
  .pager-table thead tr th:nth-child(3) {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .pager-table tbody tr {
    cursor: pointer;
  }
}
.pager-table tbody tr.opened .additional-info {
  display: block;
}
.pager-table tbody tr td {
  padding: 16px 22px;
  letter-spacing: 0.35px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #909090;
  vertical-align: middle;
  border-top: 1px solid #F0F0F0;
}
.pager-table tbody tr td .icon {
  width: 16px;
  height: 20px;
  fill: #909090;
  margin-right: 20px;
}
.pager-table tbody tr td .icon-sm {
  width: 14px;
  height: 14px;
  margin-right: 16px;
  position: relative;
  fill: #909090;
}
.pager-table tbody tr td .icon-sm--eye {
  width: 24px;
  height: 24px;
}
.pager-table tbody tr td a {
  margin-right: 20px;
  position: relative;
  /*&:focus {
  	&::after {
  		content: attr(data-title);
  		position: absolute;
  		left: 50%;
  		top: -50px;
  		transform: translateX(-50%);
  		width: auto;
  		white-space: nowrap;
  		background: $white;
  		border-radius: 2px;
  		padding: 10px 15px;
  		@include borderradius(5px);
  		font-size: $root-font-size - 6;
  		line-height: 15px;
  		font-weight: $regular;
  		letter-spacing: 0.3px;
  		color: $bodytext-color;
  		@include boxshadow(0px, 0px, 6px, 0px, #9863CF26);
  	}
  }*/
}
.pager-table tbody tr td a .icon {
  margin-right: 0;
}
.pager-table tbody tr td a .icon-sm {
  margin-right: 0;
  fill: #303030;
}
.pager-table tbody tr td a:first-of-type:hover::before {
  bottom: 20px;
}
.pager-table tbody tr td a:last-child {
  margin-right: 0;
}
.pager-table tbody tr td p {
  margin-bottom: 0;
  letter-spacing: 0.35px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #909090;
}
.pager-table tbody tr td .custom-switch span {
  padding-left: 10px;
  line-height: 1;
}
@media (max-width: 767px) {
  .pager-table tbody tr td:nth-child(2), .pager-table tbody tr td:nth-child(4) {
    display: none;
  }
}
@media (max-width: 767px) {
  .pager-table tbody tr td:nth-child(3) {
    text-align: right;
    width: 110px;
    border-right: 1px solid transparent;
  }
}
.pager-table tbody tr td:first-child {
  border-left: 2px solid transparent;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media (min-width: 768px) {
  .pager-table tbody tr.hiddenRow {
    display: none;
  }
}
.pager-table tbody tr.hiddenRow .hiddenData {
  padding: 0;
  background-color: #fff;
  border: none;
  border-left: 2px solid transparent;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.pager-table tbody tr.hiddenRow .hiddenData.selected {
  border-left: 2px solid #9863CF;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.pager-table tbody tr.hiddenRow .hiddenData table {
  width: 100%;
}
.pager-table tbody tr.hiddenRow .hiddenData table tr {
  border: 1px solid #F0F0F0;
  border-bottom: none;
  border-top: none;
}
.pager-table tbody tr.hiddenRow .hiddenData table tr td {
  border: none;
  line-height: 1;
  padding: 9px 22px;
}
@media (max-width: 767px) {
  .pager-table tbody tr.hiddenRow .hiddenData table tr td:nth-child(2), .pager-table tbody tr.hiddenRow .hiddenData table tr td:first-child {
    display: table-cell;
  }
}
.pager-table tbody tr.hiddenRow .hiddenData table tr td:first-child {
  padding-left: 58px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #303030;
}
.pager-table tbody tr.hiddenRow .hiddenData table tr td:last-child {
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  color: #909090;
}
.pager-table tbody tr.hiddenRow .hiddenData table tr:last-child td {
  padding-bottom: 30px;
}
.pager-table tbody tr.selected {
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /*border-left: 2px solid $primary-color;*/
}
@media (max-width: 767px) {
  .pager-table tbody tr.selected td:first-child {
    border-left: 2px solid #9863CF;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}
@media (max-width: 767px) {
  .pager-table tbody tr.selected td:nth-child(3) {
    border-right: 1px solid #F0F0F0;
  }
}
.pager-table--ratestable thead tr th {
  padding-left: 0;
  padding-top: 0;
}
.pager-table--ratestable thead tr th:last-child {
  text-align: right;
}
@media (max-width: 767px) {
  .pager-table--ratestable thead tr th:nth-child(2) {
    display: block;
  }
}
.pager-table--ratestable tbody tr td {
  padding-left: 0;
}
@media (max-width: 767px) {
  .pager-table--ratestable tbody tr td:nth-child(2) {
    display: block;
  }
}
@media (max-width: 991px) {
  .pager-table--ratestable tbody tr {
    cursor: auto;
  }
}
.pager-table--links {
  margin-top: 19px;
}
@media (max-width: 767px) {
  .pager-table--links thead tr th:nth-child(3) {
    display: none;
  }
}
.pager-table--links thead tr th:nth-child(4) {
  width: 100px;
}
@media (max-width: 767px) {
  .pager-table--links thead tr th:nth-child(4) {
    display: block;
    width: 100%;
    text-align: right;
  }
}
.pager-table--links tbody tr td a.url {
  color: #909090;
}
.pager-table--links tbody tr td a.url:hover:after {
  display: none;
}
.pager-table--links tbody tr td a.url:hover:before {
  display: none;
}
@media (max-width: 767px) {
  .pager-table--links tbody tr td:nth-child(3) {
    display: none;
  }
}
@media (max-width: 767px) {
  .pager-table--links tbody tr td:nth-child(4) {
    display: block;
  }
}
@media (max-width: 767px) {
  .pager-table--links tbody tr.selected td:nth-child(4) {
    border-right: 1px solid #F0F0F0;
  }
}
.pager-table--links tbody tr.hiddenRow td.hiddenData table tr td:first-child {
  padding-left: 22px;
}
.pager-table--reduce-space tbody tr.hiddenRow .hiddenData table tr td:first-child {
  padding-left: 22px;
}
@media (max-width: 767px) {
  .pager-table--new-table thead tr th:nth-child(2), .pager-table--new-table thead tr th:nth-child(4) {
    display: block;
  }
}
.pager-table--new-table tbody tr.selected td:nth-child(7) {
  border-right: 1px solid #f0f0f0;
}
@media (max-width: 767px) {
  .pager-table--new-table tbody tr td:nth-child(2), .pager-table--new-table tbody tr td:nth-child(4) {
    display: block;
  }
}
.pager-table--new-table tbody tr td .tooltip-link .icon-sm--place {
  font-size: 17px;
  color: #000;
}
.pager-table--new-table tbody tr td .tooltip-link .icon-sm--place:hover {
  color: #9863CF;
}

.template-wrapper {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
}
@media (min-width: 640px) {
  .template-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }
}
@media (min-width: 1200px) {
  .template-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  }
}
.template-wrapper--layout {
  margin-bottom: 0px;
}
@media (min-width: 1200px) {
  .template-wrapper--layout {
    margin-bottom: 80px;
  }
}
.template-wrapper--layout .template-box-header {
  opacity: 0;
  min-height: 16px;
}
.template-wrapper--layout .template-box-header h5 {
  font-size: 14px;
  line-height: 17px;
}
.template-wrapper--layout .template-box-inner {
  border: 1px solid #F0F0F0;
}
@media (max-width: 1199px) {
  .template-wrapper--layout .template-box .page-btns {
    display: none;
  }
}
.template-wrapper--layout .template-box.active .template-box-header {
  opacity: 1;
}
.template-wrapper--layout .template-box.active .template-box-inner {
  border: 5px solid #9863CF;
}
.template-wrapper--layout:last-child {
  margin-bottom: 0;
}
.template-wrapper--gallery {
  margin-bottom: 0px;
}
@media (min-width: 1200px) {
  .template-wrapper--gallery {
    margin-bottom: 80px;
  }
}
.template-wrapper--gallery .template-box-header {
  opacity: 0;
  min-height: 16px;
}
.template-wrapper--gallery .template-box-header h5 {
  font-size: 14px;
  line-height: 17px;
}
.template-wrapper--gallery .template-box-inner {
  border: 1px solid #F0F0F0;
}
@media (max-width: 1199px) {
  .template-wrapper--gallery .template-box .page-btns {
    display: none;
  }
}
.template-wrapper--gallery .template-box.active .template-box-header {
  opacity: 1;
}
.template-wrapper--gallery .template-box.active .template-box-inner {
  border: none;
  -webkit-box-shadow: 0px 0px 10px #925FC680;
  -moz-box-shadow: 0px 0px 10px #925FC680;
  -ms-box-shadow: 0px 0px 10px #925FC680;
  -o-box-shadow: 0px 0px 10px #925FC680;
  box-shadow: 0px 0px 10px #925FC680;
}
.template-wrapper--gallery:last-child {
  margin-bottom: 0;
}

.template-box {
  position: relative;
}
.template-box-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
.template-box-header h5 {
  font-size: 14px;
  line-height: 17px;
  color: #303030;
  font-weight: 400;
  padding-left: 13px;
}
@media (min-width: 768px) {
  .template-box-header h5 {
    font-size: 18px;
    line-height: 22px;
  }
}
.template-box-header span {
  color: #9863CF;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  font-style: italic;
  margin-left: 8px;
  line-height: 1;
}
@media (min-width: 768px) {
  .template-box-header span {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
  }
}
.template-box-inner {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;
}
.template-box-inner img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  -webkit-transition: transform 0.4s ease-in-out;
  -moz-transition: transform 0.4s ease-in-out;
  -ms-transition: transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
}
.template-box-inner:after {
  background: rgba(252, 252, 252, 0.93);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
}
.template-box .page-btns {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  background-color: transparent;
  z-index: 1;
  padding-top: 18px;
  padding-left: 10px;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.template-box .page-btns .button {
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 14px;
  max-width: 108px;
  width: 100%;
}
@media (min-width: 1200px) {
  .template-box .page-btns .button {
    margin-bottom: 12px;
    margin-right: 0;
  }
}
.template-box .page-btns .button:last-child {
  margin-bottom: 0;
  margin-right: 0;
}
@media (min-width: 1200px) {
  .template-box .page-btns {
    opacity: 0;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
    top: 50%;
    padding-left: 0;
    padding-top: 40px;
  }
}
.template-box:hover .template-box-inner img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.template-box:hover .template-box-inner:after {
  opacity: 1;
}
.template-box:hover .page-btns {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}
.template-box--premium .premium-tag-wrapper {
  position: absolute;
  bottom: 32px;
  width: 100%;
  padding-left: 15px;
}
.template-box--premium .premium-tag-wrapper p {
  display: none;
}
.template-box--premium .premium-tag-wrapper .premium-tag {
  position: relative;
}
.template-box--premium .premium-tag-wrapper .premium-tag span {
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  -ms-border-radius: 21px;
  -o-border-radius: 21px;
  border-radius: 21px;
  background: #EFBA01;
  color: #fff;
  padding: 10px 25px 10px 55px;
  letter-spacing: 0.3px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
  min-width: 134px;
  text-align: right;
}
@media (min-width: 1200px) {
  .template-box--premium .premium-tag-wrapper .premium-tag span {
    font-size: 16px;
    min-width: 144px;
    padding: 13px 30px 13px 80px;
    letter-spacing: 0.4px;
  }
}
.template-box--premium .premium-tag-wrapper .premium-tag svg {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 1200px) {
  .template-box--premium .premium-tag-wrapper .premium-tag svg {
    width: 68px;
    height: 68px;
  }
}
.template-box--unsubscribed .premium-tag-wrapper p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  margin: 26px 0 0;
  display: block;
}
@media (min-width: 1200px) {
  .template-box--unsubscribed .premium-tag-wrapper p {
    font-size: 18px;
    line-height: 22px;
  }
}
.template-box--unsubscribed .template-box-inner img {
  filter: blur(3px);
  -moz-filter: blur(3px);
  -webkit-filter: blur(3px);
}
.template-box--unsubscribed:hover .template-box-inner img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.template-box--unsubscribed:hover .template-box-inner:after {
  opacity: 0;
}
.template-box--unsubscribed:hover .page-btns {
  opacity: 0;
}

.faq-pageMain .page-heading {
  font-size: 40px;
  line-height: 45px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #000;
  text-align: center !important;
  text-transform: capitalize;
}
@media screen and (min-width: 320px) {
  .faq-pageMain .page-heading {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 768px) {
  .faq-pageMain .page-heading {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-pageMain .page-heading {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-pageMain .page-heading {
    font-size: 65px;
    line-height: 75px;
  }
}
.faq-pageMain .faqPanel .nav-tabs {
  margin-top: 15px;
  border-bottom: 0px;
}
.faq-pageMain .faqPanel .nav-tabs li:first-child {
  padding-left: 15px;
}
.faq-pageMain .faqPanel .nav-tabs li::before {
  content: "" !important;
}
.faq-pageMain .faqPanel .nav-tabs li a {
  text-decoration: none !important;
  font-size: 16px;
  line-height: 21px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border: 1px solid #9863CF;
  border-radius: 30px;
  padding: 12px 40px;
  display: inline-block;
}
@media screen and (min-width: 320px) {
  .faq-pageMain .faqPanel .nav-tabs li a {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .faq-pageMain .faqPanel .nav-tabs li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-pageMain .faqPanel .nav-tabs li a {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-pageMain .faqPanel .nav-tabs li a {
    font-size: 20px;
    line-height: 30px;
  }
}
.faq-pageMain .faqPanel .nav-tabs li a:hover {
  color: #922C88;
}
.faq-pageMain .faqPanel .nav-tabs li a.active {
  background-color: #9863CF;
  color: #fff;
}
.faq-pageMain .faqPanel .nav-tabs li a.active:hover {
  background-color: #7740b0;
}
.faq-pageMain .panel-body h5 {
  font-size: 20px;
  line-height: 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-left: 30px;
  margin-bottom: 30px;
}
@media screen and (min-width: 320px) {
  .faq-pageMain .panel-body h5 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) {
  .faq-pageMain .panel-body h5 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-pageMain .panel-body h5 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-pageMain .panel-body h5 {
    font-size: 30px;
    line-height: 40px;
  }
}
.faq-pageMain .panel-body h6 {
  font-size: 18px;
  line-height: 23px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .faq-pageMain .panel-body h6 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 768px) {
  .faq-pageMain .panel-body h6 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-pageMain .panel-body h6 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-pageMain .panel-body h6 {
    font-size: 25px;
    line-height: 35px;
  }
}
.faq-pageMain .panel-body button {
  cursor: pointer;
  border: 1px solid #000;
  display: block;
  border-radius: 30px;
  padding: 12px 30px;
  width: 100%;
  text-align: left;
}
.faq-pageMain .panel-body button.btn-header-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-pageMain .panel-body button.btn-header-link.collapsed::after {
  content: "\f067";
}
.faq-pageMain .panel-body button.btn-header-link::after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.faq-pageMain .panel-body .card {
  border: 0px;
  background: none;
}
.faq-pageMain .panel-body .card p {
  font-size: 14px;
  line-height: 19px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  animation: fade 1s ease-in-out;
  margin-bottom: 0;
}
@media screen and (min-width: 320px) {
  .faq-pageMain .panel-body .card p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) {
  .faq-pageMain .panel-body .card p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-pageMain .panel-body .card p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-pageMain .panel-body .card p {
    font-size: 18px;
    line-height: 28px;
  }
}
.faq-pageMain .panel-body .card-header {
  border-bottom: 0px;
  background: none;
  padding: 1rem 1rem;
}

.free-template-btn {
  margin-top: 18px;
}

.minicolors-theme-bootstrap .minicolors-swatch {
  top: 5px !important;
  left: 5px !important;
}

.theme-color-choose-info {
  margin-bottom: 28px !important;
}

.btn-color-update-wrap {
  margin-top: 48px;
}

.btnn-default-theme-color {
  margin-top: 15px;
}
@media (min-width: 640px) {
  .btnn-default-theme-color {
    margin-left: 27px;
  }
}

.card {
  border: 1px solid #F0F0F0;
}

.card-bodywrap {
  padding: 21px 27px 13px;
}

.minicolors .form-control {
  border: 1px solid #F0F0F0;
}
.minicolors .form-control:focus {
  outline: none;
  box-shadow: none;
}

.btnnnactive {
  margin-right: 15px;
  margin-bottom: 15px;
}

.btncolorchange {
  margin-bottom: 15px;
}
@media (max-width: 1024px) {
  .btncolorchange {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.card-bodywrap label {
  color: #909090;
  margin-bottom: 12px;
  font-size: 14px;
}

.info-tag {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 4px;
}
.info-tag:hover {
  cursor: pointer;
}

.tooltip1 {
  position: relative;
  display: inline-block;
}
.tooltip1:hover .tooltiptext {
  visibility: visible;
}
.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #9863CF;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border-radius: 60px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 124%;
  left: 50%;
  margin-left: -60px;
}
@media screen and (min-width: 320px) {
  .tooltip1 .tooltiptext {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 1920px) {
  .tooltip1 .tooltiptext {
    font-size: 14px;
    line-height: 24px;
  }
}
.tooltip1 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #9863CF transparent transparent transparent;
}

.color-theme-modal .modal-header {
  border-bottom: 0px;
  padding: 17px 16px 0px;
}
.color-theme-modal .modal-header .close {
  color: #303030;
  opacity: 1;
}
.color-theme-modal .modal-footer {
  padding: 0px 16px 17px;
  border-top: 0px;
  justify-content: flex-start;
}
.color-theme-modal .modal-footer .modal-title {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #303030;
}
@media screen and (min-width: 320px) {
  .color-theme-modal .modal-footer .modal-title {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .color-theme-modal .modal-footer .modal-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1600px) {
  .color-theme-modal .modal-footer .modal-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .color-theme-modal .modal-footer .modal-title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 1200px) {
  .content-info .tab-min-content {
    min-height: 20vh;
  }
}

.faq-page-wrap .page-heading {
  font-family: "Lato", sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #fff;
  font-weight: 700;
  padding-bottom: 30px !important;
}
@media screen and (min-width: 320px) {
  .faq-page-wrap .page-heading {
    font-size: 40px;
    line-height: 50px;
  }
}
@media screen and (min-width: 768px) {
  .faq-page-wrap .page-heading {
    font-size: 50px;
    line-height: 60px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-page-wrap .page-heading {
    font-size: 65px;
    line-height: 75px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-page-wrap .page-heading {
    font-size: 65px;
    line-height: 75px;
  }
}
@media (min-width: 992px) {
  .faq-page-wrap .page-heading {
    padding-bottom: 30px !important;
  }
}
.faq-page-wrap .faqPanel .nav-tabs {
  margin-bottom: 0px;
  margin-top: 0px;
}
@media (min-width: 768px) {
  .faq-page-wrap .faqPanel .nav-tabs {
    margin-bottom: 0px;
  }
}
.faq-page-wrap .faqPanel .nav-tabs li a {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.4px;
  padding: 9px 40px;
  color: #fff;
  border-radius: 0px;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 20px;
  margin-right: 20px;
}
@media screen and (min-width: 320px) {
  .faq-page-wrap .faqPanel .nav-tabs li a {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .faq-page-wrap .faqPanel .nav-tabs li a {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-page-wrap .faqPanel .nav-tabs li a {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-page-wrap .faqPanel .nav-tabs li a {
    font-size: 20px;
    line-height: 30px;
  }
}
.faq-page-wrap .faqPanel .nav-tabs li a:hover {
  color: #fff;
}
.faq-page-wrap .faqPanel .nav-tabs li a.active {
  background-color: transparent;
  color: #fff;
  border-bottom: 2px solid #fff;
}
.faq-page-wrap .faqPanel .nav-tabs li a.active:hover {
  background: transparent;
}
.faq-page-wrap .panel-body h5 {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  font-weight: 600;
  margin-left: 17px;
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .faq-page-wrap .panel-body h5 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 768px) {
  .faq-page-wrap .panel-body h5 {
    font-size: 25px;
    line-height: 35px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-page-wrap .panel-body h5 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-page-wrap .panel-body h5 {
    font-size: 30px;
    line-height: 40px;
  }
}
.faq-page-wrap .panel-body button {
  border-top: 1px solid #707070;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  background-color: #303030;
  color: #fff;
  padding: 31px 30px 0px;
}
.faq-page-wrap .panel-body button.btn-headerlink-first {
  border-top: none;
}
.faq-page-wrap .panel-body .btn-header-link {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
}
@media screen and (min-width: 320px) {
  .faq-page-wrap .panel-body .btn-header-link {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .faq-page-wrap .panel-body .btn-header-link {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1600px) {
  .faq-page-wrap .panel-body .btn-header-link {
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .faq-page-wrap .panel-body .btn-header-link {
    font-size: 20px;
    line-height: 30px;
  }
}
.faq-page-wrap .faq-panel .card-body {
  padding-top: 15px;
}

.terms-main {
  background-color: #303030;
  margin-top: 80px;
  padding: 70px 0px !important;
}
@media (min-width: 992px) {
  .terms-main {
    margin-top: 120px;
    padding: 84px 0px !important;
  }
}
.terms-main .faq-card .card-body {
  padding-left: 45px;
}
.terms-main .faq-card .card-body p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
}
@media screen and (min-width: 320px) {
  .terms-main .faq-card .card-body p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) {
  .terms-main .faq-card .card-body p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1600px) {
  .terms-main .faq-card .card-body p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1920px) {
  .terms-main .faq-card .card-body p {
    font-size: 18px;
    line-height: 28px;
  }
}

.dropzone .dz-preview {
  z-index: 0;
}

.faq-scroller .mCSB_draggerContainer {
  display: none;
}
.faq-scroller .mCSB_inside > .mCSB_container {
  margin-bottom: 0px;
}

body.modal-open {
  position: fixed;
  margin: 50px auto;
}

.social-app-input-checker-wrapper {
  margin-top: 20px !important;
}

@media (max-width: 766px) {
  .mob-upgrade-box {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}
@media (max-width: 766px) {
  .mob-upgrade-box .upgrade-btn {
    margin-top: 15px;
  }
}

@media (max-width: 374px) {
  .user-menu-top-nav .mob-upgrade-box p {
    font-size: 12px;
  }
}
@media (max-width: 413px) {
  .user-menu-top-nav .mob-upgrade-box p {
    font-size: 14px;
  }
}

.common-wrapper {
  padding: 120px 0;
}

.two-half-block {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
}
.two-half-block-left {
  width: 100%;
  padding: 16px;
}
@media (min-width: 768px) {
  .two-half-block-left {
    padding: 22px;
  }
}
@media (min-width: 1400px) {
  .two-half-block-left {
    padding: 23px 100px 23px 23px;
  }
}
.two-half-block-left .page-top-info .page-header h6 {
  margin-bottom: 14px;
}
.two-half-block-left .content-wrapper .content-info-top {
  /*max-width: 605px;*/
}
.two-half-block-right {
  max-width: 100%;
  width: 100%;
  background: #FCFCFC;
}
@media (min-width: 768px) {
  .two-half-block-right {
    max-width: 568px;
  }
}

.navbar {
  padding: 0;
  background: transparent;
}
.navbar .nav-right {
  width: 100%;
}
.navbar .nav-right-prelogin {
  padding: 35px 0;
}
@media (max-width: 991px) {
  .navbar .nav-right-prelogin {
    padding: 25px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.navbar .nav-right-prelogin-links {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navbar .nav-right-prelogin-links ul li a {
  color: #000;
  letter-spacing: 0.4px;
  font-size: 16px;
  font-weight: 700;
  margin: 0 20px;
  padding: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.navbar .nav-right-prelogin-links ul li a:hover {
  color: #9863CF;
}
.navbar .nav-right-prelogin-links .button {
  margin-left: 20px;
}
.navbar .nav-right-prelogin-links .multilingual {
  margin-left: 26px;
  position: relative;
  clear: both;
  display: table;
  width: 60px;
  height: 30px;
}
@media (max-width: 991px) {
  .navbar .nav-right-prelogin-links {
    display: none;
  }
}
.navbar .nav-right-prelogin .nav-toggle {
  display: none;
  position: relative;
  left: auto;
  cursor: pointer;
}
@media (max-width: 991px) {
  .navbar .nav-right-prelogin .nav-toggle {
    display: block;
  }
}
.navbar .nav-right-prelogin .nav-toggle .bar {
  width: 22px;
  height: 2px;
  background-color: #303030;
  margin: 6px 0;
  transition: 0.4s;
  display: block;
  pointer-events: none;
}
.navbar .nav-right-prelogin .nav-toggle .bar.bar3, .navbar .nav-right-prelogin .nav-toggle .bar.bar1 {
  width: 75%;
  margin-left: auto;
}
.navbar .nav-right-prelogin .nav-toggle.active .bar {
  background-color: #fff;
}
.navbar .nav-right-prelogin .nav-toggle.active .bar.bar1 {
  width: 100%;
}
.navbar .nav-right-postlogin {
  display: none;
}
.navbar-brand {
  line-height: 1;
  max-width: 255px;
  width: 100%;
  padding: 5px 0 0;
}
.navbar-brand svg {
  height: 26px;
  width: 170px;
}
@media (min-width: 414px) {
  .navbar-brand svg {
    width: 195px;
  }
}
@media (min-width: 992px) {
  .navbar-brand svg {
    width: 225px;
  }
}
.navbar-postlogin {
  border: 1px solid #F0F0F0;
  background: #FCFCFC;
}
.navbar-postlogin .nav-right {
  width: 100%;
}
.navbar-postlogin .nav-right-postlogin {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-postlogin .nav-right-postlogin-left {
  padding: 25px;
  border-right: 1px solid #F0F0F0;
  border-left: 1px solid #F0F0F0;
  display: none;
}
@media (min-width: 992px) {
  .navbar-postlogin .nav-right-postlogin-left {
    display: block;
  }
}
.navbar-postlogin .nav-right-postlogin-left p {
  color: #909090;
  font-size: 17px;
  margin-bottom: 0;
}
.navbar-postlogin .nav-right-postlogin-left p span {
  color: #303030;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop {
  display: none;
  padding: 25px 0;
}
@media (min-width: 768px) {
  .navbar-postlogin .nav-right-postlogin-right .postlogin-desktop {
    padding: 18px 0;
  }
}
@media (min-width: 992px) {
  .navbar-postlogin .nav-right-postlogin-right .postlogin-desktop {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .button {
  margin-right: 23px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop ul li a {
  color: #909090;
  letter-spacing: 0.4px;
  font-size: 16px;
  font-weight: 400;
  margin: 0 23px;
  padding: 0 !important;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop ul li a:hover {
  color: #9863CF;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder {
  margin-left: 23px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .accountIcon {
  font-size: 16px;
  font-weight: 400;
  color: #909090;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .accountIcon i {
  color: #000;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown {
  background: #fff;
  position: absolute;
  top: 160%;
  right: 0;
  padding: 26px 15px 0;
  min-width: 256px;
  text-align: left;
  z-index: 2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -moz-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -ms-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -o-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  transition: all 0s ease;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownDesc {
  margin-bottom: 30px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownDesc h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-bottom: 6px;
  color: #303030;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownDesc p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: normal;
  color: #909090;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownList {
  clear: both;
  display: table;
  width: 100%;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownList li {
  line-height: 1;
  /*&:last-child {
  	a {
  		margin-bottom: 0;
  	}
  }*/
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownList li a {
  color: #909090;
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  width: 100%;
  margin-bottom: 16px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownList li a svg {
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 12px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .profileDropdownList li:hover a {
  padding-left: 5px !important;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .dropdown-top {
  padding: 0 20px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .multilingual-group {
  margin-bottom: 22px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .multilingual-group .btn-group .dropdown-toggle {
  padding-left: 0;
  padding-top: 0;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .multilingual-group .btn-group .dropdown-toggle span {
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  margin-right: 10px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .multilingual-group .btn-group .dropdown-toggle:after {
  display: none;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .logout {
  padding: 25px 20px;
  border-top: 1px solid #F0F0F0;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-desktop .dropdown-holder .profileDropdown .logout .profileDropdownList li a {
  margin-bottom: 0;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-mobile {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 25px 0;
}
@media (min-width: 768px) {
  .navbar-postlogin .nav-right-postlogin-right .postlogin-mobile {
    padding: 18px 0;
  }
}
@media (min-width: 992px) {
  .navbar-postlogin .nav-right-postlogin-right .postlogin-mobile {
    display: none;
  }
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-mobile a {
  margin-left: 17px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-mobile a .material-icons {
  font-size: 32px;
}
.navbar-postlogin .nav-right-postlogin-right .postlogin-mobile a:hover {
  color: #9863CF;
}
.navbar-postlogin .nav-right .nav-right-prelogin {
  display: none;
}
.navbar-postlogin .container {
  max-width: 1810px !important;
}
.navbar-postlogin.nav-active {
  box-shadow: none !important;
}
.navbar.fixed-top {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.navbar.nav-active {
  background: #FCFCFC;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  -ms-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

/*.arrow-up {
	&:before {
		position: absolute;
	    top: -5px;
	    right: 10px;
	    content: "";
	    width: 0;
	    height: 0;
	    border-left: 5px solid transparent;
	    border-right: 5px solid transparent;
	}
}
.arrow-up-white {
	&:before {
		border-bottom: 5px solid $primary-color;
	}
}*/
.multilingual-group .btn {
  width: 65px;
  background: none;
  -webkit-appearance: none;
}
.multilingual-group .btn:focus {
  outline: none;
  box-shadow: none;
}
.multilingual-group .btn img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
}
.multilingual-group .dropdown-menu {
  right: 0;
  left: auto;
  min-width: auto;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border: none;
}
.multilingual-group .dropdown-menu li a {
  margin: 8px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
}
.multilingual-group .dropdown-menu li a img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
.multilingual-group .drop-wrap-menu li a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  clear: both;
  overflow: hidden;
  width: auto;
  height: auto;
  margin: 12px 8px !important;
}
.multilingual-group .drop-wrap-menu li a span {
  padding-right: 12px;
  display: inline-block;
}
.multilingual-group .drop-wrap-menu li a img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
}
.multilingual-group .btn-group .dropdown-toggle:after {
  background-image: url(../images/svg/down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  vertical-align: middle;
  width: 15px;
  height: 8px;
  margin-left: 13px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.multilingual-group .btn-group.show .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.site-footer {
  padding: 110px 0;
  background: #FCFCFC;
}
@media (max-width: 767px) {
  .site-footer {
    padding: 60px 0;
  }
}
.site-footer-outer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
@media (max-width: 767px) {
  .site-footer-outer {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.site-footer-outer-info {
  max-width: 540px;
  width: 100%;
}
@media (max-width: 1199px) {
  .site-footer-outer-info {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .site-footer-outer-info {
    max-width: 300px;
  }
}
@media (max-width: 767px) {
  .site-footer-outer-info {
    max-width: 100%;
    text-align: center;
    order: 2;
    -webkit-order: 2;
    margin-top: 60px;
  }
}
.site-footer-outer-info-inner {
  max-width: 325px;
}
@media (max-width: 767px) {
  .site-footer-outer-info-inner {
    max-width: 100%;
  }
}
.site-footer-outer-info-inner svg {
  width: 180px;
  height: 22px;
}
.site-footer-outer-info-inner p {
  margin-top: 25px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .site-footer-outer-info-inner p {
    font-size: 14px;
    margin-top: 18px;
    line-height: 20px;
  }
}
.site-footer-outer-info-inner span {
  margin-top: 25px;
  font-size: 14px;
  line-height: 17px;
  color: #909090;
  letter-spacing: 0.35px;
}
@media (max-width: 767px) {
  .site-footer-outer-info-inner span {
    font-size: 12px;
    margin-top: 22px;
    line-height: 15px;
  }
}
.site-footer-outer-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 767px) {
  .site-footer-outer-menu {
    flex-direction: column;
  }
}

.footer-link-group {
  max-width: 270px;
  width: 100%;
}
.footer-link-group:last-child {
  margin-bottom: 0;
}
@media (max-width: 1400px) {
  .footer-link-group {
    max-width: 100% !important;
    width: 33%;
  }
}
@media (max-width: 767px) {
  .footer-link-group {
    width: 100%;
    margin-bottom: 70px;
    text-align: center;
  }
}
.footer-link-group span {
  color: #303030;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .footer-link-group span {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 18px;
  }
}
.footer-link-group a {
  display: table;
  color: #909090;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 16px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.footer-link-group a:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .footer-link-group a {
    margin: 0 auto 18px auto;
  }
}
.footer-link-group a:hover {
  color: #9863CF;
  padding-left: 10px;
}
@media (max-width: 767px) {
  .footer-link-group a:hover {
    color: #909090;
    padding-left: 0;
  }
}

.home-banner {
  min-height: auto;
  background: url(../images/mobile_background.jpg) center center no-repeat;
  background-size: cover;
  padding-top: 110px;
}
@media (min-width: 768px) {
  .home-banner {
    padding-top: 250px;
    background: url(../images/banner.jpg) center -180px no-repeat;
    background-size: cover;
    /*background-position: center -180px;*/
  }
}
@media (min-width: 992px) {
  .home-banner {
    min-height: auto;
  }
}
@media (min-width: 1401px) {
  .home-banner {
    background-position: center -180px;
    min-height: 1242px;
  }
}
@media (min-width: 1601px) {
  .home-banner {
    background-position: center -180px;
  }
}
.home-banner-content-top {
  max-width: 1225px;
}
.home-banner-content-top p {
  max-width: 730px;
  margin: 0 auto;
}
.home-banner-content-top h6 {
  font-weight: 600;
}
.home-banner-content-top h1 span {
  color: #9863CF;
  text-transform: uppercase;
}
.home-banner-content-top .button {
  margin: 35px auto 42px;
}
@media (min-width: 768px) {
  .home-banner-content-top .button {
    margin: 35px auto 90px;
  }
}
@media (min-width: 992px) {
  .home-banner-content-top .button {
    margin: 50px auto 50px;
  }
}
.home-banner-content-bottom {
  max-height: 350px;
}
@media (min-width: 768px) {
  .home-banner-content-bottom {
    max-height: 100%;
  }
}
.home-banner-content-bottom .desktop {
  display: none;
}
@media (min-width: 768px) {
  .home-banner-content-bottom .desktop {
    display: block;
  }
}
.home-banner-content-bottom .mobile {
  display: block;
}
@media (min-width: 768px) {
  .home-banner-content-bottom .mobile {
    display: none;
  }
}

.home-banner--feature {
  min-height: auto;
  background-position: center center;
}
@media (min-width: 1200px) {
  .home-banner--feature {
    min-height: 805px;
  }
}
.home-banner--feature .container {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .home-banner--feature .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .home-banner--feature .home-banner-content-top {
    padding-left: 27px;
    padding-right: 27px;
  }
}
@media (max-width: 767px) {
  .home-banner--feature .home-banner-content-top h6 {
    font-size: 20px;
    line-height: 24px;
  }
}
.home-banner--feature .home-banner-content-top .button {
  margin-bottom: 136px;
}
@media (max-width: 1199px) {
  .home-banner--feature .home-banner-content-top .button {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .home-banner--feature .home-banner-content-top .button {
    margin-bottom: 42px;
  }
}

.home-banner--contact {
  background-position: center -120px;
  background-color: #FCFCFC;
}
@media (min-width: 992px) {
  .home-banner--contact {
    background-position: center center;
    min-height: 1000px;
  }
}
@media (min-width: 1200px) {
  .home-banner--contact {
    min-height: 1242px;
  }
}
.home-banner--contact .home-banner-content p {
  max-width: 545px;
}
@media (max-width: 767px) {
  .home-banner--contact .home-banner-content h6 {
    font-size: 20px;
    line-height: 24px;
  }
}
.home-banner--contact .home-banner-content .input-wrapper {
  margin-top: 45px;
}
@media (max-width: 767px) {
  .home-banner--contact .home-banner-content .input-wrapper {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .contact-cta-grp {
    margin-top: 30px;
  }
}

/*macbook image clone*/
.frame-outer {
  margin: 0 auto;
  position: relative;
}

.frame-inner {
  background: red;
  position: absolute;
  top: 83px;
  left: 325px;
  /* right: 0; */
  max-width: 973px;
  height: 603px;
  /* margin: 0 auto; */
  width: 100%;
  overflow: hidden;
}
.frame-inner img {
  width: 100%;
}

.mac_container {
  width: 85%;
  margin: 10px auto;
  display: none;
}
@media (min-width: 768px) {
  .mac_container {
    display: block;
  }
}

.mac_scaler {
  margin: auto;
  width: 100%;
  padding-top: 54.875%;
  height: 0px;
  position: relative;
}

.mac_holder {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.mac_screen {
  margin: auto;
  background: #111;
  width: 81%;
  height: 94%;
  border-top-left-radius: 2% 3%;
  border-top-right-radius: 2% 3%;
  border-bottom-left-radius: 5% 3%;
  border-bottom-right-radius: 5% 3%;
  box-sizing: border-box;
  padding: 3%;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
}
.mac_screen:before {
  content: "";
  position: absolute;
  right: 0.3%;
  top: 0.5%;
  width: 36.5%;
  height: 35%;
  border: 1px solid #666;
  border-bottom: none;
  border-left: none;
  border-top-right-radius: 4.5% 7%;
  display: none;
}
.mac_screen:after {
  content: "";
  position: absolute;
  right: -25%;
  top: -25%;
  width: 40%;
  height: 150%;
  background: rgba(200, 200, 200, 0.2);
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(200, 200, 200, 0) 40%);
  transform: rotate(-30deg);
  display: none;
}

.mac_camera {
  background: #333;
  border-radius: 50%;
  width: 1%;
  height: 1.5%;
  position: absolute;
  left: 49.5%;
  top: 2%;
}
.mac_camera:after {
  content: "";
  position: absolute;
  left: 35%;
  top: 40%;
  width: 30%;
  height: 30%;
  border-radius: 50%;
  background: #777;
}

.mac_bottom {
  background: #e9e9e9;
  background: linear-gradient(#ccc 50%, #444);
  width: 100%;
  height: 5%;
  position: relative;
  top: -1.7%;
  border-bottom-left-radius: 6% 50%;
  border-bottom-right-radius: 6% 50%;
}
.mac_bottom:after {
  content: "";
  position: absolute;
  right: 1%;
  bottom: 11%;
  width: 98%;
  height: 0%;
  box-shadow: 1px 0px 8px 1px #333;
}

.mac_bottom_top_half {
  width: 100%;
  height: 50%;
  background: #e9e9e9;
  background: linear-gradient(90deg, #aaa, #f3f3f3 0.5%, #aaa 2.5%, #f3f3f3 5.5%, #f3f3f3 94.5%, #aaa 97.5%, #f3f3f3 99.5%, #aaa);
}

.mac_thumb_space {
  background: #bbb;
  background: radial-gradient(90% 150% at 50% 1%, #eee 49%, #888);
  margin: auto;
  width: 15%;
  height: 60%;
  border-bottom-left-radius: 8% 100%;
  border-bottom-right-radius: 8% 100%;
}

.mac_screen_content {
  background: #333;
  width: 100%;
  height: 100%;
  text-align: center;
}
.mac_screen_content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/*mobile screen*/
.phone-wrapper {
  max-width: 290px;
  margin: 0 auto;
  display: table;
}
@media (min-width: 768px) {
  .phone-wrapper {
    display: none;
  }
}
.phone-wrapper .phone {
  height: 600px;
  position: relative;
  border: 8px solid #000;
  border-radius: 4.5vh;
  overflow: hidden;
}
.phone-wrapper .phone img {
  height: 100%;
  display: block;
  border-radius: 2.5vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.phone-wrapper .phone .notch-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
}
.phone-wrapper .phone .notch-container .notch {
  width: 20vh;
  height: 4.25vh;
  margin: 0 auto;
  background-color: #111;
  border-bottom-left-radius: 2.334vh;
  border-bottom-right-radius: 2.334vh;
}

.navbar-expand-sm .container {
  flex-wrap: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .navbar-expand-sm .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sidenav {
  height: 100%;
  width: 335px;
  text-align: center;
  position: fixed;
  z-index: 1032;
  top: 0;
  left: -335px;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 30px 20px;
  background: url(../images/mobile_menu_bg.jpg) center center no-repeat;
  background-size: cover;
}
@media (max-width: 375px) {
  .sidenav {
    left: -315px;
    width: 315px;
  }
}
.sidenav.show-nav {
  left: 0;
}
.sidenav .sidebarNav {
  padding: 100px 0;
}
.sidenav .sidebarNav ul li a {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #000;
  display: inline-block;
  margin-bottom: 28px;
}
.sidenav .sidebarNav .button {
  margin-top: 5px;
}
.sidenav .logo-wrapper {
  text-align: center;
}
.sidenav .logo-wrapper .logo {
  height: 27px;
  width: 240px;
}
.nav-overlay {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: background-color 0.15s ease-out, visibility 0.15s ease-out;
  visibility: hidden;
}
.nav-overlay.active {
  background-color: rgba(48, 48, 48, 0.21);
  visibility: visible;
  z-index: 5;
}

/*home features blocks*/
.services {
  padding: 300px 0 80px;
}
@media (min-width: 768px) {
  .services {
    padding: 80px 0 80px;
  }
}
@media (min-width: 1200px) {
  .services {
    padding: 110px 0;
  }
}
@media (min-width: 1401px) {
  .services {
    padding: 110px 0;
  }
}
@media (min-width: 1601px) {
  .services {
    padding: 110px 0;
  }
}
.services-inner {
  max-width: 1420px;
}
.services-inner .common-heading {
  max-width: 532px;
  margin-bottom: 110px;
}
@media (max-width: 767px) {
  .services-inner .common-heading {
    margin-bottom: 38px;
  }
}

.services-group {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 110px;
}
.services-group:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .services-group {
    flex-direction: column;
    margin-bottom: 55px;
  }
}
.services-group-left {
  width: 55%;
  padding-right: 145px;
}
@media (max-width: 1199px) {
  .services-group-left {
    padding-right: 95px;
  }
}
@media (max-width: 767px) {
  .services-group-left {
    width: 100%;
    padding-right: 0;
    order: 2;
    -webkit-order: 2;
  }
}
.services-group-left-inner {
  max-width: 532px;
}
@media (max-width: 767px) {
  .services-group-left-inner {
    max-width: 100%;
  }
}
.services-group-left-inner p {
  max-width: 496px;
}
.services-group-left-inner span {
  color: #9863CF;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .services-group-left-inner span {
    font-size: 18px;
    line-height: 22px;
  }
}
.services-group-left-inner .common-heading {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .services-group-left-inner .common-heading {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}
.services-group-right {
  width: 45%;
}
.services-group-right .img-wrapper {
  width: 100%;
  height: 388px;
}
.services-group-right .img-wrapper img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
@media (max-width: 767px) {
  .services-group-right .img-wrapper {
    margin-bottom: 32px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .services-group-right {
    width: 100%;
  }
}
.services-group:nth-child(odd) .services-group-left {
  order: 2;
  -webkit-order: 2;
  padding-left: 145px;
  padding-right: 0;
}
@media (max-width: 1199px) {
  .services-group:nth-child(odd) .services-group-left {
    padding-left: 95px;
  }
}
@media (max-width: 767px) {
  .services-group:nth-child(odd) .services-group-left {
    padding-left: 0;
  }
}

/*get-started*/
.get-started {
  padding: 150px 0;
  background: url(../images/home_bottom_bg.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
}
@media (max-width: 767px) {
  .get-started {
    padding: 190px 0;
  }
}
.get-started-inner {
  position: relative;
  z-index: 2;
  max-width: 725px;
}
.get-started-inner .button {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .get-started-inner .button {
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .get-started-inner p {
    font-size: 16px;
    line-height: 25px;
  }
}

/*features*/
.feature-box-wrapper {
  margin-bottom: 0;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .feature-box-wrapper {
    margin-bottom: -110px;
    padding-bottom: 0;
  }
}

@media (min-width: 768px) {
  .feature-box-slider {
    /*@include flexbox;
    flex-wrap: wrap;
    justify-content: space-between;*/
  }
}
.feature-box-slider .slick-track {
  display: flex !important;
}
.feature-box-slider .slick-track .slick-slide {
  height: inherit !important;
}
.feature-box-slider .slick-dots {
  bottom: -45px;
  /*@include flexbox;
     align-items: baseline;
     justify-content: center;*/
}
.feature-box-slider .slick-dots li button:before {
  font-size: 19px;
  line-height: 21px;
  color: #fff;
  opacity: 1;
}
.feature-box-slider .slick-dots li.slick-active {
  width: 28px;
  height: 28px;
}
.feature-box-slider .slick-dots li.slick-active button {
  width: 28px;
  height: 28px;
}
.feature-box-slider .slick-dots li.slick-active button:before {
  font-size: 28px;
  line-height: 30px;
  width: 28px;
  height: 28px;
  color: #9863CF;
  opacity: 1;
}

.feature-box {
  padding: 34px 52px;
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0px 3px 25px #922C8814;
  margin: 0 10px;
}
@media (min-width: 768px) {
  .feature-box {
    /*max-width: 49%;*/
    /*margin-bottom: 15px;*/
    margin: 0 7px;
  }
}
@media (min-width: 1200px) {
  .feature-box {
    /*max-width: 24%;*/
  }
}
.feature-box-icon {
  width: 70px;
  height: 70px;
  /*@include borderradius(50%);
  background: $yellow-accent-color;*/
  margin-bottom: 18px;
}
.feature-box-icon .material-icons {
  font-size: 43px;
  color: #fff;
}
.feature-box h6 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
}
.feature-box p a {
  color: #9863CF;
}
.feature-box p a:hover {
  color: #7740b0;
}

.features {
  padding: 88px 0;
  background: #FCFCFC;
}
@media (min-width: 768px) {
  .features {
    padding: 210px 0 110px;
  }
}
.features-info {
  max-width: 718px;
  padding: 0 25px;
}
@media (min-width: 768px) {
  .features-info {
    padding: 0;
  }
}
.features-info h2 span {
  color: #9863CF;
  text-transform: uppercase;
}
.features-info .button {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .features-info .button {
    margin-top: 50px;
  }
}

/*template slider*/
.feature-template {
  padding-top: 95px;
}
@media (min-width: 768px) {
  .feature-template {
    padding-top: 110px;
  }
}
.feature-template-slider-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  margin: 0 17px;
}
.feature-template-slider-wrapper .tmp-name {
  color: #303030;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  position: relative;
}
@media (min-width: 768px) {
  .feature-template-slider-wrapper .tmp-name {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 28px;
  }
}
.feature-template-slider-wrapper .tmp-name:before {
  position: absolute;
  content: "";
  bottom: -1px;
  width: 100%;
  height: 1px;
  background: #303030;
}
.feature-template-slider-wrapper .tmp-image {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  height: 230px;
}
@media (min-width: 540px) {
  .feature-template-slider-wrapper .tmp-image {
    height: 400px;
  }
}
@media (min-width: 768px) {
  .feature-template-slider-wrapper .tmp-image {
    height: 420px;
  }
}
@media (min-width: 1200px) {
  .feature-template-slider-wrapper .tmp-image {
    height: 678px;
  }
}
.feature-template-slider-wrapper .tmp-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
.feature-template .slider-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
  padding-right: 30px;
}
@media (min-width: 768px) {
  .feature-template .slider-nav {
    padding-right: 8%;
  }
}
.feature-template .slider-nav .material-icons {
  font-size: 35px;
  font-weight: 300;
}
.feature-template .slider-nav .slide-count {
  margin-right: 18px;
  position: relative;
  top: -3px;
  font-size: 21px;
  color: #303030;
}
.feature-template .slider-nav .slick-arrow {
  cursor: pointer;
  display: inline-block;
}
.feature-template .slider-nav .slick-arrow.prev .material-icons {
  color: #909090;
}

/*help*/
.help-wrapper {
  padding: 84px 0;
  background: #303030;
}
@media (min-width: 1401px) {
  .help-wrapper {
    padding: 150px 0;
  }
}
.help-wrapper-inner {
  padding: 0;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 768px) {
  .help-wrapper-inner {
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0 20px;
  }
}
@media (min-width: 1401px) {
  .help-wrapper-inner {
    padding: 0 70px;
  }
}
.help-wrapper-inner-left {
  width: 100%;
  text-align: center;
  padding: 0 50px;
}
@media (min-width: 768px) {
  .help-wrapper-inner-left {
    width: 50%;
    text-align: left;
    padding: 0;
    padding-right: 50px;
  }
}
.help-wrapper-inner-left-content {
  max-width: 100%;
  padding-top: 0;
}
@media (min-width: 768px) {
  .help-wrapper-inner-left-content {
    max-width: 670px;
  }
}
@media (min-width: 1401px) {
  .help-wrapper-inner-left-content {
    padding-top: 42px;
  }
}
.help-wrapper-inner-left-content .button {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .help-wrapper-inner-left-content p {
    font-size: 16px;
    line-height: 25px;
  }
}
.help-wrapper-inner-right {
  width: 100%;
  padding-top: 60px;
}
@media (min-width: 768px) {
  .help-wrapper-inner-right {
    width: 50%;
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .help-wrapper .container {
    padding: 0;
  }
}

.help-accordion {
  max-width: 100%;
  width: 100%;
  float: right;
}
@media (min-width: 768px) {
  .help-accordion {
    max-width: 662px;
  }
}
.help-accordion .card {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  border: none;
  background: transparent;
}
.help-accordion .card .card-header {
  border: 1px solid #707070;
  border-left: none;
  border-right: none;
  border-bottom: none;
  background: transparent;
  padding: 0;
}
.help-accordion .card .card-header .card-title > button {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
  padding: 30px 25px;
  width: 100%;
  text-align: left;
  white-space: inherit;
}
@media (min-width: 768px) {
  .help-accordion .card .card-header .card-title > button {
    font-size: 22px;
    line-height: 27px;
    padding: 38px 0;
  }
}
.help-accordion .card .card-header .card-title > button:before {
  float: right !important;
  font-family: "Material Icons";
  content: "remove";
  -webkit-font-feature-settings: "liga";
  margin-left: 30px;
  font-weight: 300;
  font-size: 35px;
}
.help-accordion .card .card-header .card-title > button.collapsed:before {
  float: right !important;
  font-family: "Material Icons";
  content: "add";
  -webkit-font-feature-settings: "liga";
  margin-left: 30px;
}
.help-accordion .card .card-header .card-title > button:hover {
  text-decoration: none;
}
.help-accordion .card .card-header .card-title > button:active {
  text-decoration: none;
}
.help-accordion .card .card-header .card-title > button:focus {
  text-decoration: none;
}
.help-accordion .card .card-body {
  background: transparent;
  padding: 0 75px 25px 25px;
}
@media (min-width: 768px) {
  .help-accordion .card .card-body {
    padding: 0 45px 15px 0;
  }
}
.help-accordion .card .card-body p {
  font-size: 16px;
  line-height: 25px;
  color: #fff;
}
@media (max-width: 767px) {
  .help-accordion .card .card-body p {
    font-size: 14px;
  }
}
.help-accordion .card:last-child {
  border-bottom: 1px solid #707070;
}

/*customer service*/
.customer-service {
  background: #FCFCFC;
  min-height: 274px;
  padding: 65px 0;
}
@media (min-width: 768px) {
  .customer-service {
    padding: 98px 0;
  }
}
.customer-service-inner {
  max-width: 1300px;
  position: relative;
  margin-top: 0;
  padding: 0 15px;
}
@media (min-width: 1200px) {
  .customer-service-inner {
    margin-top: -310px;
    /*padding: 0;*/
  }
}
.customer-service-inner h2 {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .customer-service-inner h2 {
    margin-bottom: 46px;
  }
}
.customer-service-inner .feature-box-wrap {
  /*@include flexbox;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;*/
  display: grid;
  grid-gap: 19px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
@media (min-width: 1200px) {
  .customer-service-inner .feature-box-wrap {
    /*flex-wrap: nowrap;
    justify-content: space-between;*/
    grid-gap: 46px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}
.customer-service-inner .feature-box-wrap .feature-box {
  /*max-width: 393px;*/
  /*margin: 0 23px;*/
  margin: 0;
  padding: 56px 48px;
  text-align: left;
}
@media (min-width: 768px) {
  .customer-service-inner .feature-box-wrap .feature-box {
    /*margin: 10px;*/
  }
}
@media (min-width: 1200px) {
  .customer-service-inner .feature-box-wrap .feature-box {
    /*margin: 0;
    max-width: 31.7%;*/
  }
}
.customer-service-inner .feature-box-wrap .feature-box h6 {
  margin-bottom: 24px;
}

/*login*/
.login-wrapper-outer {
  padding: 70px 0 100px 0;
}
@media (min-width: 992px) {
  .login-wrapper-outer {
    padding: 162px 0;
  }
}
.login-wrapper-outer .login-wrapper {
  max-width: 945px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 15px;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 992px) {
  .login-wrapper-outer .login-wrapper {
    justify-content: space-between;
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .login-wrapper-outer .login-wrapper {
    padding: 0;
  }
}
.login-wrapper-outer .login-wrapper-left {
  width: 100%;
  padding-right: 0;
  padding-top: 70px;
  text-align: center;
}
@media (min-width: 992px) {
  .login-wrapper-outer .login-wrapper-left {
    padding-right: 45px;
    padding-top: 100px;
    width: 55%;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .login-wrapper-outer .login-wrapper-left {
    padding-right: 75px;
  }
}
.login-wrapper-outer .login-wrapper-left h1 {
  margin-bottom: 15px;
}
.login-wrapper-outer .login-wrapper-right {
  width: 100%;
  border-left: none;
}
@media (min-width: 992px) {
  .login-wrapper-outer .login-wrapper-right {
    width: 45%;
    border-left: 1px solid #F0F0F0;
  }
}
.login-wrapper-outer .login-wrapper-right .form-wrapper {
  width: 100%;
  max-width: 367px;
  margin: 0 auto;
  padding: 30px 0;
}
@media (min-width: 992px) {
  .login-wrapper-outer .login-wrapper-right .form-wrapper {
    margin-left: auto;
    margin-right: 0;
    padding: 50px 0;
  }
}
.login-wrapper-outer .login-wrapper-right .form-wrapper input {
  border: 1px solid #F0F0F0;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper input.error {
  border: 1px solid #db0303;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .login-btn {
  margin-bottom: 42px;
  padding: 21px 16px;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper label {
  display: block;
}
@media (min-width: 992px) {
  .login-wrapper-outer .login-wrapper-right .form-wrapper label {
    display: block;
  }
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .option-tab {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .checker-wrapper .checker {
  font-size: 18px;
  color: #909090;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .checker-wrapper .checker a {
  text-decoration: underline;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .checker-wrapper .checker a:hover {
  color: #9863CF;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .cat-selector {
  margin-bottom: 20px;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .cat-selector p {
  font-size: 16px;
  line-height: 25px;
  color: #9863CF;
  margin-bottom: 14px;
}
.login-wrapper-outer .login-wrapper-right .form-wrapper .form-group .input-group-append .input-group-text {
  background-color: #9863CF;
  color: #fff;
  border: 1px solid #9863CF;
}

/*.round {
	position: relative;
	label {
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 50%;
		cursor: pointer;
		height: 28px;
		left: 0;
		position: absolute;
		top: 0;
		width: 28px;
		&:after {
			border: 2px solid #fff;
			border-top: none;
			border-right: none;
			content: "";
			height: 6px;
			left: 7px;
			opacity: 0;
			position: absolute;
			top: 8px;
			transform: rotate(-45deg);
			width: 12px;
		}
	}
	input[type="checkbox"] {
		visibility: hidden;
		&:checked {
			+ {
				label {
					background-color: #66bb6a;
					border-color: #66bb6a;
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}*/
/*.checker-group {
	display: block;
	margin-bottom: 15px;
	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
		&:checked {
			+ {
				label {
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 5px;
						left: 9px;
						width: 7px;
						height: 13px;
						border: solid $primary-color;
						border-width: 0 2px 2px 0;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	label {
		position: relative;
		cursor: pointer;
		color: $bodytext-color;
		font-size: 18px;
		line-height: 1;
		padding-left: 0;
		@include flexbox;
		align-items: center;
		&:before {
			content: '';
			-webkit-appearance: none;
			background-color: transparent;
			border: 2px solid $primary-color;
			@include borderradius(50%);
			padding: 10px;
			display: inline-block;
			position: relative;
			vertical-align: middle;
			cursor: pointer;
			margin-right: 15px;
			width: 25px;
			height: 25px;
			flex-shrink: 0;
		}
		a {
			text-decoration: underline;
			margin-left: 5px;
			&:hover {
				color: $primary-hover-color;
				text-decoration: none;
			}
		}
	}
}*/
.checker {
  display: block;
  position: relative;
  padding-left: 43px !important;
  font-weight: 400;
  cursor: pointer;
  font-size: 16px;
  color: #909090;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checker input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checker input:checked ~ .checkmark {
  background-color: transparent;
  border: 2px solid #9863CF;
}
.checker input:checked ~ .checkmark:after {
  display: block;
}
.checker .checkmark:after {
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #9863CF;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #F0F0F0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/*terms*/
.terms-wrapper {
  padding: 180px 0 105px 0;
}
.terms-wrapper-inner {
  max-width: 1014px;
  margin: 0 auto;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .terms-wrapper-inner {
    padding: 0 15px;
  }
}
.terms-wrapper-inner .page-heading {
  text-align: center;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .terms-wrapper-inner .page-heading {
    text-align: left;
    padding-bottom: 25px;
  }
}

.static-base h1, .static-base h2, .static-base h3, .static-base h4, .static-base h5, .static-base h6 {
  color: #000;
  margin-bottom: 20px;
  line-height: 30px;
}
.static-base h1 {
  color: #000;
  font-size: 60px;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .static-base h1 {
    font-size: 60px;
  }
}
@media (min-width: 768px) {
  .static-base h1 {
    font-size: 28px;
  }
}
.static-base h2 {
  font-size: 26px;
}
@media (min-width: 768px) {
  .static-base h2 {
    font-size: 24px;
  }
}
.static-base h3 {
  font-size: 24px;
}
@media (min-width: 768px) {
  .static-base h3 {
    font-size: 22px;
  }
}
.static-base h4 {
  font-size: 22px;
}
@media (min-width: 768px) {
  .static-base h4 {
    font-size: 20px;
  }
}
.static-base h5 {
  font-size: 20px;
}
@media (min-width: 768px) {
  .static-base h5 {
    font-size: 18px;
  }
}
.static-base h6 {
  font-size: 18px;
}
@media (min-width: 768px) {
  .static-base h6 {
    font-size: 16px;
  }
}
.static-base p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 25px;
}
@media (min-width: 768px) {
  .static-base p {
    font-size: 18px;
  }
}
.static-base p a {
  text-decoration: underline;
  color: #000;
}
.static-base p a:hover {
  color: #000;
}
.static-base ul {
  margin-bottom: 35px;
  /*list-style: none;*/
  padding: 0;
}
.static-base ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  line-height: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.static-base ul li:before {
  position: absolute;
  content: "•";
  left: 0;
  top: 1px;
  font-size: 25px;
  color: #000;
  border-radius: 50%;
}
.static-base ul li ul li:before {
  content: "•";
}
.static-base ul li a {
  font-size: 14px;
  text-decoration: underline !important;
}
.static-base small {
  margin-bottom: 15px;
  display: block;
}
.static-base ol {
  counter-reset: numbered;
}
.static-base ol li {
  position: relative;
  margin-bottom: 15px;
  line-height: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  display: table;
}
.static-base ol li:before {
  content: counters(numbered, ".") ". ";
  counter-increment: numbered;
  font-size: 14px;
  color: #909090;
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .static-base ol li:before {
    font-size: 18px;
  }
}
.static-base ol li p {
  display: inline-block;
}
.static-base ol li p a {
  text-decoration: underline !important;
}
.static-base ol li p span {
  color: #ff0000;
}
.static-base ol li ol {
  padding-left: 0;
  margin-bottom: 0;
}
.static-base ol li ul {
  list-style-type: circle;
  counter-reset: none;
}
.static-base ol li ul li {
  list-style-type: circle;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 25px;
}
.static-base ol li ul li:before {
  counter: none !important;
  counter-increment: none;
  content: "●" !important;
}
.static-base ol li ul li p {
  margin-bottom: 0;
}

.error-base .xl-heading {
  font-size: 100px;
  font-weight: 700;
  display: block;
  color: #9863CF;
  line-height: 80%;
}
@media (min-width: 767px) {
  .error-base .xl-heading {
    line-height: 80%;
    font-size: 130px;
  }
}
@media (min-width: 1200px) {
  .error-base .xl-heading {
    line-height: 80%;
    font-size: 200px;
  }
}

/*new modify starts*/
.inner-wrapper .postlogin-content-wrapper {
  padding: 105px 0 118px;
}
.inner-wrapper .postlogin-sidebar {
  display: none;
}
.inner-wrapper .postlogin-content {
  margin-left: 0;
}
.inner-wrapper-postlogin .postlogin-content-wrapper {
  padding: 105px 0 118px;
}
@media (min-width: 992px) {
  .inner-wrapper-postlogin .postlogin-sidebar {
    display: block;
  }
}
@media (min-width: 992px) {
  .inner-wrapper-postlogin .postlogin-content {
    margin-left: 300px;
  }
}

.content-outer-wrap {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #F0F0F0;
  padding: 25px;
  background: #fff;
  position: relative;
}
@media (min-width: 992px) {
  .content-outer-wrap {
    padding: 30px;
  }
}
.content-outer-wrap .domain-content {
  width: 100%;
}
@media (min-width: 1400px) {
  .content-outer-wrap .domain-content {
    max-width: 65%;
  }
}
.content-outer-wrap .domain-logo {
  max-width: 32%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
@media (max-width: 1400px) {
  .content-outer-wrap .domain-logo {
    display: none;
  }
}
.content-outer-wrap .content-info-header .mob-domain-logo {
  position: relative;
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0 40px 0;
}
@media (min-width: 1400px) {
  .content-outer-wrap .content-info-header .mob-domain-logo {
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 30px;
    top: 50%;
    max-width: 32%;
    padding-left: 60px;
  }
}
.content-outer-wrap .content-info-header .mob-domain-logo svg {
  width: 82px;
  height: 82px;
}
@media (min-width: 1400px) {
  .content-outer-wrap .content-info-header .mob-domain-logo svg {
    width: 154px;
    height: 154px;
  }
}

.input-flex-outer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}
@media (min-width: 992px) {
  .input-flex-outer {
    flex-direction: row;
  }
}
.input-flex-outer .input-with-btn {
  width: 100%;
}
@media (max-width: 991px) {
  .input-flex-outer .input-with-btn .input-wrap:last-child {
    margin-right: 0;
  }
}
.input-flex-outer .input-with-btn .input-wrap-small {
  max-width: 100px;
  width: 100%;
}
.input-flex-outer .split-btns {
  margin-top: 16px;
  justify-content: center;
}
@media (min-width: 992px) {
  .input-flex-outer .split-btns {
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .input-flex-outer .split-btns a {
    min-width: 170px;
  }
}
.input-flex-outer .split-btns a:not(:first-of-type) {
  margin-left: 10px;
}

@media (max-width: 991px) {
  .split-btns-wrapper {
    width: 100%;
  }
}

.def-text {
  font-weight: 400;
  font-size: 16px;
  color: #909090;
}

.body-wrapper {
  padding: 105px 0 118px;
}

.inner-wrapper .postlogin-content-wrapper {
  padding: 105px 0 118px;
}
.inner-wrapper .postlogin-sidebar {
  display: none;
}
.inner-wrapper .postlogin-content {
  margin-left: 0;
}
.inner-wrapper-postlogin .postlogin-content-wrapper {
  padding: 105px 0 118px;
}
.inner-wrapper-postlogin .postlogin-content-wrapper--premium {
  background: #fff;
}
@media (max-width: 767px) {
  .inner-wrapper-postlogin .postlogin-content-wrapper--premium .page-top-info {
    padding-bottom: 20px;
    background: #fff;
    box-shadow: 0px 25px 35px 0px rgba(77, 77, 77, 0.08);
  }
}
@media (min-width: 992px) {
  .inner-wrapper-postlogin .postlogin-sidebar {
    /*display: block;*/
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-x: hidden;
  }
}
.inner-wrapper-postlogin .postlogin-sidebar .multilingual-group {
  margin-bottom: 40px;
  /*overflow-x: hidden;*/
}
.inner-wrapper-postlogin .postlogin-sidebar .multilingual-group .btn-group .dropdown-toggle {
  padding-left: 0;
  padding-top: 0;
}
.inner-wrapper-postlogin .postlogin-sidebar .multilingual-group .btn-group .dropdown-toggle span {
  font-size: 14px;
  color: #909090;
  font-weight: 400;
  margin-right: 10px;
}
.inner-wrapper-postlogin .postlogin-sidebar .multilingual-group .btn-group .dropdown-toggle:after {
  display: none;
}
@media (min-width: 992px) {
  .inner-wrapper-postlogin .postlogin-content {
    margin-left: 300px;
  }
}

.postlogin-content {
  margin-left: 0;
  min-height: 600px;
  box-shadow: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  background: #FCFCFC;
}
@media (min-width: 992px) {
  .postlogin-content {
    margin-left: 300px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
    -moz-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
    -ms-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
    -o-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
    box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  }
}
.postlogin-content-inner {
  padding: 0;
  /* &.two-half-block {
  	padding: 11px;
  	flex-direction: column;
  	align-items: stretch;
  	@include media(">tablet") {
  		padding: 22px;
  		flex-direction: row;
  	}
  } */
}
@media (min-width: 992px) {
  .postlogin-content-inner {
    padding: 50px;
  }
}
.postlogin-content .two-half-block {
  padding: 11px;
  flex-direction: column;
  align-items: stretch;
}
@media (min-width: 768px) {
  .postlogin-content .two-half-block {
    padding: 22px;
    flex-direction: row;
  }
}
.postlogin-content--mod .page-top-info {
  /*display: none;*/
}
@media (min-width: 992px) {
  .postlogin-content--mod .page-top-info {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
.postlogin-content--payment {
  background: #fff;
}
@media (max-width: 767px) {
  .postlogin-content--payment .page-header h6 {
    font-size: 16px;
    line-height: 20px;
  }
}

/*user menu sidebar*/
/*.user-menu-sidebar {
	position: fixed;
	background: $white;
	top: 0;
	max-height: 100%;
	height: 100%;
	z-index: 2;
	padding-top: 84px;
	left: 0;
	width: 100%;
	right: 0;
}*/
.user-menu {
  height: 100%;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}
.user-menu-top-details {
  border-bottom: 1px solid #F0F0F0;
  padding: 25px 35px;
}
.user-menu-top-details h6 {
  color: #303030;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}
.user-menu-top-details span {
  color: #909090;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.user-menu-top-nav {
  padding: 25px 35px;
}
.user-menu-top-nav p {
  color: #909090;
  font-size: 16px;
  margin-bottom: 0;
}
.user-menu-top-nav p span {
  color: #303030;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}
.user-menu-top-nav .button--sm {
  padding: 10px 16px;
}
.user-menu-top-nav .user-menu-links {
  margin-top: 25px;
}
.user-menu-top-nav .user-menu-links a {
  display: table;
  color: #909090;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 18px;
}
.user-menu-top-nav .multilingual .multilingual-group .btn-group .dropdown-toggle {
  padding-left: 0;
}
.user-menu-top-nav .multilingual .multilingual-group .btn-group .dropdown-menu {
  position: fixed !important;
  width: 100%;
  overflow-y: auto;
  min-width: 100%;
  bottom: 0;
  left: 0 !important;
  padding: 25px;
  right: 0 !important;
  transform: none !important;
  padding-top: 85px;
  -webkit-transition: transform 0.8s ease;
  -moz-transition: transform 0.8s ease;
  -ms-transition: transform 0.8s ease;
  -o-transition: transform 0.8s ease;
  transition: transform 0.8s ease;
}
.user-menu-top-nav .multilingual .multilingual-group .btn-group .dropdown-menu li {
  display: inline-block;
}
.user-menu-bottom {
  border-top: 1px solid #F0F0F0;
  padding: 25px 35px;
}
.user-menu-bottom a {
  display: table;
  color: #909090;
  font-size: 14px;
  font-weight: 400;
}
.user-menu.sidebar-active {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-animation: fade-in 1.5s ease-out;
  -moz-animation: fade-in 1.5s ease-out;
  background: #fff;
}

.animated-in {
  -webkit-animation: fade-in 1.5s ease-in;
  -moz-animation: fade-in 1.5s ease-in;
}

.animated-out {
  -webkit-animation: fade-in 1.5s ease-out;
  -moz-animation: fade-in 1.5s ease-out;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*post login contents*/
/*.cont-info-block {
	display: none;
	&.show {
		display: block;
	}
}*/
.page-top-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  /*&.show-top-info {
  	left: 0;
  }*/
}
@media (min-width: 992px) {
  .page-top-info {
    margin-bottom: 55px;
  }
}

.page-btns a, .page-btns button {
  margin-left: 14px;
}

.page-header {
  max-width: 605px;
}
.page-header p {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0;
}
.page-header h6 {
  margin-bottom: 22px;
  font-size: 20px;
  line-height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.page-header h6 a {
  margin-right: 10px;
  color: #303030;
}
@media (min-width: 992px) {
  .page-header h6 a {
    display: none;
  }
}
.page-header h6 a i {
  font-size: 30px;
}
.page-header--spacing-bottom {
  margin-bottom: 24px;
}
.page-header--widthincrease {
  max-width: 812px;
}
.page-header--message-place h6 {
  margin-bottom: 15px;
}
.page-header--message-place .input-checker-wrapper {
  margin-top: 20px;
}
.page-header-premium {
  background: url(../images/premium_banner.jpg) center center no-repeat;
  padding: 48px 55px;
  background-size: cover;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width: 767px) {
  .page-header-premium {
    padding: 36px 34px;
  }
}
.page-header-premium h6 {
  margin-bottom: 5px;
}
.page-header-premium .button {
  margin-top: 18px;
  min-width: 150px;
}
.page-header-premium .price {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #9863CF;
  margin-bottom: 18px;
  line-height: 1;
}
@media (max-width: 767px) {
  .page-header-premium .price {
    line-height: 50px;
    font-size: 40px;
  }
}
.page-header-premium .price p {
  padding: 0 0 2px 6px;
  font-weight: 400;
  display: inline-block;
}
@media (max-width: 767px) {
  .page-header-premium .price p {
    padding: 0 0 6px 6px;
  }
}
.page-header-premium-info {
  max-width: 50%;
}
@media (max-width: 767px) {
  .page-header-premium-info {
    max-width: 100%;
  }
}
.page-header-premium .upgrade-logo {
  max-width: 50%;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .page-header-premium .upgrade-logo {
    position: absolute;
    right: 34px;
    top: 34px;
  }
}
.page-header-premium .upgrade-logo svg {
  width: 154px;
  height: 154px;
}
@media (max-width: 767px) {
  .page-header-premium .upgrade-logo svg {
    width: 82px;
    height: 82px;
  }
}

.info-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #303030;
}
.info-title span {
  color: #9863CF;
  font-size: 20px;
  line-height: 22px;
}

.global-p {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #909090;
}

.premium-feature {
  margin-top: 40px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
@media (max-width: 1400px) {
  .premium-feature {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
@media (max-width: 991px) {
  .premium-feature {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
@media (max-width: 767px) {
  .premium-feature {
    grid-gap: 14px;
  }
}
.premium-feature--box {
  border: 1px solid #F0F0F0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  padding: 35px 30px;
}
@media (max-width: 767px) {
  .premium-feature--box {
    background: #fff;
  }
}
.premium-feature--box svg {
  width: 64px;
  height: 64px;
  margin-bottom: 18px;
}
.premium-feature--box .premium-title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 10px;
}
.premium-feature--box p {
  margin-bottom: 0;
}

.page-btns {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 25px;
  justify-content: center;
  z-index: 1;
  background: #FCFCFC;
  filter: drop-shadow(0px -3px 6px rgba(0, 0, 0, 0.05));
}
@media (min-width: 992px) {
  .page-btns {
    width: auto;
    position: relative;
    filter: none;
    background: #fff;
    padding: 0;
  }
}
.page-btns a:first-of-type {
  margin-left: 0;
}

.content-sidebar {
  padding-top: 0;
  width: 100%;
  float: none;
  left: auto;
  right: auto;
  display: block;
}
@media (min-width: 1200px) {
  .content-sidebar {
    float: left;
    padding-top: 18px;
    width: 195px;
  }
}
.content-sidebar h6 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 22px;
}
.content-sidebar-menu {
  padding: 0 0 15px 0;
  height: 100%;
  overflow-y: auto;
}
.content-sidebar-menu ul li {
  margin: 15px 0;
  display: inline-block;
  /*&.active {
  	a {
  		background: $primary-color;
  		color: $white;
  	}
  }*/
}
@media (min-width: 1200px) {
  .content-sidebar-menu ul li {
    display: block;
  }
}
.content-sidebar-menu ul li a {
  display: inline-block;
  width: auto;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.35px;
  padding: 8px 20px;
  color: #909090;
  font-size: 14px;
  line-height: 18px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  -ms-border-radius: 21px;
  -o-border-radius: 21px;
  border-radius: 21px;
}
@media (min-width: 1200px) {
  .content-sidebar-menu ul li a {
    width: 100%;
  }
}
.content-sidebar-menu ul li a.active {
  background: #9863CF;
  color: #fff;
}
.content-sidebar--box {
  background: #fff;
  border: 1px solid #F0F0F0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 20px;
  /*display: none;*/
}
@media (min-width: 992px) {
  .content-sidebar--box {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    border: none;
    background: transparent;
    margin-bottom: 0;
    /*display: block;*/
  }
}
@media (max-width: 991px) {
  .content-sidebar--box .content-sidebar-menu {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .content-sidebar--box .content-sidebar-menu ul li {
    display: block;
  }
}
@media (max-width: 991px) {
  .content-sidebar--box .content-sidebar-menu ul li a {
    display: block;
  }
}
.content-sidebar .nav {
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .content-sidebar .nav {
    margin-bottom: 0;
  }
}
.content-sidebar .nav-link {
  display: inline-block;
  width: auto;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.35px;
  padding: 8px 20px;
  color: #909090;
  font-size: 14px;
  line-height: 18px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  -ms-border-radius: 21px;
  -o-border-radius: 21px;
  border-radius: 21px;
  margin: 7px 0;
}
.content-sidebar .nav-link.active {
  background: #9863CF;
  color: #fff;
}
.content-sidebar .nav-link.active.has-error {
  border: 1px solid red;
  background: transparent;
}
.content-sidebar .nav-link.has-error {
  color: red;
}

.content-info {
  margin-left: 0;
  background: #FCFCFC;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: none;
  border-bottom: 1px solid #F0F0F0;
  padding: 0 0 18px 0;
}
@media (min-width: 992px) {
  .content-info {
    border: 1px solid #F0F0F0;
    padding: 40px 35px;
    background: #fff;
  }
}
@media (min-width: 1200px) {
  .content-info {
    margin-left: 240px;
  }
}
.content-info .common-info-wrapper {
  max-width: 906px;
}
.content-info .common-info-wrapper--medium {
  max-width: 800px;
}
.content-info .common-info-wrapper--widerspace {
  max-width: 1065px;
}
.content-info--fullwidth {
  border-bottom: none;
}
@media (min-width: 1200px) {
  .content-info--fullwidth {
    margin-left: 0;
    border: none;
    padding: 0;
  }
}

.input-checker-wrapper {
  margin-top: 40px;
}
.input-checker-wrapper .checker-group label {
  font-size: 16px;
}
@media (min-width: 1200px) {
  .input-checker-wrapper--increasespace {
    max-width: 704px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1401px) {
  .input-checker-wrapper--increasespace {
    max-width: 100%;
  }
}

.content-info-top {
  padding-bottom: 13px;
}
@media (min-width: 1200px) {
  .content-info-top {
    padding-bottom: 52px;
  }
}
.content-info-top--nomarginbottom {
  padding-bottom: 0;
}
.content-info-top .customer-service {
  padding: 0;
  background: transparent;
  min-height: auto;
  margin-top: 80px;
}
@media (max-width: 767px) {
  .content-info-top .customer-service {
    margin-top: 44px;
  }
}
.content-info-top .customer-service-inner {
  margin-top: 0;
  padding: 0;
}
.content-info-top .customer-service-inner h2 {
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  line-height: 22px;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .content-info-top .customer-service-inner h2 {
    margin-bottom: 65px;
  }
}
@media (max-width: 767px) {
  .content-info-top .form-btn-grp .button {
    margin-top: 14px;
  }
}

.content-info-header {
  padding-bottom: 11px;
}
.content-info-header h6 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.content-info-header h6 a {
  margin-right: 10px;
  color: #303030;
}
@media (min-width: 992px) {
  .content-info-header h6 a {
    display: none;
  }
}
.content-info-header h6 a i {
  font-size: 30px;
}
.content-info-header p {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 15px;
}

.content-form .form-group {
  margin-bottom: 24px;
}
.content-form .form-group label {
  width: 100%;
  text-align: left;
  color: #303030;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 14px;
}
.content-form .form-group .help-text {
  display: block;
  color: #909090;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
.content-form .form-group input {
  padding: 18px 22px;
  font-size: 16px;
  line-height: 1;
}
.content-form .form-group input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #909090;
}
.content-form .form-group input::-moz-placeholder {
  /* Firefox 19+ */
  color: #909090;
}
.content-form .form-group input:-ms-input-placeholder {
  /* IE 10+ */
  color: #909090;
}
.content-form .form-group input:-moz-placeholder {
  /* Firefox 18- */
  color: #909090;
}
.content-form .form-group input:disabled {
  cursor: not-allowed;
  pointer-events: all;
}
.content-form .form-group textarea {
  height: 200px;
  padding: 28px 28px 28px 32px;
  resize: none;
}
@media (min-width: 768px) {
  .content-form .form-group textarea {
    height: 300px;
  }
}
.content-form .form-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #909090;
}
.content-form .form-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #909090;
}
.content-form .form-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #909090;
}
.content-form .form-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #909090;
}
.content-form .form-group input, .content-form .form-group textarea {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #F0F0F0;
  color: #909090;
}
.content-form .form-group input:focus, .content-form .form-group textarea:focus {
  outline: none;
  box-shadow: none;
}
.content-form .form-group .select2-container {
  width: 100% !important;
}
.content-form .form-group .select2-container .select2-selection--single {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #F0F0F0;
  width: 100%;
  clear: both;
  display: table;
  height: 58px;
}
.content-form .form-group .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 18px 22px;
  font-size: 16px;
  color: #909090;
  line-height: normal;
}
.content-form .form-group .select2-container .select2-selection--single .select2-selection__arrow {
  right: 20px;
  top: 10px;
  height: 100%;
}
.content-form .form-group .select2-container .select2-selection--single .select2-selection__arrow b {
  display: none;
}
.content-form .form-group .select2-container .select2-selection--single .select2-selection__arrow:after {
  content: "keyboard_arrow_down";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  font-size: 27px;
  color: #000;
}
.content-form .form-group .select2-container .select2-selection--multiple {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #F0F0F0;
  width: 100%;
  clear: both;
  display: table;
  height: 58px;
  padding: 0;
  /*@include flexbox;
  align-items: center;*/
  padding: 8px 22px;
  /*.select2-selection__arrow {
  	right: 20px;
  	top: 10px;
  	height: 100%;
  	b {
  		display: none;
  	}
  	&:after {
  		content: "keyboard_arrow_down";
    			font-family: 'Material Icons';
  		-webkit-font-feature-settings: 'liga';
  		font-size: $root-font-size + 9;
  		color: $black;

  	}
  }*/
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-selection__rendered {
  /*padding: 18px 22px;*/
  font-size: 16px;
  color: #909090;
  line-height: normal;
  /*clear: both;
  display: inline-block;*/
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  margin: 4px !important;
  background: #a973e1;
  color: #fff;
  padding: 4px 4px 4px 20px;
  border: none;
  /*@include flexbox();
  align-items: center;
  justify-content: center;
  height: 100%;*/
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  color: #fff;
  background: #9863CF;
  border-right: 1px solid #9863CF;
  padding: 4px;
  /*@include flexbox();
  align-items: center;
  justify-content: center;*/
  height: 100%;
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove span {
  height: 22px;
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__display {
  height: 21px;
  display: inline-block;
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-search--inline {
  /*line-height: normal;*/
  /*height: 30px;*/
}
.content-form .form-group .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
  margin: 0;
  /*height: 24px;
  			vertical-align: top;
  			padding-top: 4px;*/
  line-height: 40px;
  height: 40px;
  vertical-align: middle;
  padding-top: 0;
}
.content-form .btn-wrap {
  margin-top: 3px;
}
.content-form .text-btn-wrap {
  margin-top: 52px;
}
.content-form .text-btn-wrap span {
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  line-height: 22px;
  margin-bottom: 22px;
}
.content-form .text-btn-wrap .text-wrap {
  margin-bottom: 22px;
}
.content-form .text-btn-wrap .text-wrap span {
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  line-height: 22px;
}
.content-form .text-btn-wrap .text-wrap p {
  font-size: 14px;
  font-weight: 400;
  color: #909090;
  line-height: 25px;
  margin-top: 10px;
}
.content-form--image .image-dropdown-group .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 13px 22px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

/*country block*/
.general-settings .text-btn-wrap {
  margin-top: 8px;
}

.select-tag-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.select-tag {
  border: 1px solid #9863CF;
  min-width: 125px;
  border-radius: 35px;
  padding: 7px 18px 7px 20px;
  margin: 0 15px 15px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: relative;
}
.select-tag span {
  font-size: 14px;
  font-weight: 700;
  color: #9863CF;
  letter-spacing: 0.35px;
  line-height: 1;
}
.select-tag a {
  /*position: absolute;
  right: 15px;*/
  line-height: 1;
  margin-left: 10px;
}
.select-tag a i {
  font-size: 20px;
}
.select-tag a:hover {
  color: #7e4ab4;
}

.checker-outer {
  margin-top: 18px;
}
.checker-outer .form-group {
  margin-bottom: 34px;
}

.checker-group {
  display: inline-block;
  margin-right: 50px;
}
.checker-group:last-child {
  margin-right: 0;
}
.checker-group .checker {
  font-size: 14px !important;
  display: inline-block;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #909090 !important;
}
.checker-group .checker span {
  line-height: normal;
}
.checker-group .checkmark {
  border: 2px solid #909090;
}

.o-notification {
  background-color: #9863CF;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
}
.o-notification--inner {
  color: #fff;
  line-height: 1;
}
.o-notification--inner .notificationImage {
  margin-right: 10px;
}
.o-notification--inner .notificationContent span {
  font-size: 15px;
  line-height: 18px;
}
.o-notification .notificationClose {
  position: absolute;
  background: #9863CF;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  padding: 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
}
.o-notification .notificationClose svg {
  fill: #fff;
}

/*popper dashboard click*/
.popper {
  background-color: #fff;
  box-shadow: 0px 0px 6px #9863CF26;
  letter-spacing: 0.3px;
  color: #909090;
  font-size: 12px;
  line-height: 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  padding: 14px 22px;
  position: absolute;
  bottom: 30px;
  left: -55px;
}

/*tooltip*/
.tooltip-inner {
  background-color: #fff;
  opacity: 1;
  box-shadow: 0px 0px 6px #9863CF26;
  letter-spacing: 0.3px;
  color: #909090;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 22px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  /*border-top-color: #f00 !important;*/
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #fff;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #f00 !important;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #f00 !important;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #f00 !important;
}

/*dropdown language*/
.lang-overlay.active {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1032;
  cursor: pointer;
}

.drop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}
.drop-wrap .dropdown-menu {
  position: relative;
  background: #fff;
  left: 0;
  right: 0;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 300px;
  max-width: 400px;
  margin: 30px auto;
  z-index: 2;
  width: 100%;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.drop-wrap-sidebar {
  z-index: 1400;
}
.drop-wrap-sidebar .drop-wrap-menu li a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  clear: both;
  overflow: hidden;
  width: auto;
  height: auto;
  margin: 12px 8px !important;
}
.drop-wrap-sidebar .drop-wrap-menu li a span {
  padding-right: 12px;
  display: inline-block;
}
.drop-wrap-sidebar .drop-wrap-menu li a img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
}

.extraservice-place {
  margin-top: 8px;
}
.extraservice-place > label {
  font-size: 14px;
  line-height: 17px;
  color: #303030;
  font-weight: 700;
  margin-bottom: 27px;
}

.inline-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 639px) {
  .inline-list {
    flex-direction: column;
    align-items: flex-start;
  }
}
.inline-list li {
  margin-right: 25px;
  margin-bottom: 16px;
}
.inline-list li:last-child {
  margin-right: 0;
}
.inline-list li .checker-modify {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;
}
.inline-list li .checker-modify a {
  width: 22px;
  height: 22px;
  background: #9863CF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.inline-list li .checker-modify a:hover {
  color: #9863CF;
}
.inline-list li .checker-modify a i {
  font-size: 14px;
  color: #fff;
}
.inline-list li:hover .checker {
  color: #303030;
}
.inline-list li:hover .checker.checked {
  color: #909090;
}
.inline-list-modifier li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.list-group .inline-list-modifier li {
  width: 100%;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 16px;
}
.list-group .inline-list-modifier li .checker {
  font-weight: 400;
  font-size: 16px;
  color: #909090;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 0;
}
.list-group .inline-list-modifier li .checker-modify a {
  line-height: 1;
  margin-right: 5px;
}
.list-group .inline-list-modifier li:hover .checker {
  color: #303030;
}
.list-group .inline-list-modifier li:hover .checker.checked {
  color: #909090;
}

@media (max-width: 639px) {
  .mobile-width {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width: 639px) {
  .mobile-width--space {
    margin-left: 0px;
  }
}

.divider {
  border-top: 1px solid #F0F0F0;
  margin-bottom: 26px;
}

.switch-group {
  min-width: 190px;
}

@media (max-width: 767px) {
  .mobile-popup {
    position: fixed;
    background: #fff;
    padding: 20px 30px;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .mobile-popup {
    position: static;
  }
}
.mobile-popup .add-link-wrapper {
  position: relative;
}

.image-upload-place h5 {
  text-align: left;
  color: #303030;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 11px;
}

.upload-file-holder {
  position: relative;
  min-height: 245px;
  border: 2px dashed #9863CF !important;
  margin-top: 25px;
  margin-bottom: 19px;
  padding: 60px 20px !important;
}
.upload-file-holder .upload-file-middle {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 !important;
}
.upload-file-holder .upload-file-middle i {
  font-size: 40px;
  color: rgba(152, 99, 207, 0.43);
  margin-bottom: 8px;
}
.upload-file-holder .upload-file-middle svg {
  width: 40px;
  height: 40px;
  fill: rgba(152, 99, 207, 0.43);
  margin-bottom: 8px;
}
.upload-file-holder .upload-file-middle span {
  line-height: 1;
  margin-bottom: 17px;
  color: #909090;
  font-size: 16px;
}
.upload-file-holder--heightincrease {
  min-height: 308px;
}

.upload-file-holder .dz-preview.dz-image-preview .dz-details .dz-filename {
  display: none;
}

.upload-file-holder .dz-preview .dz-remove {
  position: absolute;
  font-size: 0px !important;
  line-height: 1;
  right: -5px;
  top: -5px;
  width: 25px;
  height: 25px;
  z-index: 20;
  background: #9863CF;
  color: transparent;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  display: flex !important;
  display: -webkit-flex !important;
  align-items: center;
  justify-content: center;
}

.upload-file-holder .dz-preview .dz-remove:hover {
  color: transparent;
}

.upload-file-holder .dz-preview .dz-remove:after {
  content: "clear";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  font-size: 15px;
  color: #fff;
}

.bottom-buttons-place {
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 767px) {
  .bottom-buttons-place {
    position: static;
    width: 100%;
    text-align: center;
  }
}
.bottom-buttons-place button {
  margin-left: 14px;
}

.empty-post {
  font-size: 16px;
}

.tour-place {
  margin-bottom: 56px;
}

.responsive-table {
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .mobile-hidden {
    display: none !important;
  }
}

/*toaster modify*/
.toast-bottom-right {
  z-index: 2 !important;
}
.toast-bottom-right .toast {
  border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  box-shadow: 0px 3px 25px #0000001A !important;
  color: inherit !important;
  background: #fff !important;
  opacity: 1 !important;
  overflow: inherit !important;
  padding: 10px 15px 10px 80px !important;
  min-width: 380px !important;
  width: auto !important;
  min-height: 64px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.toast-bottom-right .toast:before {
  font-size: 22px;
  color: #fff;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  z-index: 1;
}
.toast-bottom-right .toast:after {
  width: 58px;
  height: 58px;
  content: "";
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  left: 4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.toast-bottom-right .toast .toast-close-button {
  font-size: 25px !important;
  position: absolute !important;
  right: 0 !important;
  top: -30px !important;
  color: #000 !important;
  font-weight: 400;
}
.toast-bottom-right .toast .toast-title {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  color: #303030 !important;
}
.toast-bottom-right .toast .toast-message {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #909090 !important;
}
.toast-bottom-right .toast-error:before {
  content: "clear";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  background: #FD6B68;
}
.toast-bottom-right .toast-error:after {
  background: #FD6B68;
}
.toast-bottom-right .toast-success:before {
  content: "done";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  background: #5DD584;
}
.toast-bottom-right .toast-success:after {
  background: #5DD584;
}
.toast-bottom-right .toast-warning:before {
  content: "warning";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  background: #f6bf00;
  font-size: 18px;
}
.toast-bottom-right .toast-warning:after {
  background: #f6bf00;
}
.toast-bottom-right .toast-info:before {
  content: "priority_high";
  font-family: "Material Icons";
  -webkit-font-feature-settings: "liga";
  background: #9863CF;
}
.toast-bottom-right .toast-info:after {
  background: #9863CF;
}

@media (max-width: 767px) {
  .contact-info-grid {
    margin-top: 44px;
  }
}

.medium-heading {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 38px;
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
}
.contact-list li {
  width: 393px;
  margin-right: 37px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .contact-list li {
    margin-right: 15px;
    width: 353px;
  }
}
@media (max-width: 639px) {
  .contact-list li {
    width: 100%;
    margin-right: 0;
  }
}
.contact-list li:last-child {
  margin-right: 0;
}
.contact-list li .box {
  background: #fff;
  -webkit-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -moz-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -ms-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  -o-box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  box-shadow: 0px 3px 25px rgba(77, 77, 77, 0.08);
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 246px;
}
.contact-list li .box .inner-box {
  max-width: 282px;
  width: 100%;
  margin: 0 auto;
}
.contact-list li .box .inner-box h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}
.contact-list li .box .inner-box p {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0;
}

.escort-place {
  display: flex;
  align-items: center;
}

.escort-image {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 19px;
  display: inline-block;
}
.escort-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.remove-tooltip:after, .remove-tooltip:before {
  display: none;
}

.group-section {
  margin-bottom: 50px;
  margin-top: 50px;
}
.group-section:first-child {
  margin-top: 0px;
}
.group-section:last-child {
  margin-bottom: 0px;
}
.group-section .medium-heading {
  margin-bottom: 34px;
}

/*select 2 variations*/
.select2-container--open .select2-dropdown--below {
  margin-top: 6px;
  border: 1px solid #f4f4f4 !important;
}
.select2-container--open .select2-dropdown--below .select2-search--dropdown .select2-search__field {
  border: 1px solid #f4f4f4;
}
.select2-container--open .select2-dropdown--below .select2-results .select2-results__options .select2-results__option {
  font-size: 14px;
  padding: 9px 16px;
  font-weight: 400;
  color: #131313;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.select2-container--open .select2-dropdown--below .select2-results .select2-results__options .select2-results__option--highlighted {
  font-weight: 700;
  background: #F8F8F8;
}
.select2-container--open .select2-dropdown--above {
  margin-top: 6px;
  border: 1px solid #f4f4f4 !important;
}
.select2-container--open .select2-dropdown--above .select2-search--dropdown .select2-search__field {
  border: 1px solid #f4f4f4;
}
.select2-container--open .select2-dropdown--above .select2-results .select2-results__options .select2-results__option {
  font-size: 14px;
  padding: 9px 16px;
  font-weight: 400;
  color: #131313;
}
.select2-container--open .select2-dropdown--above .select2-results .select2-results__options .select2-results__option--highlighted {
  font-weight: 700;
  background: #F8F8F8;
}

/*payment*/
.card-set {
  margin-top: 20px;
}
.card-set img {
  margin-right: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  width: 65px;
  height: 38px;
  object-fit: cover;
  object-position: center;
}

.order-summary {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 100%;
}
.order-summary h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 18px;
  text-align: left;
}
@media (min-width: 992px) {
  .order-summary h5 {
    margin-bottom: 45px;
    text-align: center;
  }
}
.order-summary-list {
  width: 100%;
  padding: 35px 0;
}
@media (min-width: 992px) {
  .order-summary-list {
    padding: 0;
  }
}
.order-summary-list-option {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-summary-list-option p, .order-summary-list-option span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #909090;
  margin-bottom: 0;
}
.order-summary-list .button {
  margin-top: 55px;
}
.order-summary-list .info-txt {
  margin-top: 32px;
  font-size: 12px;
  color: #909090;
  font-weight: 400;
}
.order-summary-list hr {
  margin-top: 24px;
  margin-bottom: 32px;
  border-top-color: #F0F0F0;
}
.order-summary-list-top {
  padding: 0 20px;
}
@media (min-width: 992px) {
  .order-summary-list-top {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) {
  .order-summary-list-top {
    padding: 0 40px;
  }
}
@media (min-width: 1400px) {
  .order-summary-list-top {
    padding: 0 72px;
  }
}
.order-summary-list-bot {
  padding: 0 22px;
}
@media (min-width: 992px) {
  .order-summary-list-bot {
    padding: 0 20px;
  }
}
@media (min-width: 1200px) {
  .order-summary-list-bot {
    padding: 0 40px;
  }
}
@media (min-width: 1400px) {
  .order-summary-list-bot {
    padding: 0 72px;
  }
}
.order-summary-list-bot .order-summary-list-option p, .order-summary-list-bot .order-summary-list-option span {
  color: #303030;
  font-weight: 700;
}

@media (max-width: 767px) {
  .container-premium {
    padding: 0;
  }
}
.container-premium .postlogin-content-inner {
  padding: 32px;
}
@media (max-width: 767px) {
  .container-premium .postlogin-content-inner {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .container-premium .postlogin-content .content-wrapper {
    padding: 0 34px;
  }
}

.cont-info-block {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 100px 25px 25px;
  position: fixed;
  top: 0;
  left: 0;
  background: #FCFCFC;
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  .cont-info-block {
    padding: 0;
    top: auto;
    overflow-y: auto;
    background: #fff;
    position: relative;
    z-index: 1;
  }
}

.rules-list li:last-child {
  margin-right: 25px;
}

@media (max-width: 639px) {
  .rates-content-sidebar {
    padding: 15px;
  }
}

@media (max-width: 639px) {
  .rates-content-info {
    padding: 15px;
  }
}

@media (max-width: 639px) {
  .rates-input-checker {
    padding: 15px;
    margin-top: 25px;
  }
}

.postlogin-sidebar {
  float: none;
  position: fixed;
  background: #FCFCFC;
  top: 0;
  max-height: 100%;
  height: 100%;
  z-index: 2;
  padding-top: 84px;
  left: 0;
  width: 100%;
  right: 0;
  display: none;
}
@media (min-width: 992px) {
  .postlogin-sidebar {
    padding-top: 120px;
    width: 280px;
    float: left;
    left: auto;
    right: auto;
    display: block;
  }
}
.postlogin-sidebar .sidebar-menu {
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
}
@media (min-width: 992px) {
  .postlogin-sidebar .sidebar-menu {
    padding: 0 0 15px 0;
    display: block;
  }
}
.postlogin-sidebar .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 12px 30px 12px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  -o-border-radius: 27px;
  border-radius: 27px;
}
@media (min-width: 992px) {
  .postlogin-sidebar .sidebar-menu ul li a {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    width: auto;
  }
}
.postlogin-sidebar .sidebar-menu ul li a span {
  font-size: 20px;
  color: #303030;
  font-weight: 700;
  line-height: 24px;
  /*@include media(">ltablet") {
  	color: $primary-color;
  }*/
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown {
  /*a {
  	&:after {
      	font-family: 'Material Icons';
  		content: "keyboard_arrow_down";
  		-webkit-font-feature-settings: 'liga';
  	    font-style: normal;
  	    display: inline-block;
  	    font-style: normal;
  	    font-variant: normal;
  	    text-rendering: auto;
  	    -webkit-font-smoothing: antialiased;
  	    -moz-osx-font-smoothing: grayscale;
  	    text-align: center;
  	    background: 0 0;
  	    position: absolute;
  	    right: 15px;
  	    top: 50%;
  	    @include transform(translateY(-50%));
  	    color: $black;
  	    font-size: 22px;
  	    @include media("<=ltablet") {
  	    	content: "settings";
  	    	color: $faded-white;
  	    }
      }
  }*/
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .main-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .main-menu .toggler {
  cursor: pointer;
  font-size: 25px;
  margin-right: 14px;
}
@media (max-width: 991px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .main-menu .toggler-desktop {
    display: none;
  }
}
@media (min-width: 991px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .main-menu .toggler-mobile {
    display: none;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu {
  display: none;
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li {
  padding-left: 15px;
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li a {
  color: #909090;
  font-size: 14px;
  /*line-height: 1;*/
  letter-spacing: 0.35px;
  padding: 8px 20px;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  -o-border-radius: 27px;
  border-radius: 27px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li a:after {
    content: "settings";
    color: #FCFCFC;
    font-family: "Material Icons";
    -webkit-font-feature-settings: "liga";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 22px;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li:hover a {
  color: #909090;
}
@media (min-width: 992px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li:hover a {
    color: #9863CF;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li.active a {
  color: #9863CF;
  /*&:after {
  	@include media(">ltablet") {
  					color: $white;
  				}
  }*/
}
@media (min-width: 992px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown .sidebar-submenu ul li.active a {
    background: #9863CF;
    color: #fff;
  }
}
@media (max-width: 991px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active a {
    background: #9863CF;
    width: 100%;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active a span {
  color: #9863CF;
}
@media (max-width: 991px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active a span {
    color: #fff;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active a:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active .main-menu .toggler {
  position: absolute;
  /*right: 15px;*/
  right: 0;
}
@media (max-width: 991px) {
  .postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active .main-menu .toggler {
    right: 15px;
  }
}
.postlogin-sidebar .sidebar-menu ul li.sidebar-dropdown.active .main-menu .toggler-mobile {
  color: #fff;
}
.postlogin-sidebar .sidebar-menu ul li.message-count-place {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.postlogin-sidebar .sidebar-menu ul li.message-count-place .message-count {
  background: #9863CF;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  min-width: 19px;
  min-height: 19px;
  padding: 0px 3px;
  text-align: center;
  margin-right: 16px;
}
.postlogin-sidebar .sidebar-menu.sidebar-active {
  display: block;
  -webkit-animation: fade-in 1.5s ease-out;
  -moz-animation: fade-in 1.5s ease-out;
}
.postlogin-sidebar.sidebar-show {
  display: block;
  /*display: flex;
  justify-content: space-between;
  flex-direction: column;*/
}
.postlogin-sidebar.sidebar-show-user {
  display: block;
}

.content-info-commonplace {
  display: block;
  margin-bottom: 37px;
  position: relative;
}

.small-font-heading {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  font-weight: 700;
}

.upload-phpto-place {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .upload-phpto-place {
    flex-direction: column-reverse;
  }
}
.upload-phpto-place .show-photo {
  max-width: 735px;
  height: 331px;
  overflow: hidden;
  position: relative;
  margin-top: 22px;
  border-radius: 5px;
  width: 100%;
}
.upload-phpto-place .show-photo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 991px) {
  .upload-phpto-place .show-photo {
    height: 260px;
    margin-top: 0;
    margin-bottom: 16px;
  }
}
.upload-phpto-place .show-photo .remove {
  position: absolute;
  top: 12px;
  right: 14px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #fff;
  z-index: 2;
}
.upload-phpto-place .show-photo--medium {
  max-width: 100%;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}
@media (min-width: 992px) {
  .upload-phpto-place .show-photo--medium {
    max-width: 100%;
    height: 164px;
  }
}
.upload-phpto-place--gallery {
  /*flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;*/
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
@media (max-width: 767px) {
  .upload-phpto-place--gallery {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}
.upload-phpto-place--gallery .show-photo {
  width: 100%;
  height: 250px;
  /*@include media(">ltablet") {
      max-width: calc(100% / 3 - 5px);
      height: 180px;
  }
  @include media(">mdesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 160px;
  }
  @include media(">largerdesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 200px;
  }
  @include media(">ldesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 250px;
  }*/
}
@media (max-width: 991px) {
  .upload-phpto-place--gallery .show-photo {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .upload-phpto-place--gallery .show-photo {
    height: 180px;
  }
}
.upload-phpto-place--gallery .show-photo video, .upload-phpto-place--gallery .show-photo iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.upload-phpto-place--gallery .show-photo .vid-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-phpto-place--gallery .show-photo .vid-overlay svg {
  width: 38px;
  height: 38px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-phpto-place--gallery {
  /*flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;*/
  display: grid;
  grid-gap: 10px;
  /*grid-template-columns: 270px 270px 270px 270px 270px;*/
  /*grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));*/
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /*grid-auto-rows: minmax(26vh, 250px);*/
}
@media (max-width: 767px) {
  .upload-phpto-place--gallery {
    /*grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));*/
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-auto-rows: minmax(26vh, 180px);
  }
}
.upload-phpto-place--gallery .show-photo {
  width: 100%;
  height: 250px;
  /*@include media(">ltablet") {
      max-width: calc(100% / 3 - 5px);
      height: 180px;
  }
  @include media(">mdesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 160px;
  }
  @include media(">largerdesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 200px;
  }
  @include media(">ldesktop") {
     max-width: calc(100% / 5 - 5px);
     height: 250px;
  }*/
}
@media (max-width: 991px) {
  .upload-phpto-place--gallery .show-photo {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .upload-phpto-place--gallery .show-photo {
    height: 180px;
  }
}
.upload-phpto-place--gallery .show-photo video, .upload-phpto-place--gallery .show-photo iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.upload-phpto-place--gallery .show-photo .vid-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-phpto-place--gallery .show-photo .vid-overlay svg {
  width: 38px;
  height: 38px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-photo-place .show-photo {
  height: auto;
  max-width: 331px;
}
.logo-photo-place .show-photo img {
  object-fit: contain;
  background-color: #9863CF;
}

.show-photo-wrapper {
  margin-left: 6px;
  margin-right: 6px;
}

@media (max-width: 991px) {
  .custom-modal-dialog {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .custom-modal-dialog {
    height: auto;
    overflow-y: auto;
  }
}

.modal-wrapper {
  padding: 34px 47px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}
@media (max-width: 991px) {
  .modal-wrapper {
    padding: 23px 30px;
    height: 100vh;
  }
}
@media (max-width: 991px) {
  .modal-wrapper--height {
    height: auto;
  }
}
@media (max-width: 767px) {
  .modal-wrapper--height {
    height: 100vh !important;
    overflow-y: auto;
  }
}

.modal-close {
  position: absolute;
  top: 35px;
  right: 37px;
  color: #000;
  opacity: 1;
}
@media (max-width: 991px) {
  .modal-close {
    top: 23px;
  }
}

.modal-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.modal-content-place {
  display: block;
  margin: 36px 0 0;
}
@media (max-width: 991px) {
  .modal-content-place {
    display: flex;
    min-height: 600px;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    margin-left: -30px;
    margin-right: -30px;
  }
}
.modal-content-place .image-crop-holder {
  max-width: 775px;
  height: 614px;
  position: relative;
}
@media (max-width: 991px) {
  .modal-content-place .image-crop-holder {
    height: 365px;
  }
}
.modal-content-place .image-crop-holder img {
  object-fit: cover;
  max-width: inherit;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .modal-content-place--form-place {
    margin-left: auto;
    margin-right: auto;
    min-height: auto;
    margin-top: 36px;
  }
}

.button-group-place {
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
  flex-wrap: wrap;
}
.button-group-place .button {
  margin-right: 24px;
}
.button-group-place .button:last-child {
  margin-right: 0px;
}
@media (max-width: 991px) {
  .button-group-place {
    justify-content: center;
  }
}
.button-group-place--reducespace {
  padding: 0 27px;
}
@media (max-width: 991px) {
  .button-group-place--reducespace {
    padding: 0 0;
  }
}
@media (max-width: 991px) {
  .button-group-place--button-break {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .button-group-place--button-break .button {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.close-popup {
  display: none;
}
@media (max-width: 767px) {
  .close-popup {
    display: block;
    top: 0;
    right: 0;
  }
}

.form-heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-bottom: 22px;
}
.form-heading--violetcolor {
  color: #9863CF;
}

.language-selector-menu {
  margin: 45px 15px 35px 0;
  padding-left: 20px;
}
.language-selector-menu .lang-toggle span {
  color: #000 !important;
}

.message-title {
  margin-bottom: 28px;
}
.message-title .nav-link {
  font-size: 14px;
  line-height: 17px;
  color: #909090;
  padding: 8px 27px;
  border-radius: 21px;
}
.message-title .nav-link.active {
  background-color: #9863CF;
  color: #fff;
}

.accordian-style .card {
  margin-bottom: 15px;
  border-radius: 2px;
  border: 1px solid #F0F0F0;
}
.accordian-style .card-header {
  padding: 28px 28px 28px 71px;
  background-color: #fff;
  position: relative;
  border-bottom: 0;
}
@media (max-width: 991px) {
  .accordian-style .card-header {
    padding: 25px;
  }
}
.accordian-style .card-header .pointer {
  cursor: pointer;
}
.accordian-style .card-header .email-place h5 {
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  margin-bottom: 5px;
  display: inline-block;
  font-weight: 600;
  position: relative;
}
@media (max-width: 991px) {
  .accordian-style .card-header .email-place h5 {
    font-size: 14px;
  }
}
.accordian-style .card-header .email-place .time {
  display: block;
  font-size: 14px;
  color: #909090;
}
@media (max-width: 991px) {
  .accordian-style .card-header .email-place .time {
    font-size: 12px;
  }
}
.accordian-style .card-header .email-place.memberon .activemember {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #9863CF;
  display: block;
  position: absolute;
  top: 62%;
  left: -40px;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .accordian-style .card-header .email-place.memberon .activemember {
    width: 7px;
    height: 7px;
    left: auto;
    right: -19px;
  }
}
@media (max-width: 991px) {
  .accordian-style .card-header .email-place {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
.accordian-style .card-header .message-content {
  position: relative;
}
.accordian-style .card-header .message-content h3 {
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  margin-bottom: 5px;
  font-weight: 600;
}
@media (max-width: 991px) {
  .accordian-style .card-header .message-content h3 {
    font-size: 14px;
  }
}
.accordian-style .card-header .message-content .message-text {
  display: block;
  font-size: 14px;
  color: #909090;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .accordian-style .card-header .message-content .message-text {
    display: none;
  }
}
.accordian-style .card-header .action-buttons {
  position: absolute;
  top: 50%;
  right: 28px;
  display: flex;
  align-items: center;
  max-width: 46px;
  width: 100%;
  justify-content: space-between;
  z-index: 666;
  z-index: 666;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .accordian-style .card-header .action-buttons {
    top: 67%;
    right: 25px;
  }
}
.accordian-style .card-header .action-buttons .delete {
  width: 21px;
}
.accordian-style .card-header .heading-color .message-content h3 {
  color: #909090;
}
.accordian-style .card-header .heading-color.collapsed .message-content h3 {
  color: #303030;
}
.accordian-style .message-body {
  padding: 0px 28px 28px 71px;
}
@media (max-width: 991px) {
  .accordian-style .message-body {
    padding: 25px;
    padding-top: 0;
  }
}
.accordian-style .message-body .details-tags {
  margin-bottom: 25px;
}
.accordian-style .message-body .details-tags:last-child {
  margin-bottom: 3;
}
.accordian-style .message-body .details-tags h4 {
  margin-bottom: 2px;
}
.accordian-style .message-body .details-tags p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 17px;
}
.accordian-style .message-body .description-place p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px;
}