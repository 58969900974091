.button{
    padding: 16px;
    /*min-width: 250px;*/
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    @include borderradius(60px);
    letter-spacing: 0.4px;
    color: $white;
    transition: all .5s ease-in-out;
    line-height: 1;
    display: inline-block;
    cursor: pointer;
    /*@include media("<=largerdesktop") {
        height: 45px;
    }
    @include media("<=ltablet") {
        height: 58px;
    }*/
    &--violet {
        background: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
            &:not([disabled]) {
                background: $violet-hover-color;
                border: 1px solid $violet-hover-color;
            }
        }
        &:disabled {
            opacity: .6;
            cursor: not-allowed;
        }
    }
    &--gray {
        background: $bodytext-color;
        border: 1px solid $bodytext-color;
        &:hover {
            background: $headline-color;
            border: 1px solid $headline-color;
        }
    }
    &--transparent {
        background: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
        &:hover {
            background: $primary-color;
            border: 1px solid $primary-color;
            color: $white;
        }
    }
    &--yellow {
        background: $yellow-accent-color;
        &:hover {
            background: $yellow-hover-color;
        }
    }
    &--text {
        border:none;
        background:transparent;
        color:$bodytext-color;
        font-weight:$regular;
    }
    &--large {
        min-width: 188px;
    }
    &--big {
        min-width: 235px;
    }
    &--md {
        min-width: 150px;
    }
    &--sm {
        min-width: 115px;
        padding: 12px 16px;
    }
    &--form {
        padding: 11px 25px;
    }
}

