.free-template-btn{
    margin-top:18px;
}
.minicolors-theme-bootstrap .minicolors-swatch{
    top:5px !important;
    left: 5px !important;
}
.theme-color-choose-info{
    margin-bottom: 28px !important;
}
.btn-color-update-wrap{
    margin-top: 48px;
}
.btnn-default-theme-color{
    margin-top: 15px;
    @include media (">lphone"){
        margin-left: 27px;
    }
}
.card{
    border:1px solid $lightgray-border;
}
.card-bodywrap{
    padding:21px 27px 13px;

}
.minicolors {
    .form-control{
        border:1px solid $lightgray-border; 
        &:focus{
            outline: none;
            box-shadow: none;
        }
    }
}
.btnnnactive{
    margin-right: 15px;
    margin-bottom: 15px;
}
.btncolorchange{
    margin-bottom: 15px;
    @include media ("<=desktop"){
        padding-left: 18px;
        padding-right: 18px;
    }
}
.card-bodywrap{
    label{
        color: $bodytext-color;
        margin-bottom: 12px;
        font-size: $root-font-size - 4;
    }
}
.info-tag {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 4px;
    &:hover{
        cursor: pointer;
    }
}

//Tooltip
.tooltip1 {
    position: relative;
    display: inline-block;
    &:hover{
        .tooltiptext {
            visibility: visible;
        }
    }
    .tooltiptext{
        visibility: hidden;
        width: 120px;
        background-color: $primary-color;
        color: $white;
        font-family: $lato;
        font-weight: $regular;
        @include font-size ($link-font-sizes);
        text-align: center;
        border-radius: 60px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 124%;
        left: 50%;
        margin-left: -60px;
        &::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $primary-color transparent transparent transparent;
        }
    }
  }
.color-theme-modal{
    .modal-header{
        border-bottom: 0px;
        padding: 17px 16px 0px;
        .close{
            color: $headline-color;
            opacity: 1;
        }
    }
    .modal-footer{
        padding: 0px 16px 17px;
        border-top: 0px;
        justify-content: flex-start;
        .modal-title{
            font-family: $lato;
            font-weight: $semi-bold;
            @include font-size($bttn-font-sizes);
            color: $headline-color;
        }
    }
}
.content-info{
    .tab-min-content{
        @include media(">mdesktop"){
            min-height: 20vh;
        }
    }
}

.faq-page-wrap{
    .page-heading{
        font-family: $lato;
        @include font-size($h1-font-sizes);
        color: $white;
        font-weight: $bold;
        padding-bottom: 30px !important;
        @include media(">ltablet"){
            padding-bottom: 30px !important;
        }
    }
    .faqPanel {
        .nav-tabs {
            @include media(">tablet"){
                margin-bottom: 0px;
            }
            margin-bottom: 0px;
            margin-top: 0px;
            li {
                a{
                    font-family: $lato;
                    font-weight: $bold;
                    @include font-size($bttn-font-sizes);
                    letter-spacing: 0.4px;
                    padding: 9px 40px;
                    color: $white;
                    border-radius: 0px;
                    // border-bottom: 1px solid #707070;
                    border-bottom: none;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding-left: 0px;
                    padding-right: 0px;
                    margin-left: 20px;
                    margin-right: 20px;
                    &:hover{
                        color: $white;
                    }
                    &.active{
                        background-color: transparent;
                        color: #fff;
                        border-bottom: 2px solid #fff;
                        &:hover{
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    .panel-body{
        h5{
            font-family: $lato;
            @include font-size($h5-font-sizes);
            color: $white;
            font-weight: $semi-bold;
            margin-left: 17px;
            margin-bottom: 15px;
        }
        button{
            border-top: 1px solid #707070;
            border-bottom: none;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            background-color: $headline-color;
            color: $white;
            padding: 31px 30px 0px;
            &.btn-headerlink-first{
                border-top: none;
            }
        }
        .btn-header-link{
            font-family: $lato;
            font-weight: $semi-bold;
            color: $white;
            @include font-size($bttn-font-sizes);
        }
    }
    .faq-panel{
        .card-body{
            padding-top:15px;
        }
    }
}

.terms-main{
    background-color: $headline-color;
    margin-top: 80px;
    padding: 70px 0px !important;
    @include media(">ltablet"){
        margin-top: 120px;
        padding: 84px 0px !important;
    }
    .faq-card{
        .card-body{
            padding-left: 45px;
            p{
                font-family: $lato;
                font-weight: $regular;
                color: $white;
                @include font-size($p-font-sizes);
            }
        }
    }
}
.dropzone {
    .dz-preview{
        z-index: 0;
    }
}
.faq-scroller{
    .mCSB_draggerContainer{
        // @include media(">ltablet"){
        //     display: none;
        // }
        display: none;
    }
    .mCSB_inside>.mCSB_container{
        margin-bottom: 0px;
    }
}

body.modal-open{
    position: fixed;
    margin: 50px auto;
}

.social-app-input-checker-wrapper{
    margin-top: 20px !important;
}

.mob-upgrade-box{
    @include media("<tablet"){
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
    .upgrade-btn{
        @include media("<tablet"){
            margin-top: 15px;
        }
    }
}
.user-menu-top-nav {
    .mob-upgrade-box{
        p{
            @include media("<mphone"){
                font-size: 12px;
            }
            @include media("<mxphone"){
                font-size: 14px;
            }
        }
    }
}