.site-footer {
	padding: 110px 0;
	background: $faded-white;
	@include media("<=tablet") {
		padding: 60px 0;
	}
	&-outer {
		@include flexbox;
		flex-wrap: nowrap;
		flex-direction: row;
		@include media("<=tablet") {
			flex-wrap: wrap;
			flex-direction: column;
		}
		&-info {
			max-width: 540px;
			width: 100%;
			@include media("<=mdesktop") {
				max-width: 400px;
			}
			@include media("<=ltablet") {
				max-width: 300px;
			}
			@include media("<=tablet") {
				max-width: 100%;
				text-align: center;
				order: 2;
				-webkit-order: 2;
				margin-top: 60px;
			}
			&-inner {
				max-width: 325px;
				@include media("<=tablet") {
					max-width: 100%;
				}
				svg {
				width: 180px;
				height: 22px;
				}
				p {
					margin-top: 25px;
					font-size: 16px;
					@include media("<=tablet") {
						font-size: 14px;
						margin-top: 18px;
						line-height: 20px;
					}
				}
				span {
					margin-top: 25px;
					font-size: $root-font-size - 4;
					line-height: 17px;
					color: $bodytext-color;
					letter-spacing: 0.35px;
					@include media("<=tablet") {
						font-size: 12px;
						margin-top: 22px;
						line-height: 15px;
					}
				}
			}
		}
		&-menu {
			@include flexbox;
			flex-wrap: wrap;
			flex-direction: row;
			width: 100%;
			@include media("<=tablet") {
				flex-direction: column;
			}
		}
	}
}
.footer-link-group {
	max-width: 270px;
	width: 100%;
	&:last-child {
		margin-bottom: 0;
	}
	@include media("<=largerdesktop") {
		max-width: 100% !important;
		width: 33%;
	}
	@include media("<=tablet") {
		width: 100%;
		margin-bottom: 70px;
		text-align: center;
	}
	span {
		color: $headline-color;
		font-size: 20px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 24px;
		@include media("<=tablet") {
			font-size: 18px;
			line-height: 22px;
			margin-bottom: 18px;
		}
	}
	a {
		display: table;
		color: $bodytext-color;
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		margin-bottom: 16px;
		@include transition(all, .4s, ease-in-out);
		&:last-child {
			margin-bottom: 0;
		}
		@include media("<=tablet") {
			margin: 0 auto 18px auto;
		}
		&:hover {
			color: $primary-color;
			padding-left: 10px;
			@include media("<=tablet") {
				color: $bodytext-color;
				padding-left: 0;
			}
		}
	}
}