.static-base {
    h1, h2, h3, h4, h5, h6 {
        color: $black;
        margin-bottom: 20px;
        line-height: 30px;
    }
    h1 {
        color: $black;
        font-size: 60px;
        margin-bottom: 0;
        @include media(">ltablet") {
            font-size: 60px;
        }
        @include media(">tablet") {
            font-size: 28px;
        }
    }
    h2 {
        font-size: 26px;
        @include media(">tablet") {
            font-size: 24px;
        }
    }
    h3 {
        font-size: 24px;
        @include media(">tablet") {
            font-size: 22px;
        }
    }
    h4 {
        font-size: 22px;
        @include media(">tablet") {
            font-size: 20px;
        }
    }
    h5 {
        font-size: 20px;
        @include media(">tablet") {
            font-size: 18px;
        }
    }
    h6 {
        font-size: 18px;
        @include media(">tablet") {
            font-size: 16px;
        }
    }
    p {
        font-size: $root-font-size - 4;
        font-weight: $regular;
        margin-bottom: 25px;
        line-height: 25px;
        @include media(">tablet") {
            font-size: $root-font-size;
        }
        a {
            text-decoration: underline;
            color: $black;
            &:hover {
                color: $black;
            }
        }
    }
    ul {
            margin-bottom: 35px;
            /*list-style: none;*/
            padding: 0;
            li {
                position: relative;
                padding-left: 30px;
                margin-bottom: 15px;
                line-height: 25px;
                color: $black;
                font-size: 16px;
                font-weight: 400;
                &:before {
                    position: absolute;
                    content: '•';
                    left: 0;
                    top: 1px;
                    font-size: 25px;
                    color: $black;
                    border-radius: 50%;
                }
                ul {
                    li {
                        &:before {
                            content: '•';
                        }
                    }
                }
                a {
                    font-size: 14px;
                    text-decoration: underline !important;
                }
            }
        }
        small {
            margin-bottom: 15px;
            display: block;
        }
        ol {
            counter-reset: numbered;
            li {
                position: relative;
                margin-bottom: 15px;
                line-height: 25px;
                color: $black;
                font-size: 16px;
                font-weight: 400;
                display: table;
                &:before {
                    content: counters(numbered, '.')'. ';
                    counter-increment: numbered;
                    font-size: $root-font-size - 4;
                    color: $bodytext-color;
                    display: table-cell;
                    vertical-align: top;
                    padding-right: 20px;
                    @include media(">tablet") {
                        font-size: $root-font-size;
                    }
                }
                p {
                    display: inline-block;
                    a {
                        text-decoration: underline !important;
                    }
                    span {
                        color: #ff0000;
                    }
                }
                ol {
                    padding-left: 0;
                    margin-bottom: 0;
                    li {

                    }
                }
                ul {
                    list-style-type: circle;
                    counter-reset: none;
                    li {
                        list-style-type: circle;
                        position: relative;
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 20px;
                        line-height: 25px;
                        &:before {
                            counter: none !important;
                            counter-increment: none;
                            content: '●' !important;
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
}

.error-base {
    .xl-heading {
        font-size: $root-font-size + 82;
        font-weight: $bold;
        display: block;
        color: $primary-color;
        line-height: 80%;
        @include media(">=tablet") {
            line-height: 80%;
            font-size: $root-font-size + 112;
        }
        @include media(">mdesktop") {
            line-height: 80%;
            font-size: $root-font-size + 182;
        }
    }
}