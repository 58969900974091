.input-wrapper {
	&-inner {
		max-width: 766px;
		margin: 0 auto;
	}
	.form-group {
		margin-bottom: 18px;
		@include media(">tablet") {
			margin-bottom: 26px;
		}
	}
}

.form-wrapper {
	.form-group {
		margin-bottom: 20px;
	}
	label {
		width: 100%;
		text-align: left;
		color: $primary-color;
		font-size: $root-font-size - 2;
		line-height: 25px;
		padding-left: 16px;
		margin-bottom: 16px;
	}
	input {
		padding: 13px 13px 13px 32px;
		font-size: 16px;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $bodytext-color;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: $bodytext-color;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: $bodytext-color;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: $bodytext-color;
		}
	}
	textarea {
		height: 190px;
		padding: 28px 28px 28px 32px;
		resize: none;
		@include media(">tablet") {
			height: 150px;
		}
	}
	input, textarea {
		@include borderradius(5px);
		border: none;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	.btn-group {
		.button {
			border: none;
			margin-top: 17px;
			margin-bottom: 0;
			@include media(">tablet") {
				margin-top: 15px;
			}
		}
	}
	.field-icon {
	  float: right;
	  margin-right: 15px;
	  margin-top: -40px;
	  position: relative;
	  z-index: 2;
	  font-size: 28px;
	  color: $lightergray-bg;
	  cursor: pointer;
	}
}

.link-wrapper {
	.login-failed {
		color: $primary-color;
		font-weight: $regular;
		font-size: $root-font-size - 2;
		line-height: 25px;
		margin-bottom: 20px;
		text-decoration: underline;
		@include media(">ltablet") {
			margin-bottom: 35px;
			color: $headline-color;
			text-decoration: none;
			font-weight: $regular;
			font-size: $root-font-size;
		}
	}
}

.choose-tabs {
	display: flex;
	position: relative;
	background-color: $white;
	padding: 4px;
	border: 1px solid $lightgray-border;
	@include borderradius(46px);
	* {
		z-index: 2;
	}
	input[type=radio] {
		display: none;
		&:checked {
			+ {
				label {
					color: $white;
				}
			}
		}
	}
	.option-tab {
		@include flexbox;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 178px;
		font-size: 18px;
		font-weight: 500;
		border-radius: 99px;
		margin-bottom: 0;
		cursor: pointer;
		padding-left: 0;
		transition: color 0.15s ease-in;
	}
	input[id=radio-1] {
		&:checked {
			~ {
				.glider {
					transform: translateX(0);
				}
			}
		}
	}
	input[id=radio-2] {
		&:checked {
			~ {
				.glider {
					transform: translateX(100%);
				}
			}
		}
	}
	input[id=radio-3] {
		&:checked {
			~ {
				.glider {
					transform: translateX(200%);
				}
			}
		}
	}
	.glider {
		position: absolute;
		display: flex;
		height: 44px;
		width: 178px;
		background-color: $primary-color;
		z-index: 1;
		border-radius: 99px;
		transition: 0.25s ease-out;
	}
	@media (max-width: 700px) {
		.tabs {
			transform: scale(0.6);
		}
	}
}

/*input button group*/

.input-with-btn {
	@include flexbox;
	align-items: center;
	.input-wrap {
		margin-right: 20px;
		width: 100%;
		.form-group {
			width: 100%;
		}
		&-small {
			max-width: 28%;
			width: auto;
		}
	}
	a {
		margin-top: 10px;
		&:not(:first-of-type) {
			margin-left: 10px;
		}
	}
	.split-btns {
	    margin-top: 10px;
	    a {
	        margin-top: 0;
	    }
	}
	&--start {
		align-items: flex-start;
		.preurl {
			margin: 15px 25px 0 0;
			font-size: $root-font-size - 2;
			color: $bodytext-color;
		}
	}
}

/*radio group*/

.radio-grp-full {
  ul {
    li {
      position: relative;
      //width: 12%;
      //max-width: 160px;
      //width: 100%;
      //height: 44px;
      //float: left;
      display: inline-block;
      text-align: center;
      margin: 0 12px 12px 0;
      //padding: 7px 45px;
      vertical-align: top;
      label, input {
        display: block;
        //position: absolute;
        //top: 0;
        //left: 0;
        //right: 0;
        //bottom: 0;
      }
      input {
        position: absolute;
      }
      label {
        //padding: 7px;
        padding: 11px 25px;
        margin-bottom: 0 !important;
        border: 1px solid $lightgray-border;
        cursor: pointer;
        z-index: 9;
        letter-spacing: -0.08px;
        font-size: 14px;
        line-height: 1 !important;
        font-weight: $regular;
        color: $bodytext-color !important;
        @include borderradius(35px);
        &:hover {
          border-color: $primary-color;
          color: $primary-color !important;
        }
      }
      input[type="radio"], input[type="checkbox"] {
        opacity: 0.01;
        z-index: 10;
      }
      input[type="radio"]:checked+label, .Checked+label, input[type="checkbox"]:checked+label, .Checked+label {
        border-color: $primary-color;
        color: $primary-color !important;
      }
    }
  }
}


/*custom checker switch*/

.switch {
	position: relative;
	display: inline-block;
	width: 52px;
	height: 26px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: transparent;
	border: 1px solid $lightgray-border;
	transition: .4s;
	&:before {
		position: absolute;
		content: "";
		height: 18px;
		width: 18px;
		left: 3px;
		bottom: 3px;
		background-color: $headline-color;
		transition: .4s;
	}
}
input {
	&:checked {
		+ {
			.slider {
				background-color: transparent;
				&:before {
					transform: translateX(26px);
					background-color: $green-accent-color;
				}
			}
		}
	}
	&:focus {
		+ {
			.slider {
				outline: none;
			}
		}
	}
}
.slider.round {
	border-radius: 34px;
	&:before {
		border-radius: 50%;
	}
}
