/*new modify starts*/
.inner-wrapper {
	.postlogin-content-wrapper {
		padding: 105px 0 118px;
	}
	.postlogin-sidebar {
		display: none;
	}
	.postlogin-content {
		margin-left: 0;
	}
	&-postlogin {
		.postlogin-content-wrapper {
			padding: 105px 0 118px;
		}
		.postlogin-sidebar {
			@include media(">ltablet") {
				display: block;
			}
		}
		.postlogin-content {
			@include media(">ltablet") {
				margin-left: 300px;
			}
		}
	}
}
.content-outer-wrap {
	@include borderradius(2px);
	border: 1px solid $lightgray-border;
	padding: 25px;
	background: $white;
	position: relative;
	@include media(">ltablet") {
		padding: 30px;
	}
	.domain-content {
		width: 100%;
		@include media(">=largerdesktop") {
			max-width: 65%;
		}
	}
	.domain-logo {
		max-width: 32%;
	    width: 100%;
	    @include flexbox;
	    align-items: center;
	    justify-content: center;
	    padding: 30px;
	    @include media("<=largerdesktop") {
	    	display: none;
	    }
	}
	.content-info-header {
		.mob-domain-logo {
			position: relative;
		    max-width: 100%;
    		width: 100%;
		    @include flexbox;
    		align-items: center;
    		justify-content: center;
    		padding: 25px 0 40px 0;
		    @include media(">=largerdesktop") {
		    	position: absolute;
		    	@include transform(translateY(-50%));
		    	right: 30px;
		    	top: 50%;
		    	max-width: 32%;
		    	padding-left: 60px;
		    }
			svg {
			    width: 82px;
			    height: 82px;
			    @include media(">=largerdesktop") {
			    	width: 154px;
			    	height: 154px;
			    }
			}
		}
	}
}
.input-flex-outer {
	@include flexbox;
	align-items: flex-start;
    width: 100%;
    flex-direction: column;
    @include media(">ltablet") {
    	flex-direction: row;
    }
    .input-with-btn {
    	width: 100%;
    	.input-wrap {
    		&:last-child {
    			@include media("<=ltablet") {
    				margin-right: 0;
    			}
    		}
    		&-small {
    			max-width: 100px;
    			width: 100%;
    		}
    	}
    }
    .split-btns {
    	margin-top: 16px;
    	justify-content: center;
    	@include media(">ltablet") {
    		margin-top: 10px;
    	}
    	a {
    		@include media("<=ltablet") {
    			min-width: 170px;
    		}
	    	&:not(:first-of-type) {
				margin-left: 10px;
			}
		}
    }
}
.split-btns-wrapper {
	@include media("<=ltablet") {
		width: 100%;
	}
}

.def-text {
	font-weight: $regular;
    font-size: $root-font-size - 2;
    color: $bodytext-color;
}

.body-wrapper {
 	padding: 105px 0 118px;
}

.inner-wrapper {
		.postlogin-content-wrapper {
			padding: 105px 0 118px;
		}
		.postlogin-sidebar {
			display: none;
		}
		.postlogin-content {
			margin-left: 0;
		}
		&-postlogin {
			.postlogin-content-wrapper {
				padding: 105px 0 118px;
				&--premium {
					background: $white;
					.page-top-info {
						@include media("<=tablet") {
							padding-bottom: 20px;
							background: $white;
							box-shadow: 0px 25px 35px 0px rgba(77,77,77,0.08);
						}
					}
				}
			}
			.postlogin-sidebar {
				@include media(">ltablet") {
					/*display: block;*/
					display: flex;
					justify-content: space-between;
					flex-direction: column;
					overflow-x: hidden;
				}
				.multilingual-group {
			    	margin-bottom: 40px;
			    	/*overflow-x: hidden;*/
			    	.btn-group {
			    		.dropdown-toggle {
			    			padding-left: 0;
			    			padding-top: 0;
			    			span {
			    				font-size: $root-font-size - 4;
			    				color: $bodytext-color;
			    				font-weight: $regular;
			    				margin-right: 10px;
			    			}
			    			&:after {
			    				display: none;
			    			}
			    		}
			    	}
			    }
			}
			.postlogin-content {
				@include media(">ltablet") {
					margin-left: 300px;
				}
			}
		}
}

.postlogin-content-wrapper {

}

.postlogin-content {
	margin-left: 0;
    min-height: 600px;
    box-shadow: none;
    @include borderradius(5px);
    background: $faded-white;
    @include media(">ltablet") {
    	margin-left: 300px;
    	background: $white;
    	@include boxshadow(0px, 3px, 25px, 0px, $light-gray-shadow);
    }
    &-inner {
    	padding: 0;
    	@include media(">ltablet") {
    		padding: 50px;
    	}
    	/* &.two-half-block {
    		padding: 11px;
    		flex-direction: column;
    		align-items: stretch;
    		@include media(">tablet") {
    			padding: 22px;
	    		flex-direction: row;
    		}
    	} */
    }
		.two-half-block {
			padding: 11px;
			flex-direction: column;
			align-items: stretch;
			@include media(">tablet") {
				padding: 22px;
				flex-direction: row;
			}
		}
    &--mod {
    	.page-top-info {
    		/*display: none;*/
    		@include media(">ltablet") {
				@include flexbox;
			}
    	}
    }
    &--payment {
    	background: $white;
    	.page-header {
    		h6 {
    			@include media("<=tablet") {
	    			font-size: $root-font-size - 2;
	    			line-height: 20px;
	    		}
    		}
    	}
    }
}

/*user menu sidebar*/

/*.user-menu-sidebar {
	position: fixed;
	background: $white;
	top: 0;
	max-height: 100%;
	height: 100%;
	z-index: 2;
	padding-top: 84px;
	left: 0;
	width: 100%;
	right: 0;
}*/

.user-menu {
	height: 100%;
	overflow-y: auto;
	display: none;
    flex-direction: column;
    justify-content: space-between;
	&-top {
		&-details {
			border-bottom: 1px solid $lightgray-border;
			padding: 25px 35px;
			h6 {
				color: $headline-color;
				font-size: $root-font-size - 2;
				line-height: 20px;
				font-weight: $bold;
			}
			span {
				color: $bodytext-color;
				font-size: $root-font-size - 4;
				font-weight: $regular;
				display: inline-block;
			}
		}
		&-nav {
			padding: 25px 35px;
			p {
				color: $bodytext-color;
			    font-size: $root-font-size - 2;
			    margin-bottom: 0;
				span {
					color: $headline-color;
				    font-size: $root-font-size - 4;
				    font-weight: $bold;
				    margin-right: 10px;
				}
			}
			.button--sm {
				padding: 10px 16px;
			}
			.user-menu-links {
				margin-top: 25px;
				a {
					display: table;
					color: $bodytext-color;
					font-size: $root-font-size - 4;
				    font-weight: $regular;
				    margin-bottom: 18px;
				}
			}
			.multilingual {
				.multilingual-group {
					.btn-group {
						.dropdown-toggle {
							padding-left: 0;
						}
						.dropdown-menu {
							position: fixed !important;
							width: 100%;
							overflow-y: auto;
    						min-width: 100%;
    						bottom: 0;
						    left: 0 !important;
						    padding: 25px;
						    right: 0 !important;
						    transform: none !important;
						    padding-top: 85px;
						    @include transition(transform, .8s, ease);
						    li {
						    	display: inline-block;
						    }
						}
					}
				}
			}
		}
	}
	&-bottom {
		border-top: 1px solid $lightgray-border;
		padding: 25px 35px;
		a {
			display: table;
			color: $bodytext-color;
			font-size: $root-font-size - 4;
		    font-weight: $regular;
		}
	}
	&.sidebar-active {
		@include flexbox;
		-webkit-animation: fade-in 1.5s ease-out;
        -moz-animation: fade-in 1.5s ease-out;
        background: $white;
	}
}
.animated-in {
	-webkit-animation: fade-in 1.5s ease-in;
    -moz-animation: fade-in 1.5s ease-in;
}
.animated-out {
	-webkit-animation: fade-in 1.5s ease-out;
    -moz-animation: fade-in 1.5s ease-out;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*post login contents*/
/*.cont-info-block {
	display: none;
	&.show {
		display: block;
	}
}*/
.page-top-info {
	@include flexbox;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 25px;
	position: relative;
	@include transition(all, .4s, ease);
	@include media(">ltablet") {
		margin-bottom: 55px;
	}
	/*&.show-top-info {
		left: 0;
	}*/
}
.page-btns {
	a, button {
		margin-left: 14px;
	}
}
.page-header {
	max-width: 605px;
	p {
		font-size: $root-font-size - 4;
		line-height: 25px;
		margin-bottom: 0;
	}
	h6 {
		margin-bottom: 22px;
		font-size: $root-font-size + 2;
		line-height: 24px;
		@include flexbox;
		align-items: center;
		a {
			margin-right: 10px;
			color: $headline-color;
			@include media(">ltablet") {
				display: none;
			}
			i {
				font-size: $root-font-size + 12;
			}
		}
	}

	&--spacing-bottom {
		margin-bottom:24px;
	}

	&--widthincrease {
		max-width:812px;
	}
	&--message-place{
		h6{
			margin-bottom: 15px;
		}

		.input-checker-wrapper {
			margin-top:20px;
		}

	}
	&-premium {
		background: url(../images/premium_banner.jpg) center center no-repeat;
		padding: 48px 55px;
		background-size: cover;
		@include flexbox;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		@include media("<=tablet") {
	        padding: 36px 34px;
	    }
		h6 {
			margin-bottom: 5px;
		}
		.button {
			margin-top: 18px;
    		min-width: 150px;
		}
		.price {
			font-size: $root-font-size + 32;
			line-height: 60px;
			font-weight: $bold;
			color: $primary-color;
			margin-bottom: 18px;
			line-height: 1;
			@include media("<=tablet") {
		        line-height: 50px;
		        font-size: $root-font-size + 22;
		    }
			p {
				padding: 0 0 2px 6px;
				font-weight: $regular;
				display: inline-block;
				@include media("<=tablet") {
					padding: 0 0 6px 6px;
				}
			}
		}
		&-info {
			max-width: 50%;
			@include media("<=tablet") {
		        max-width: 100%;
		    }
		}
		.upgrade-logo {
			max-width: 50%;
			padding-left: 30px;
			@include media("<=tablet") {
				position: absolute;
				right: 34px;
    			top: 34px;
			}
			svg {
				width: 154px;
				height: 154px;
				@include media("<=tablet") {
					width: 82px;
					height: 82px;
				}
			}
		}
	}
}
.info-title {
	font-size: $root-font-size;
	line-height: 22px;
	font-weight: $bold;
	color: $headline-color;
	span {
		color: $primary-color;
		font-size: $root-font-size + 2;
		line-height: 22px;
	}
}
.global-p {
	font-size: $root-font-size - 4;
	line-height: 25px;
	font-weight: $regular;
	color: $bodytext-color;
}
.premium-feature {
	margin-top: 40px;
	display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @include media("<=largerdesktop") {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    @include media("<=ltablet") {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    @include media("<=tablet") {
        grid-gap: 14px;
    }
    &--box {
    	border: 1px solid $lightgray-border;
    	@include borderradius(2px);
    	padding: 35px 30px;
    	@include media("<=tablet") {
    		background: $white;
    	}
    	svg {
    		width: 64px;
    		height: 64px;
    		margin-bottom: 18px;
    	}
    	.premium-title {
    		font-size: $root-font-size - 4;
			line-height: 17px;
			font-weight: $bold;
			color: $headline-color;
			margin-bottom: 10px;
    	}
    	p {
    		margin-bottom: 0;
    	}
    }
}
.page-btns {
	@include flexbox;
	align-items: center;
	width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px 25px;
    justify-content: center;
    z-index: 1;
    background: $faded-white;
    filter: drop-shadow(0px -3px 6px rgba(0, 0, 0, .05));
	@include media(">ltablet") {
		width: auto;
		position: relative;
		filter: none;
		background: $white;
		padding: 0;
	}
	a {
		&:first-of-type {
			margin-left: 0;
		}
	}
}

.content-wrapper {

}
.content-sidebar {
	padding-top: 0;
    width: 100%;
    float: none;
    left: auto;
    right: auto;
    display: block;
    @include media(">mdesktop") {
    	float: left;
    	padding-top: 18px;
    	width: 195px;
    }
	h6{
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 22px;
	}
    &-menu {
    	padding: 0 0 15px 0;
    	height: 100%;
    	overflow-y: auto;
    	ul {
    		li {
    			margin: 15px 0;
    			display: inline-block;
    			@include media(">mdesktop") {
    				display: block;
    			}
    			a {
    				display: inline-block;
				    width: auto;
				    text-decoration: none;
				    position: relative;
				    letter-spacing: 0.35px;
				    padding: 8px 20px;
				    color: $bodytext-color;
				    font-size: $root-font-size - 4;
				    line-height: 18px;
				    @include transition(all, .3s, ease);
				    @include borderradius(21px);
				    @include media(">mdesktop") {
				    	width: 100%;
				    }
				    &.active {
				    	background: $primary-color;
    					color: $white;
				    }
    			}
    			/*&.active {
    				a {
    					background: $primary-color;
    					color: $white;
    				}
    			}*/
    		}
    	}
    }
    &--box {
    	background: $white;
    	border: 1px solid $lightgray-border;
    	@include borderradius(2px);
    	margin-bottom: 20px;
    	/*display: none;*/
    	@include media(">ltablet") {
    		@include borderradius(0px);
    		border: none;
    		background: transparent;
    		margin-bottom: 0;
    		/*display: block;*/
    	}
    	.content-sidebar-menu {
    		@include media("<=ltablet") {
    			padding: 20px;
    		}
    		ul {
    			li {
    				@include media("<=ltablet") {
    					display: block;
    				}
    				a {
    					@include media("<=ltablet") {
	    					display: block;
	    				}
    				}
    			}
    		}
    	}
    }
    .nav {
    	margin-bottom: 20px;
    	@include media(">mdesktop") {
    		margin-bottom: 0;
    	}
    	&-link {
    		display: inline-block;
		    width: auto;
		    text-decoration: none;
		    position: relative;
		    letter-spacing: 0.35px;
		    padding: 8px 20px;
		    color: $bodytext-color;
		    font-size: $root-font-size - 4;
		    line-height: 18px;
		    @include transition(all, .3s, ease);
		    @include borderradius(21px);
		    margin: 7px 0;
		    &.active {
		    	background: $primary-color;
    			color: $white;
    			&.has-error {
    				border: 1px solid red;
    				background: transparent;
    			}
		    }
		    &.has-error {
		    	color: red;
		    }
    	}
    }
}
.content-info {
	margin-left: 0;
	background: $faded-white;
	@include borderradius(2px);
	border: none;
	border-bottom: 1px solid $lightgray-border;
	padding: 0 0 18px 0;
	@include media(">ltablet") {
		border: 1px solid $lightgray-border;
		padding: 40px 35px;
		background: $white;
	}
	@include media(">mdesktop") {
		margin-left: 240px;
	}
	.common-info-wrapper {
		max-width: 906px;
		&--medium {
			max-width: 800px;
		}
		&--widerspace {
			max-width: 1065px;
		}
	}

	&--fullwidth {
		border-bottom: none;
		@include media(">mdesktop") {
			margin-left:0;
			border:none;
			padding:0;
		}
	}
}
.input-checker-wrapper {
	margin-top: 40px;
	.checker-group {
		label {
			font-size: $root-font-size - 2;
		}
	}

	&--increasespace {
		@include media(">mdesktop") {
			max-width:704px;
			margin-left:auto;
			margin-right:auto;
		}
		@include media(">largerdesktop") {
			max-width: 100%;
		}
	}
}

.content-info-top {
	padding-bottom: 13px;
	@include media(">mdesktop") {
		padding-bottom: 52px;
	}

	&--nomarginbottom {
		padding-bottom:0; 
	}
	.customer-service {
		padding: 0;
		background: transparent;
		min-height: auto;
		margin-top: 80px;
		@include media("<=tablet") {
			margin-top: 44px;
		}
		&-inner {
			margin-top: 0;
			padding: 0;
			h2 {
				font-size: $root-font-size;
				font-weight: $bold;
				color: $headline-color;
				line-height: 22px;
				margin-bottom: 38px;
				@include media("<=tablet") {
					margin-bottom: 65px;
				}
			}
			.feature-box-wrap {
				.feature-box {
				}
			}
		}
	}
	.form-btn-grp {
		.button {
			@include media("<=tablet") {
				margin-top: 14px;
			}
		}
	}
}
.content-info-header {
	padding-bottom: 11px;
	h6 {
		font-size: $root-font-size;
		line-height: 22px;
		font-weight: $bold;
		color: $headline-color;
		margin-bottom: 15px;
		@include flexbox;
		align-items: center;
		a {
			margin-right: 10px;
			color: $headline-color;
			@include media(">ltablet") {
				display: none;
			}
			i {
				font-size: $root-font-size + 12;
			}
		}
	}
	p {
		font-size: $root-font-size - 4;
		line-height: 25px;
		margin-bottom: 15px;
	}
}
.content-form {
	.form-group {
		margin-bottom: 24px;
		label {
			width: 100%;
			text-align: left;
			color: $headline-color;
			font-size: $root-font-size - 4;
			font-weight: $bold;
			line-height: 18px;
			margin-bottom: 14px;
		}
		.help-text {
			display: block;
			color: $bodytext-color;
			padding-left: 10px;
			font-size: $root-font-size - 6;
			font-weight: $regular;
			margin-top: 10px;
		}
		input {
			padding: 18px 22px;
			font-size: $root-font-size - 2;
			line-height: 1;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: $bodytext-color;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: $bodytext-color;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: $bodytext-color;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: $bodytext-color;
			}
		}
		input:disabled {
			cursor: not-allowed;
			pointer-events: all;
		}
		textarea {
			height: 200px;
			padding: 28px 28px 28px 32px;
			resize: none;
			@include media(">tablet") {
				height: 300px;
			}
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: $bodytext-color;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: $bodytext-color;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: $bodytext-color;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: $bodytext-color;
			}
		}
		input, textarea {
			@include borderradius(5px);
			border: 1px solid $lightgray-border;
			color: $bodytext-color;
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.select2-container {
			width: 100% !important;
			.select2-selection--single {
				@include borderradius(5px);
				border: 1px solid $lightgray-border;
				width: 100%;
				clear: both;
				display: table;
				height: 58px;
				.select2-selection__rendered {
					padding: 18px 22px;
					font-size: $root-font-size - 2;
					color: $bodytext-color;
					line-height: normal;
				}
				.select2-selection__arrow {
					right: 20px;
					top: 10px;
					height: 100%;
					b {
						display: none;
					}
					&:after {
						content: "keyboard_arrow_down";
		    			font-family: 'Material Icons';
						-webkit-font-feature-settings: 'liga';
						font-size: $root-font-size + 9;
						color: $black;

					}
				}
			}
			.select2-selection--multiple {
				@include borderradius(5px);
				border: 1px solid $lightgray-border;
				width: 100%;
				clear: both;
				display: table;
				height: 58px;
				padding: 0;
				/*@include flexbox;
				align-items: center;*/
				padding: 8px 22px;
				.select2-selection__rendered {
					/*padding: 18px 22px;*/
					font-size: $root-font-size - 2;
					color: $bodytext-color;
					line-height: normal;
					/*clear: both;
					display: inline-block;*/
					.select2-selection__choice {
						margin: 4px !important;
						background: #a973e1;
						color: $white;
						padding: 4px 4px 4px 20px;
						border: none;
						/*@include flexbox();
						align-items: center;
						justify-content: center;
						height: 100%;*/
						.select2-selection__choice__remove {
							color: $white;
							background: $primary-color;
							border-right: 1px solid $primary-color;
							padding: 4px;
							/*@include flexbox();
							align-items: center;
							justify-content: center;*/
							height: 100%;
							span {
								height: 22px;
							}
						}
						.select2-selection__choice__display {
							height: 21px;
							display: inline-block;
						}
					}
				}
				.select2-search--inline {
					/*line-height: normal;*/
					/*height: 30px;*/
					.select2-search__field {
						margin: 0;
						/*height: 24px;
    					vertical-align: top;
    					padding-top: 4px;*/
    					line-height: 40px;
					    height: 40px;
					    vertical-align: middle;
					    padding-top: 0;
					}
				}
				/*.select2-selection__arrow {
					right: 20px;
					top: 10px;
					height: 100%;
					b {
						display: none;
					}
					&:after {
						content: "keyboard_arrow_down";
		    			font-family: 'Material Icons';
						-webkit-font-feature-settings: 'liga';
						font-size: $root-font-size + 9;
						color: $black;

					}
				}*/
			}
		}
	}
	.btn-wrap {
		margin-top: 3px;
	}
	.text-btn-wrap {
		margin-top: 52px;
		span {
			font-size: $root-font-size;
			font-weight: $bold;
			color: $headline-color;
			line-height: 22px;
			margin-bottom: 22px;
		}
		.text-wrap {
			margin-bottom: 22px;
			span {
				font-size: $root-font-size;
				font-weight: $bold;
				color: $headline-color;
				line-height: 22px;
			}
			p {
				font-size: $root-font-size - 4;
				font-weight: $regular;
				color: $bodytext-color;
				line-height: 25px;
				margin-top: 10px;
			}
		}
	}
	&--image {
		.image-dropdown-group {
			.select2-container {
				.select2-selection--single {
					.select2-selection__rendered {
						padding: 13px 22px;
						@include flexbox;
						align-items: center;
					}
				}
			}
		}
	}
}

/*country block*/
.general-settings {
	.text-btn-wrap {
		margin-top: 8px;
	}
}

.select-tag-wrapper {
	@include flexbox;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 5px;
}
.select-tag {
	border: 1px solid $primary-color;
	min-width: 125px;
	border-radius: 35px;
	padding: 7px 18px 7px 20px;
	margin: 0 15px 15px 0;
	@include flexbox;
	align-items: center;
	position: relative;
	span {
		font-size: $root-font-size - 4;
		font-weight: $bold;
		color: $primary-color;
		letter-spacing: 0.35px;
		line-height: 1;
	}
	a {
		/*position: absolute;
		right: 15px;*/
		line-height: 1;
		margin-left: 10px;
		i {
			font-size: $root-font-size + 2;
		}
		&:hover {
			color: $primary-hover-color;
		}
	}
}
.checker-outer {
	margin-top: 18px;
	.form-group {
		margin-bottom: 34px;
	}
}
.checker-group-wrapper {
	
}
.checker-group {
	display: inline-block;
	margin-right: 50px;
	&:last-child {
		margin-right: 0;
	}
	.checker {
		font-size: $root-font-size - 4 !important;
		display: inline-block;
		font-weight: $regular !important;
		line-height: 1.5 !important;
		color: $bodytext-color !important;
		span {
			line-height: normal;
		}
	}
	.checkmark {
		border: 2px solid $bodytext-color;
	}
}

.o-notification {
	background-color: $primary-color;
	position: relative;
	@include borderradius(5px);
	margin-bottom: 20px;
	padding: 15px;
	&--inner {
		color: $white;
		line-height: 1;
		.notificationImage {
			margin-right: 10px;
		}
		.notificationContent {
			span {
				font-size: $root-font-size - 3;
				line-height: 18px;
			}
		}
	}
	.notificationClose {
		position: absolute;
		background: $primary-color;
	    right: -10px;
	    top: -10px;
	    width: 20px;
	    height: 20px;
	    padding: 4px;
	    @include borderradius(50%);
	    @include boxshadow(0px, 0px, 5px, 0px, rgba(0,0,0,0.75));
	    svg {
	    	fill: $white;
	    }
	}
}

/*popper dashboard click*/

.popper {
	background-color: $white;
	box-shadow: 0px 0px 6px #9863CF26;
	letter-spacing: 0.3px;
	color: $bodytext-color;
	font-size: $root-font-size - 6;
	line-height: 15px;
	@include borderradius(10px);
	padding: 14px 22px;
	position: absolute;
	bottom: 30px;
    left: -55px;
}

/*tooltip*/

.tooltip-inner {
	background-color: $white;
	opacity: 1;
	box-shadow: 0px 0px 6px #9863CF26;
	letter-spacing: 0.3px;
	color: $bodytext-color;
	font-size: $root-font-size - 6;
	line-height: 15px;
	padding: 12px 22px;
	@include borderradius(10px);
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  /*border-top-color: #f00 !important;*/
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid $white;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #f00 !important;
}


.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #f00 !important;
}


.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #f00 !important;
}

/*dropdown language*/

.lang-overlay {
	&.active {
		position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background-color: rgba(0,0,0,0.5);
	    z-index: 1032;
	    cursor: pointer;
	}
}

.drop-wrap {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    background: rgba(0,0,0,.5);
    z-index: 1;
    display: none;
    .dropdown-menu {
    	position: relative;
	    background: #fff;
	    left: 0;
	    right: 0;
	    top: 30%;
	    left: 50%;
	    @include transform(translate(-50%, -50%));
	    height: 300px;
	    max-width: 400px;
	    margin: 30px auto;
	    z-index: 2;
	    width: 100%;
	    padding: 15px;
	    overflow-y: auto;
	    overflow-x: hidden;
    }
    &-sidebar {
    	z-index: 1400;
    	.drop-wrap-menu {
			li {
				a {
					@include borderradius(0px);
					@include flexbox;
					justify-content: space-between;
					clear: both;
					overflow: hidden;
	    			width: auto;
	    			height: auto;
	    			margin: 12px 8px !important;
					span {
						padding-right: 12px;
						display: inline-block;
					}
					img {
						@include borderradius(50%);
					    width: 24px;
					    height: 24px;
					    display: inline-block;
					    overflow: hidden;
					}
				}
			}
		}
    }
}

.extraservice-place {
	margin-top:8px;
		> label{
			font-size:14px;
			line-height: 17px;
			color:$headline-color;
			font-weight: $bold;
			margin-bottom: 27px; 
		}
}

.inline-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include media("<=lphone") {
		flex-direction:column;
		align-items: flex-start;
	}

		li {
			margin-right: 25px;
			margin-bottom: 16px;

			&:last-child{
				margin-right:0;
			}
			.checker-modify {
				@include flexbox;
				align-items: center;
				flex-direction: row;
				padding-left: 10px;
				a {
					width: 22px;
				    height: 22px;
				    background: $primary-color;
				    @include borderradius(50%);
				    @include flexbox;
				    align-items: center;
				    justify-content: center;
				    margin-left: 5px;
					&:hover {
						color: $primary-color;
					}
					i {
						font-size: $root-font-size - 4;
						color: $white;
					}
				}
			}
			&:hover {
				.checker {
					color: $headline-color;
					&.checked {
						color: $bodytext-color;
					}
				}
			}
		}
		&-modifier {	
			li {
				@include flexbox;
				align-items: center;
				flex-direction: row;
			}
		}
}
.list-group {
	.inline-list-modifier {
		li {
			width: 100%;
			border-bottom: 1px solid $lightgray-border-v2;
    		padding-bottom: 16px;
			.checker {
				font-weight: $regular;
				font-size: $root-font-size - 2;
				color: $bodytext-color;
				width: 100%;
				line-height: 1.5;
				margin-bottom: 0;
			}
			.checker-modify {
				a {
					line-height: 1;
					margin-right: 5px;
				}
			}
			&:hover {
				.checker {
					color: $headline-color;
					&.checked {
						color: $bodytext-color;
					}
				}
			}
		}
	}
}

.mobile-width {
	@include media("<=lphone") {
		margin-left:-15px;
		margin-right:-15px;
	}
	&--space{
		@include media("<=lphone") {
			margin-left:0px;	
		}
	}
}

.divider {
	border-top: 1px solid #F0F0F0;
	margin-bottom:26px;
}
.switch-group {
	min-width: 190px;
}
.mobile-popup {
	@include media("<=tablet") {
		position:fixed; 
		background: #fff;
		padding: 20px 30px;
		overflow-y: auto;
	}
	@include media(">tablet") {
		position:static;  
	}

	.add-link-wrapper {
		position:relative;
	}
}


.image-upload-place {

	h5 {
		text-align: left;
		color:$headline-color;
		font-size: 14px;
		font-weight: 700;
		line-height: 18px;
		margin-bottom: 11px;
	}
}

.upload-file-holder {
	position:relative;
	min-height:245px;
	border: 2px dashed #9863CF !important;
	margin-top: 25px;
	margin-bottom: 19px; 
	padding: 60px 20px !important;
		.upload-file-middle {
			width:100%;
			@include flexbox;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin: 0 !important;
			i {
				font-size: 40px;
				color: $primary-color-faded;
				margin-bottom: 8px;
			}
			svg {
				width: 40px;
				height: 40px;
				fill: $primary-color-faded;
				margin-bottom: 8px;
			}
			span {
				line-height: 1;
				margin-bottom: 17px;
				color: $bodytext-color;
				font-size: $root-font-size - 2;
			}
		}

	&--heightincrease {
		min-height: 308px;
	}
}

.upload-file-holder .dz-preview.dz-image-preview .dz-details .dz-filename {
	display: none;
}
.upload-file-holder .dz-preview .dz-remove {
	position: absolute;
	font-size: 0px !important;
	line-height: 1;
	right: -5px;
    top: -5px;
    width: 25px;
    height: 25px;
    z-index: 20;
    background: $primary-color;
    color: transparent;
    @include borderradius(50%);
    display: flex !important;
    display: -webkit-flex !important;
    align-items: center;
    justify-content: center;
}
.upload-file-holder .dz-preview .dz-remove:hover {
	color: transparent;
}
.upload-file-holder .dz-preview .dz-remove:after {
	content: "clear";
	font-family: 'Material Icons';
	-webkit-font-feature-settings: 'liga';
	font-size: $root-font-size - 3;
	color: $white;
}

.bottom-buttons-place {
	position:absolute;
	top:0;
	right:0; 

	@include media("<=tablet") {
		position:static;
		width: 100%;
		text-align: center;
	}
		button{
			margin-left:14px;
		}
}

.empty-post{
	font-size:16px;
}

.tour-place {
	margin-bottom: 56px;
}

.responsive-table {
	width:100%;
	overflow-y: auto;
}

.mobile-hidden {
	@include media("<=tablet") {
		display:none !important;
	}
}

/*toaster modify*/

.toast-bottom-right {
	z-index: 2 !important;
	.toast {
		border-radius: 50px !important;
		-moz-border-radius: 50px !important;
		box-shadow: 0px 3px 25px #0000001A !important;
		color: inherit !important;
		background: $white !important;
		opacity: 1 !important;
		overflow: inherit !important;
		padding: 10px 15px 10px 80px !important;
		min-width: 380px !important;
		width: auto !important;
		min-height: 64px;
		@include flexbox;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		&:before {
			font-size: $root-font-size + 4;
			color: $white;
			position: absolute;
		    left: 15px;
		    top: 50%;
		    @include transform(translateY(-50%));
		    @include borderradius(50%);
		    width: 35px;
		    height: 35px;
		    @include flexbox;
		    align-items: center;
		    justify-content: center;
		    border: 2px solid $white;
		    z-index: 1;
		}
		&:after {
			width: 58px;
		    height: 58px;
		    content: '';
		    position: absolute;
		    @include borderradius(50%);
		    left: 4px;
		    top: 50%;
		    @include transform(translateY(-50%));
		}
		.toast-close-button {
			font-size: $root-font-size + 7 !important;
			position: absolute !important;
			right: 0 !important;
			top: -30px !important;
			color: $black !important;
			font-weight: $regular;
		}
		.toast-title {
			font-size: $root-font-size - 2 !important;
			line-height: 20px !important;
			font-weight: $bold !important;
			color: $headline-color !important;
		}
		.toast-message {
			font-size: $root-font-size - 4 !important;
			line-height: 20px !important;
			font-weight: $regular !important;
			color: $bodytext-color !important;
		}
		&-error {
			&:before {
				content: "clear";
				font-family: 'Material Icons';
				-webkit-font-feature-settings: 'liga';
				background: $error-toaster-color;
			}
			&:after {
				background: $error-toaster-color;
			}
		}
		&-success {
			&:before {
				content: "done";
				font-family: 'Material Icons';
				-webkit-font-feature-settings: 'liga';
				background: $success-toaster-color;
			}
			&:after {
				background: $success-toaster-color;
			}
		}
		&-warning {
			&:before {
				content: "warning";
				font-family: 'Material Icons';
				-webkit-font-feature-settings: 'liga';
				background: $warning-toaster-color;
				font-size: $root-font-size;
			}
			&:after {
				background: $warning-toaster-color;
			}
		}
		&-info {
			&:before {
				content: "priority_high";
				font-family: 'Material Icons';
				-webkit-font-feature-settings: 'liga';
				background: $primary-color;
			}
			&:after {
				background: $primary-color;
			}
		}
	}
}


.contact-info-grid{
	@include media("<=tablet") {
		margin-top: 44px;
	}
}

.medium-heading {
	font-size:18px;
	line-height: 22px;
	margin-bottom: 38px;
}

.contact-list {
	display:flex;
	flex-wrap: wrap;
	font-size: 0;

	li{
		width:393px;
		// margin-right: 46px;
		margin-right: 37px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		@include media("<=tablet") {
			margin-right:15px;
			width:353px;
		}

		@include media("<=lphone") {
			width:100%;
			margin-right: 0;
		}

		

		&:last-child{
			margin-right: 0;
		}
		.box{
			background: $white;
			@include boxshadow(0px, 3px, 25px, 0px, $light-gray-shadow);
			border-radius: 5px;
			width:100%;
			display: flex;
    		align-items: center;
			min-height: 246px;

			.inner-box {
				max-width: 282px;
				width:100%;
				margin: 0 auto;

				h4{
					font-size: 20px;
					line-height: 24px;
					margin-bottom: 24px;
				}

				p{
					font-size: 18px;
					line-height: 25px;
					margin-bottom: 0;
				}
			}
		}
	}
}

.escort-place {
	display:flex;
	align-items: center;
}
.escort-image {
	width:33px;
	height: 33px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 19px;
	display: inline-block;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
.remove-tooltip {
	&:after, &:before{
		display:none;
	}
}

.group-section {
	margin-bottom: 50px;
	margin-top: 50px;

		&:first-child{
			margin-top: 0px;
		}
		&:last-child{
			margin-bottom: 0px;
		}

	.medium-heading {
		margin-bottom: 34px;
	}
}

/*select 2 variations*/

.select2-container--open {
	.select2-dropdown--below {
		margin-top: 6px;
		border: 1px solid $select-dropdown-border !important;
		.select2-search--dropdown {
			.select2-search__field {
				border: 1px solid $select-dropdown-border;
			}
		}
		.select2-results {
			.select2-results__options {
				.select2-results__option {
					font-size: $root-font-size - 4;
					padding: 9px 16px;
					font-weight: $regular;
					color: $select-dropdown-option;
					@include flexbox;
					align-items: center;
					&--highlighted {
						font-weight: $bold;
						background: $lightgray-bg;
					}
				}
			}
		}
	}
	.select2-dropdown--above {
		margin-top: 6px;
		border: 1px solid $select-dropdown-border !important;
		.select2-search--dropdown {
			.select2-search__field {
				border: 1px solid $select-dropdown-border;
			}
		}
		.select2-results {
			.select2-results__options {
				.select2-results__option {
					font-size: $root-font-size - 4;
					padding: 9px 16px;
					font-weight: $regular;
					color: $select-dropdown-option;
					&--highlighted {
						font-weight: $bold;
						background: $lightgray-bg;
					}
				}
			}
		}
	}
}

/*payment*/

.card-set {
	margin-top: 20px;
	img {
		margin-right: 10px;
		@include borderradius(3px);
		width: 65px;
	    height: 38px;
	    object-fit: cover;
	    object-position: center;
	}
}
.order-summary {
	@include flexbox;
	align-items: center;
	height: 100%;
	h5 {
		font-size: $root-font-size;
		line-height: 22px;
		font-weight: $bold;
		color: $headline-color;
		margin-bottom: 18px;
		text-align: left;
		@include media(">ltablet") {
			margin-bottom: 45px;
			text-align: center;
		}
	}
	&-list {
		width: 100%;
		padding: 35px 0;
		@include media(">ltablet") {
			padding: 0;
		}
		&-option {
			margin-bottom: 16px;
			@include flexbox;
			align-items: center;
			justify-content: space-between;
			p, span {
				font-size: $root-font-size - 4;
				line-height: 24px;
				font-weight: $regular;
				color: $bodytext-color;
				margin-bottom: 0;
			}
		}
		.button {
			margin-top: 55px;
		}
		.info-txt {
			margin-top: 32px;
			font-size: $root-font-size - 6;
			color: $bodytext-color;
			font-weight: $regular;
		}
		hr {
			margin-top: 24px;
			margin-bottom: 32px;
			border-top-color: $lightgray-border; 
		}
		&-top {
			padding: 0 20px;
			@include media(">ltablet") {
				padding: 0 20px;
			}
			@include media(">mdesktop") {
				padding: 0 40px;
			}
			@include media(">=largerdesktop") {
				padding: 0 72px;
			}
		}
		&-bot {
			padding: 0 22px;
			@include media(">ltablet") {
				padding: 0 20px;
			}
			@include media(">mdesktop") {
				padding: 0 40px;
			}
			@include media(">=largerdesktop") {
				padding: 0 72px;
			}
			.order-summary-list-option {
				p, span {
					color: $headline-color;
					font-weight: $bold;
				}
			}
		}
	}
}

.container-premium {
    @include media("<=tablet") {
        padding: 0;
    }
    .postlogin-content {
    	&-inner {
    		padding: 32px;
    		@include media("<=tablet") {
		        padding: 0;
		    }
    	}
    	.content-wrapper {
    		@include media("<=tablet") {
		        padding: 0 34px;
		    }
    	}
    }
}
.cont-info-block {
	overflow-y: scroll;
	overflow-x: hidden;
	padding: 100px 25px 25px;
    position: fixed;
    top: 0;
    left: 0;
    background: $faded-white;
    width: 100%;
    height: 100%;
	@include media(">ltablet") {
		padding: 0;
		top: auto;
		overflow-y: auto;
		background: $white;
	    position: relative;
	    z-index: 1;
	}
}
.img-dropdown-select {

}
.rules-list {
	li {
		&:last-child {
			margin-right: 25px;
		}
	}
}

.rates-content-sidebar{
	@include media("<=lphone") {
		padding: 15px;
	}
}
.rates-content-info{
	@include media("<=lphone") {
		padding: 15px;
	}
}
.rates-input-checker{
	@include media("<=lphone") {
		padding: 15px;
		margin-top: 25px;
	}
}