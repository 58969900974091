html, body{
    font-family: $lato;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: auto;
}
body {
    background: $faded-white;
    &.bodyopen {
        position: fixed;
    }
}
$root-font-size: 18px;
p {
    line-height: 25px;
    margin-bottom: 25px;
    color: $bodytext-color;
    @include font-size($p-font-sizes);
}
h1, h2, h3, h4, h5, h6{
    font-family: $lato;
    margin: 0 0 25px;
    color: $headline-color;
    font-weight: $bold;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    @include media("<=tablet") {
        margin: 0 0 15px;
    }
}
h1{
    @include font-size($h1-font-sizes);
}
h2, .h2{
    @include font-size($h2-font-sizes);
}
h3, .h3{
    @include font-size($h3-font-sizes);
}
h4, .h4{
    @include font-size($h4-font-sizes);
}
h5, .h5{
    @include font-size($h5-font-sizes);
}
h6, .h6{
    @include font-size($h6-font-sizes);
}
.letter-spacing{
    @include modifier('07') {
        letter-spacing: -0.07px;
    }
    @include modifier('09') {
        letter-spacing: -0.09px;
    }
    @include modifier('024') {
        letter-spacing: -0.24px;
    }
    @include modifier('039') {
        letter-spacing: -0.39px;
    }
}
.opacity{
    @include modifier('44') {
        opacity: 0.44;
    }
}
ul{
    li{
        -webkit-font-smoothing: antialiased;
    }
}
.container {
    max-width: 1642px !important;
    &-big {
        max-width: 1810px !important;
    }
}

a, input, button, h1, h2, h3, h4, h5, h6, svg, input[type="text"], input[type="search"], input[type="file"], input[type="time"], input[type="date"], input[type="tel"], input[type="email"], input[type="password"], input[type="url"], textarea, select{
    @include transition(all, 0.5s, ease-out);
    -webkit-font-smoothing: antialiased;
}
input, button{
    &:hover, &:focus{
        text-decoration: none;
        outline: none;
    }
}
a{
    color: $primary-color;
    font-weight: 400;
    &:hover{
        color: $white;
        text-decoration: none;
    }
}

/*.p-32 {
    padding: 32px !important;
    @include media("<=tablet") {
        padding: 18px !important;
    }
}*/
.mb-40 {
    margin-bottom: 30px !important;
    @include media(">ltablet") {
        margin-bottom: 40px !important;
    }
}

#overlay{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    content: '';
    z-index: 1;
    background: rgba($color: $headline-color, $alpha:  0.81);
    height: 100vh;
    @include size(100%);
    @include transition(all, 0.5s, ease-out);
}
p{
    a{
        color: $primary-color;
        font-weight: 400;
        text-decoration: underline;

        &:hover{
            color: $primary-hover-color;
            text-decoration: none;
        }
    }

    @include media("<=ltablet") {
        margin: 0 0 15px;
    }
}
span.error, span.valid{
    display: block;
    text-align: left;
    top: -8px;;
    position: relative;
    font-size: $root-font-size - 4;
    font-family: $lato;
    padding-left: 16px;
    margin-bottom: 8px;

}
span.error{
    color: $error-color;
    text-align: left;
}
span.valid{
    color: $headline-color;
    text-align: left;
}
.overlay{
    position: relative;

    &:after{
        top: 0;
        left: 0;
        content: '';
        @include size(100%);
        position: absolute;
        z-index: 2;
        opacity: .2;
        @include borderradius(15px);
    }

    @include modifier('fullwhite') {
        &:after{
            background: rgba($color: $white, $alpha: 0.9);
        }
    }

    @include modifier('fullBlack') {
        &:after{
            // @include gradient(rgba($headline-color, 0.49), rgba($headline-color, 1));
            background-color: $headline-color;
        }
    }
}
.borderBottom{
    position: relative;

    &:after{
        bottom: 0;
        left: 0;
        content: '';
        width: 140px;
        height: 1px;
        border: 1px dashed $white;
        position: absolute;
        z-index: 1;
    }

    &--full{
        &:after{
            width: 100%;
        }
    }
}
.blank{
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);
    z-index: 1;
}
.clear{
    width: 100%;
    float: left;
}
.dividerSide{
    margin: 0 0 30px;
    
    &:before, &:after{
        position: absolute;
        top: 50%;
        content: '';
        width: 129px;
        height: 1px;
        border: 1px dashed $white;
        opacity: 0.4;
        @include transform(translateY(-50%));

        @include media("<=ltablet") {
            display: none !important;
        }
    }
    &:before{
        left: 100%;
    }
    &:after{
        right: 100%;
    }

    h1, h2, h3, h4, h5, h6{
        padding: 0 15px;
        margin: 0 !important;
    }

    @include media("<=ltablet") {
        margin: 0 0 10px;
    }
}
.container{
    @include media(">mdesktop") {
        max-width: 1170px;
    }
    @include media(">=ldesktop") {
        max-width: 1560px;
    }
    @include media(">=exldesktop") {
        max-width: 1748px;
    }
    @include media(">=mac") {
        max-width: 1748px;
    }

    &--postLogin{
        @include media("<=desktop") {
            max-width: 100%;
        }
    }
}
.bannersubheading, .subheading{
    font-size: 16px;
    color: $white;
    font-weight: 400;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 15px;
    font-style: italic;

    @include media("<=ltablet") {
        font-size: 15px;
        margin-bottom: 0;
    }
}

.alignMiddle{
    display: flex;
    justify-content: center;
    align-items: center;
}

.o-commonSection{
    padding: 51px 0;

    @include media("<=desktop") {
        padding: 31px 0;
    }

    &--innerPage{
        @include media("<=desktop") {
            padding: 0;
        }
    }
}
.slick-slide{
    &:hover, &:focus{
        outline: none;
    }
}

.slick-arrow{
    width: 25px !important;
    height: 40px !important;
    z-index: 2;

    &:before{
        display: none;
    }

    .slickIcon{
        width: 12px;
        height: 23px;
        fill: $white;
    }

    @include media(">=mac") {
        width: 57px !important;
        height: 50px !important;
    }
}

.font100{
    font-weight: 100;
}
.font200{
    font-weight: 200;
}
.font300{
    font-weight: 300;
}
.font400{
    font-weight: 400;
}
.font500{
    font-weight: 500;
}
.font600{
    font-weight: 600;
}
.font700{
    font-weight: 700;
}
.font800{
    font-weight: 800;
}
.font900{
    font-weight: 900;
}
.commonSectionInner{
    &:not(:last-child){
        padding: 0 0 101px;

        @include media("<=ltablet") {
            padding-bottom: 51px;
        }
    }

    ul, ol{
        padding: 0 0 15px;
        overflow: hidden;

        li{
            position: relative;
            padding-left: 35px;
            @extend .clear;
            
            &:before{
                background: url(../images/arrow-dropright-circle.svg) no-repeat center center / cover;
                position: absolute;
                top: 4px;
                left: 0;
                content: '';
                @include size(20px);
            }

            &:not(:last-child){
                padding-bottom: 15px;
            }
        }
    }
}
.boxImageWrap{
    height: 350px;
    overflow: hidden;
    border: 1px solid $headline-color;

    .boxImage{
        position: absolute;
        top: 0;
        left: 0;
        background-attachment: fixed !important;
        @include size(100%);
        @include transition(all .5s);
        @include transform(translateZ(0) scale(1));
    }
    
    .boxContent{
        color: $white;
        z-index: 2;
        left: 55px;
        bottom: 15px;

        @include media("<=ltablet") {
            left: 15px;
        }
    }

    .boxImageWrapBlank{
        z-index: 3;
    }
    
    &:hover{
        .boxImage{
            @include transform(translateZ(0) scale(1.2));
            @include transition(all .5s);
        }
    }
    
    @include media(">=ldesktop") {
        height: 470px;
    }
    
    @include media("<=desktop") {
        height: 250px;
    }
}
.colorWhite{
    color: $white;
}
.col{
    &-10{
        @include media(">mac") {
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        }
    }
}
.o-breadcrumb{
    position: relative;
    z-index: 2;
    margin: 0 0 30px;

    .o-breadcrumbItem{
        display: inline-block;
        vertical-align: top;
        color: $white;
        font-size: 14px;
        position: relative;
        margin: 0 5px 0 0; 

        &:before{
            float: right;
            content: '/';
            padding: 0 0 0 8px;
        }

        &:hover{
            color: $headline-color;
        }

        &.active{
            color: $headline-color;
            margin: 0;

            &:before{
                display: none;
            }
        }
    }   
}

.columns{
    @include modifier('md') {
        @include modifier('3') {
            -moz-column-count: 3;
            -webkit-column-count: 3;
            column-count: 3;
        }
        @include modifier('4') {
            -moz-column-count: 4;
            -webkit-column-count: 4;
            column-count: 4;
        }
        @include modifier('6') {
            -moz-column-count: 6;
            -webkit-column-count: 6;
            column-count: 6;
        }
    }
}

.o-body-content{
    @include media(">=tablet") {
        // padding-top: 73px;
    }

    @include modifier('postLogin') {
        @include media(">=tablet") {
            // padding-bottom: 73px;
        }
    }
}
.addedSuccesful{
    /*background: $headline-color;*/
    @extend .alignMiddle;
    @include size(22px);
    @include borderradius(50%);
    .addedSuccesfulIcon{
        border: 2px solid $white;
        @extend .alignMiddle;
        @include size(100%);
        @include borderradius(50%);        
    }

    .correct{
        @include size(11px);
        fill: $white;
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .backdropFilter {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
    .header.is-visible 
    {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
    .NewsfeedStatus
    {
        a.wishlisticon
        {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
        }
    }
}
  
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .backdropFilter {
        // background-color: rgba(255, 255, 255, .28);
        background-color: rgba(0, 0, 0, .6);
    }
    .header.is-visible 
    {
        background-color: rgba($color: $secondary-color, $alpha: 0.8);
    }
    .NewsfeedStatus
    {
        a.wishlisticon
        {
            background-color: rgba($color: $white, $alpha: 0.6);
        }
    }
}

.abc {
    @include font-size($h1-font-sizes);
}

@keyframes fade{
    from{
      opacity: 0;
      transform: translateY(-10px);
    }
    to{
      opacity:1;
      transform: translateY(0px);
    }
}