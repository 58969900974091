.message-place {}

.message-title {
    margin-bottom: 28px;
    .nav-link {
        font-size:14px;
        line-height: 17px;
        color:$bodytext-color;
        padding: 8px 27px;
        border-radius: 21px;

            &.active{
                background-color: $primary-color;
                color:$white;
            }
    }
}

.accordian-style {

    .card {
        margin-bottom: 15px;
        border-radius: 2px;
        border:1px solid $lightgray-border;
    }

    .card-header {
        padding: 28px 28px 28px 71px;
        background-color: $white;
        position:relative;
        border-bottom: 0;

        @include media("<=ltablet") {
            padding: 25px;
        }

        .pointer {
            cursor: pointer;
        }

        .email-place {
            h5 {
                font-size:16px;
                line-height: 19px;
                color:$headline-color;
                margin-bottom: 5px;
                display:inline-block;
                font-weight: $semi-bold;
                position:relative;
                @include media("<=ltablet") {
                    font-size: 14px;
                }
            }
            .time {
                display:block;
                font-size: 14px;
                color:$bodytext-color;
                @include media("<=ltablet") {
                    font-size: 12px;
                }
            }

            &.memberon {

                .activemember {
                    width:14px;
                    height:14px;
                    border-radius:50%;
                    background:$primary-color;
                    display:block; 
                    position: absolute;
                    top: 62%;
                    left: -40px;
                    transform: translateY(-50%); 

                    @include media("<=ltablet") { 
                        width:7px;
                        height:7px;
                        left:auto;
                        right: -19px;  
                    }
                        
                }
            }
            @include media("<=ltablet") { 
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
            }
        }

        .message-content {
            position: relative;

            h3 {
                font-size:16px;
                line-height: 19px;
                color:$headline-color;
                margin-bottom: 5px;
                font-weight: $semi-bold;
                @include media("<=ltablet") { 
                    font-size: 14px;
                }
            }

            .message-text {
                display:block;
                font-size: 14px;
                color:$bodytext-color;
                margin-bottom: 0;
                @include media("<=ltablet") { 
                    display:none;
                }
            }
        }

        .action-buttons{
            position:absolute;
            top: 50%;
            right: 28px;
            display: flex;
            align-items: center;
            max-width: 46px;
            width: 100%;
            justify-content: space-between;
            z-index: 666;
            z-index: 666;
            transform: translateY(-50%);

            @include media("<=ltablet") {
                top: 67%;
                right: 25px;
             }


            .delete {
                width:21px;  
            }
        }

        .heading-color {

            .message-content {
                h3{
                    color:$bodytext-color;
                }
            }

            &.collapsed {
                .message-content {
                    h3{
                        color:$headline-color;
                    }
                }

            }
        }

        // .collapsed ~{

        //     .action-buttons {
        //         a {
        //             .icon-sm {
        //                 path{
        //                     @include media("<=ltablet") {
        //                         fill:$primary-color;  
        //                     }
        //                 }

        //                 &.delete {
        //                     path{
        //                         fill:none;
        //                         stroke:$primary-color;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    .message-body {
        padding: 0px 28px 28px 71px;  

        @include media("<=ltablet") {
            padding: 25px;
            padding-top: 0;
        }

        .details-tags {
            margin-bottom: 25px;

            &:last-child{
                margin-bottom: 03;
            }
            h4 {
                margin-bottom: 2px;
            }

            p{
                margin-bottom: 0;
                font-size: 14px;
                line-height: 17px;
            }
        }

        .description-place {
            p {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 20px; 
                margin-bottom: 25px;
            }
        }
    }
}