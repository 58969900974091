.content-info-commonplace {
    display:block;
    margin-bottom:37px;
    position:relative;
}

.small-font-heading {
    font-size:14px;
    line-height: 17px;
    margin-bottom: 16px;
    font-weight:$bold;
}

.upload-phpto-place {
    display: flex;
    flex-direction: row;
    align-items: flex-start;  
    flex-wrap: wrap;

        
    @include media("<=ltablet") { 
        flex-direction: column-reverse;
    }


    .show-photo {
        max-width:735px;
        height:331px;
        overflow: hidden;
        position:relative;
        margin-top:22px;
        border-radius:5px;
        width: 100%;
    
            img {
                width:100%;
                height:100%; 
                object-fit: contain;
            }
    
        @include media("<=ltablet") {
            height:260px;
            margin-top:0;
            margin-bottom:16px;
        }
    
            .remove {
                position:absolute;
                top:12px;
                right:14px;
                width:24px;
                height:24px;
                text-align: center;
                color:$white;
                z-index: 2;
            }

        &--medium {
            max-width: 100%;
            height: 200px;
            background-color: rgba(0, 0, 0, 0.2);
            margin-bottom: 16px;
            @include media(">ltablet"){
                max-width: 100%;
                height: 164px;
            }
        }
    }
    &--gallery {
        /*flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;*/
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        @include media("<=tablet") {
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }
        .show-photo {
            width: 100%;
            height: 250px;
            @include media("<=ltablet") {
                margin-bottom: 0;
            }
            @include media("<=tablet") {
                height: 180px;
            }
            /*@include media(">ltablet") {
                max-width: calc(100% / 3 - 5px);
                height: 180px;
            }
            @include media(">mdesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 160px;
            }
            @include media(">largerdesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 200px;
            }
            @include media(">ldesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 250px;
            }*/
            video, iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .vid-overlay {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                svg {
                    width: 38px;
                    height: 38px;
                    fill: $white;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &--gallery {  
        /*flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;*/
        display: grid;
        grid-gap: 10px;
        /*grid-template-columns: 270px 270px 270px 270px 270px;*/
        /*grid-template-rows: auto;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));*/
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        /*grid-auto-rows: minmax(26vh, 250px);*/
        @include media("<=tablet") {
            /*grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));*/
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            grid-auto-rows: minmax(26vh, 180px);
        }
        .show-photo {
            width: 100%;
            height: 250px;
            @include media("<=ltablet") {
                margin-bottom: 0;
            }
            @include media("<=tablet") {
                height: 180px;
            }
            /*@include media(">ltablet") {
                max-width: calc(100% / 3 - 5px);
                height: 180px;
            }
            @include media(">mdesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 160px;
            }
            @include media(">largerdesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 200px;
            }
            @include media(">ldesktop") {
               max-width: calc(100% / 5 - 5px);
               height: 250px;
            }*/
            video, iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .vid-overlay {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                svg {
                    width: 38px; 
                    height: 38px;
                    fill: $white;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);  
                }
            }
        }
    }

}

.logo-photo-place{
    .show-photo{
        height: auto;
        max-width: 331px;
        img{
            object-fit: contain;
            background-color: $primary-color;
        }
    }
}
.show-photo-wrapper{
    margin-left: 6px;
    margin-right: 6px;
}