.pager-table {
	border-collapse: separate;
	thead {
		tr {
			th {
				color: $headline-color;
				font-size: $root-font-size - 6;
				text-transform: uppercase;
				line-height: 15px;
				font-weight: $bold;
				border-top: none;
				padding: 22px;
				border-bottom: none;
				/*border-bottom: 1px solid $lightgray-border;*/
				vertical-align: middle;
				&:nth-child(2), &:nth-child(4) {
					@include media("<=tablet") {
						display: none;
					}
				}
				&:nth-child(3) {
					@include media("<=tablet") {
						text-align: right;
					}
				}
			}
		}
	}
	tbody {
		tr {
			@include media("<=ltablet") {
				cursor: pointer;
			}
			&.opened {
				.additional-info {
					display: block;
				}
			}
			td {
				padding: 16px 22px;
				letter-spacing: 0.35px;
				font-size: $root-font-size - 4;
				line-height: 17px;
				font-weight: $regular;
				color: $bodytext-color;
				vertical-align: middle;
				border-top: 1px solid $lightgray-border;
				.icon {
					width: 16px;
					height: 20px;
					fill: $bodytext-color;
					margin-right: 20px;
					&-sm {
						width: 14px;
						height: 14px;
						margin-right: 16px;
						position: relative;
						fill: $bodytext-color;
						&--eye {
							width: 24px;
							height: 24px;
						}
					}
				}
				a {
					margin-right: 20px;
					position: relative;
					.icon {
						margin-right: 0;
						&-sm {
							margin-right: 0;
							fill: $headline-color;
						}
					}
					// &:hover {
					// 	&::after {
					// 		content: attr(data-title);
					// 		position: absolute;
					// 		left: 50%;
					// 		top: -50px;
					// 		transform: translateX(-50%);
					// 		width: auto;
					// 		white-space: nowrap;
					// 		background: $white;
					// 		@include borderradius(5px);
					// 		padding: 10px 15px;
					// 		font-size: $root-font-size - 6;
					// 		line-height: 15px;
					// 		font-weight: $regular;
					// 		letter-spacing: 0.3px;
					// 		color: $bodytext-color;
					// 		@include boxshadow(0px, 0px, 6px, 0px, #9863CF26);
					// 	}
					// 	&::before {
					// 		border: solid;
					// 		border-color: $white transparent;
					// 		border-width: 12px 6px 0 6px;
					// 		content: "";
					// 		left: 45%;
					// 		bottom: 30px;
					// 		z-index: 1;
					// 		position: absolute;
					// 		-webkit-filter: drop-shadow(0px 1px 1px rgba(0,0,0,.1));
  					// 		filter: drop-shadow(0px 1px 1px rgba(0,0,0,.1));
					// 	}
					// 	.icon {
					// 		&-sm {
					// 			fill: $primary-color;
					// 		}
					// 	}
					// }
					/*&:focus {
						&::after {
							content: attr(data-title);
							position: absolute;
							left: 50%;
							top: -50px;
							transform: translateX(-50%);
							width: auto;
							white-space: nowrap;
							background: $white;
							border-radius: 2px;
							padding: 10px 15px;
							@include borderradius(5px);
							font-size: $root-font-size - 6;
							line-height: 15px;
							font-weight: $regular;
							letter-spacing: 0.3px;
							color: $bodytext-color;
							@include boxshadow(0px, 0px, 6px, 0px, #9863CF26);
						}
					}*/
					&:first-of-type {
						&:hover {
							&::before {
								bottom: 20px;
							}
						}
					}
					&:last-child {
						margin-right: 0;
					}
				}
				p {
					margin-bottom: 0;
					letter-spacing: 0.35px;
					font-size: $root-font-size - 4;
					line-height: 17px;
					font-weight: $regular;
					color: $bodytext-color;
				}
				.custom-switch {
					span {
						padding-left: 10px;
						line-height: 1;
					}
				}
				&:nth-child(2), &:nth-child(4) {
					@include media("<=tablet") {
						display: none;
					}
				}
				&:nth-child(3) {
					@include media("<=tablet") {
						text-align: right;
						width: 110px;
						border-right: 1px solid transparent;
					}
				}
				&:first-child {
					border-left: 2px solid transparent;
					@include transition(all, .5s, ease);
				}
			}
			&.hiddenRow {
			    @include media(">tablet") {
			    	display: none;
			    }
			    .hiddenData {
			    	padding: 0;
				    background-color: $white;
				    border: none;
				    border-left: 2px solid transparent;
				    @include transition(all, .5s, ease);
				    &.selected {
						border-left: 2px solid $primary-color;
						@include transition(all, .5s, ease);
					}
				    table {
				    	width: 100%;
					    tr {
					    	border: 1px solid $lightgray-border;
					    	border-bottom: none;
					    	border-top: none;
					    	td {
					    		border: none;
					    		line-height: 1;
					    		padding: 9px 22px;
					    		&:nth-child(2), &:first-child {
									@include media("<=tablet") {
										display: table-cell;
									}
								}
								&:first-child {
									padding-left: 58px;
									font-size: $root-font-size - 6;
									text-transform: uppercase;
									font-weight: $bold;
									color: $headline-color;
								}
								&:last-child {
									text-align: right;
									font-size: $root-font-size - 6;
									font-weight: $regular;
									color: $bodytext-color;
								}
					    	}
					    	&:last-child {
					    		td {
					    			padding-bottom: 30px;
					    		}
					    	}
					    }
					}
			    }
			}
			&.selected {
				background: $white;
				@include transition(all, .5s, ease);
				/*border-left: 2px solid $primary-color;*/
				td {
					&:first-child {
						@include media("<=tablet") {
							border-left: 2px solid $primary-color;
							@include transition(all, .5s, ease);
						}
					}
					&:nth-child(3) {
						@include media("<=tablet") {
							border-right: 1px solid $lightgray-border;
						}
					}
				}
				&.hiddenRow {
					.hiddenData {	
					}
				}
			}
		}
	}


	&--ratestable {
		thead {
			tr {
				th{
					padding-left: 0;  
					padding-top:0; 

					&:last-child{
						text-align: right;
					}

					&:nth-child(2) {
						@include media("<=tablet") {
							display: block; 
						}
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding-left: 0;  

					&:nth-child(2){
						@include media("<=tablet") {
							display: block;  
						}
					}
				}

				@include media("<=ltablet") {
					cursor: auto;
				}
			}
		}
	}

	&--links {

		margin-top: 19px;

		thead {
			tr {
				th {
					&:nth-child(3){
						@include media("<=tablet") {
							display:none;
						}
					}
					&:nth-child(4){
						width:100px;
						@include media("<=tablet") {
							display:block; 
							width:100%;
							text-align: right;
						}

					}
				}
			}
		}

		tbody {
			tr {
				td {
					a{
						&.url {
							color: $bodytext-color;
							&:hover{
								&:after{
									display:none;  
								}
								&:before {
									display:none;
								}
							}

						}
					}

					&:nth-child(3) {
						@include media("<=tablet") {
							display:none;
						}
					}

					&:nth-child(4) {
						@include media("<=tablet") {
							display:block;  
						}
					}
				}
				&.selected {
					td{
						&:nth-child(4){
							@include media("<=tablet") {
								border-right:1px solid $lightgray-border;
							}
						}
					}
				}
				&.hiddenRow {
					td{
						&.hiddenData {
							table {
								tr {
									td {
										&:first-child {
											padding-left: 22px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&--reduce-space {

		tbody{

			tr {
				&.hiddenRow {

					.hiddenData {
						table {
							tr {
								td{
									&:first-child{
										padding-left:22px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&--new-table {

		thead{
			tr {
				th {
					&:nth-child(2), &:nth-child(4) {
						@include media("<=tablet") {
							display: block;
						}
					}
				}
			}
		}

		tbody {
			tr {
				&.selected {
					td{
						&:nth-child(7){
							border-right:1px solid #f0f0f0;
						}
					}
				}

				td {
					&:nth-child(2), &:nth-child(4) {
						@include media("<=tablet") {
							display: block;
						}
					}
					.tooltip-link {
						.icon-sm--place {
							font-size: 17px;
							color:$black;
							&:hover {
								color:$primary-color;
							}
						}
					}
				}


			}
		}
	}
}

