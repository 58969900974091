.navbar-expand-sm {
    .container {
        flex-wrap: nowrap;
        padding-left: 15px;
        padding-right: 15px;
        @include media("<=tablet") {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
.sidenav {
    height: 100%;
    width: 335px;
    text-align: center;
    position: fixed;
    z-index: 1032;
    top: 0;
    left: -335px;
    overflow-x: hidden;
    transition: 0.5s;
    padding: 30px 20px;
    background: url(../images/mobile_menu_bg.jpg) center center no-repeat;
    background-size: cover;
    @include media("<=mphone") {
        left: -315px;
        width: 315px;
    }
    &.show-nav {
        left: 0;
    }
    .sidebarNav {
        padding: 100px 0;
        ul {
            li {
                a {
                    font-size: $root-font-size;
                    line-height: 22px;
                    font-weight: $bold;
                    color: $black;
                    display: inline-block;
                    margin-bottom: 28px;
                }
            }
        }
        .button {
            margin-top: 5px;
        }
        
    }
    .logo-wrapper {
        text-align: center;
        .logo {
            height: 27px;
            width: 240px;
        }
    }
    .language-wrapper {

    }
}
.nav-overlay {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: background-color 0.15s ease-out, visibility 0.15s ease-out;
    visibility: hidden;
    &.active {
        background-color: $headline-color-overlay;
        visibility: visible;
        z-index: 5;
    }
}