.faq-pageMain {
    .page-heading{
        @include font-size($h1-font-sizes);
        font-family: $lato;
        font-weight: $meduim;
        color: $black;
        text-align: center !important;
        text-transform: capitalize; 
    }
    .faqPanel{
        .nav-tabs{
            margin-top: 15px;
            border-bottom: 0px;
            li {
                &:first-child{
                    padding-left: 15px;
                }
                &::before{
                    content: '' !important;
                }
                a{
                    text-decoration: none !important;
                    @include font-size($bttn-font-sizes);
                    font-family: $roboto;
                    font-weight: $regular;
                    border: 1px solid $primary-color;
                    border-radius: 30px;
                    padding: 12px 40px;
                    display: inline-block;
                    &:hover{
                        color: $secondary-color;
                    }
                    &.active{
                        background-color: $primary-color;
                        color: $white;
                        &:hover{
                            background-color: $violet-hover-color;
                        }
                    }
                }
            }
        }
    }
    .panel-body{
        h5{
            @include font-size($h5-font-sizes);
            font-family: $roboto;
            font-weight: $regular;
            padding-left: 30px;
            margin-bottom: 30px;
        }
        h6{
            @include font-size($h6-font-sizes);
            font-family: $roboto;
            font-weight: $regular;
        }
        button{
            cursor: pointer;
            border: 1px solid $black;
            display: block;
            border-radius: 30px;
            padding: 12px 30px;
            width: 100%;
            text-align: left;
            &.btn-header-link{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.collapsed{
                    &::after {
                        content: "\f067";
                    }
                }
            }           
            &.btn-header-link{
                &::after {
                    content: "\f068";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                }
            }
        }
        .card{
            p{
                @include font-size($p-font-sizes);
                font-family: $roboto;
                font-weight: $regular;
                animation: fade 1s ease-in-out;
                margin-bottom: 0;
            }
            border: 0px;
            background: none;
        }
        .card-header{
            border-bottom: 0px;
            background: none;
            padding: 1rem 1rem;
        }
        
    }
}