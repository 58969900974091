.template-wrapper {
	display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit,minmax(335px,1fr));
    @include media(">lphone") {
		grid-template-columns: repeat(auto-fit,minmax(270px,1fr));
	}
    @include media(">mdesktop") {
		grid-template-columns: repeat(auto-fit,minmax(335px,1fr));
	}
	&--layout {
		margin-bottom: 0px;
		@include media(">mdesktop") {
			margin-bottom: 80px;
		}
		.template-box {
			&-header {
				opacity: 0;
				min-height: 16px;
				h5 {
					font-size: $root-font-size - 4;
					line-height: 17px;
				}
			}
			&-inner {
				border: 1px solid $lightgray-border;
			}
			.page-btns {
				@include media("<=mdesktop") {
					display: none;
				}
			}
			&.active {
				.template-box-header {
					opacity: 1;
				}
				.template-box-inner {
					border: 5px solid $primary-color;
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&--gallery {
		margin-bottom: 0px;
		@include media(">mdesktop") {
			margin-bottom: 80px;
		}
		.template-box {
			&-header {
				opacity: 0;
				min-height: 16px;
				h5 {
					font-size: $root-font-size - 4;
					line-height: 17px;
				}
			}
			&-inner {
				border: 1px solid $lightgray-border;
			}
			.page-btns {
				@include media("<=mdesktop") {
					display: none;
				}
			}
			&.active {
				.template-box-header {
					opacity: 1;
				}
				.template-box-inner {
					border: none;
					@include boxshadow(0px, 0px, 10px, 0px, #925FC680);
				}
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.template-box {
	position: relative;
	&-header {
		@include flexbox;
		align-items: center;
		flex-direction: row;
		margin-bottom: 20px;
		h5 {
			font-size: $root-font-size - 4;
			line-height: 17px;
			color: $headline-color;
			font-weight: $regular;
			padding-left: 13px;
			@include media(">tablet") {
				font-size: $root-font-size;
				line-height: 22px;
			}
		}
		span {
			color: $primary-color;
			font-size: $root-font-size - 2;
			line-height: 25px;
			font-weight: $bold;
			font-style: italic;
			margin-left: 8px;
			line-height: 1;
			@include media(">tablet") {
				font-size: $root-font-size - 4;
				font-weight: $regular;
				font-style: normal;
			}
		}
	}
	&-inner {
		@include borderradius(5px);
		overflow: hidden;
		@include transition(all, .4s, ease-in-out);
		position: relative;
		img {
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
			@include transition(transform, .4s, ease-in-out);
		}
		&:after {
			background: rgba(252, 252, 252, .93);
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 100%;
			@include transition(all, .4s, ease-in-out);
			opacity: 0;
		}
	}
	.page-btns {
		position: relative;
	    left: auto;
	    top: auto;
	    right: auto;
	    background-color: transparent;
	    z-index: 1;
	    padding-top: 18px;
	    padding-left: 10px;
	    @include transform(translateY(0));
	    opacity: 1;
	    @include transition(all, .4s, ease-in-out);
	    .button {
	    	margin-left: 0;
	    	margin-bottom: 0;
	    	margin-right: 14px;
	    	max-width: 108px;
	    	width: 100%;
	    	@include media(">mdesktop") {
	    		margin-bottom: 12px;
	    		margin-right: 0;
	    	}
	    	&:last-child {
	    		margin-bottom: 0;
	    		margin-right: 0;
	    	}
	    }
	    @include media(">mdesktop") {
	    	opacity: 0;
	    	position: absolute;
	    	right: 0;
	    	left: 0;
	    	@include transform(translateY(50%));
	    	top: 50%;
	    	padding-left: 0;
	    	padding-top: 40px;
	    }
	}
	&:hover {
		.template-box-inner {
			img {
				@include transform(scale(1.1));
			}
			&:after {
				opacity: 1;
			}
		}
		.page-btns {
			@include transform(translateY(-50%));
			opacity: 1;
		}
	}
	&--premium {
		.premium-tag-wrapper {
			position: absolute;
			bottom: 32px;
			width: 100%;
			padding-left: 15px;
			p {
				display: none;
			}
			.premium-tag {
				position: relative;
				span {
					@include borderradius(21px);
					background: $yellow-accent-color;
					color: $white;
					padding: 10px 25px 10px 55px;
					letter-spacing: 0.3px;
					font-size: $root-font-size - 6;
					font-weight: $bold;
				    display: inline-block;
				    line-height: 1;
				    min-width: 134px;
				    text-align: right;
				    @include media(">mdesktop") {
						font-size: $root-font-size - 2;
						min-width: 144px;
						padding: 13px 30px 13px 80px;
						letter-spacing: 0.4px;
					}
				}
				svg {
					width: 50px;
					height: 50px;
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					@include media(">mdesktop") {
						width: 68px;
						height: 68px;
					}
				}
			}
		}
	}
	&--unsubscribed {
		.premium-tag-wrapper {
			p {
				font-size: $root-font-size - 2;
				line-height: 20px;
				font-weight: $bold;
				color: $white;
				margin: 26px 0 0;
				display: block;
				@include media(">mdesktop") {
					font-size: $root-font-size;
					line-height: 22px;
				}
			}
		}
		.template-box-inner {
			img {
				filter: blur(3px);
				-moz-filter: blur(3px);
				-webkit-filter: blur(3px);
			}
		}
		&:hover {
			.template-box-inner {
				img {
					@include transform(scale(1));
				}
				&:after {
					opacity: 0;
				}
			}
			.page-btns {
				opacity: 0;
			}
		}
	}
}