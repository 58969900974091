.common-wrapper {
	padding: 120px 0;
}
.two-half-block {
	@include flexbox;
	align-items: flex-start;
	&-left {
		width: 100%;
		padding: 16px;
		@include media(">tablet") {
			padding: 22px;
		}
		@include media(">=largerdesktop") {
			padding: 23px 100px 23px 23px;
		}
		.page-top-info {
			.page-header {
				h6 {
					margin-bottom: 14px;
				}
			}
		}
		.content-wrapper {
			.content-info-top {
				/*max-width: 605px;*/
			}
		}
	}
	&-right {
		max-width: 100%;
		width: 100%;
		background: $faded-white;
		@include media(">tablet") {
			max-width: 568px;
		}
	}
}